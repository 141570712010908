import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Search from "../../components/Search";
// import ExitingValueFilter from "../category/ExitingValueFilter";
import { getCategoryTree } from "../category/redux/action";
import TreeViewSelect from "../category/TreeViewSelect";
import { useTheme } from "@emotion/react";

export default function DetailsFilter({ reqData, setReqData }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLarge = useMediaQuery(
    theme.breakpoints.down("lg") ||
      theme.breakpoints.down("md") ||
      theme.breakpoints.down("sm") ||
      theme.breakpoints.down("xs")
  );
  const { categoriesTreeList } = useSelector(
    (state) => ({
      categoriesTreeList:
        state && state.categories && state.categories.categoriesTreeList ? state.categories.categoriesTreeList : []
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getCategoryTree({ flag: false }));
  }, [dispatch]);
  return (
    <Box my={2}>
      {/* <Grid container spacing={1} alignItems="center">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TreeViewSelect
            items={
              reqData?.categoryId !== ""
                ? ExitingValueFilter(categoriesTreeList, reqData?.categoryId)
                : categoriesTreeList
            }
            defaultValue={reqData?.categoryId}
            onChange={(e, triggeredAction) => {
              if (triggeredAction.action === "clear") {
                setReqData({ ...reqData, categoryId: "" });
              } else {
                setReqData({ ...reqData, categoryId: e.value.id });
              }
            }}
            placeholder="Select Category"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Search
            placeholder="Search product and sub product..."
            reqData={reqData}
            setReqData={setReqData}
            sx={{ float: "right" }}
          />
        </Grid>
      </Grid> */}
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" flexWrap="wrap">
        <Box width="100%" maxWidth={`${isLarge ? "100%" : "400px"}`}>
          <TreeViewSelect
            items={categoriesTreeList}
            defaultValue={reqData?.categoryId}
            isClear
            onChange={(e, triggeredAction) => {
              if (triggeredAction.action === "clear") {
                setReqData({ ...reqData, categoryId: "" });
              } else {
                setReqData({ ...reqData, categoryId: e.value.id });
              }
            }}
            placeholder="Select Category"
          />
        </Box>
        <Box sx={{ textAlign: "right", width: `${isLarge ? "100%" : ""}` }}>
          <Search
            placeholder="Search product and sub product..."
            reqData={reqData}
            setReqData={setReqData}
            sx={{ float: "right", textAlign: "right" }}
          />
        </Box>
      </Box>
    </Box>
  );
}
