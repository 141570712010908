import {
  GET_SPECIFICATIONS_BY_CATEGORY_FAIL,
  GET_SPECIFICATIONS_BY_CATEGORY_INITIAL,
  GET_SPECIFICATIONS_BY_CATEGORY_REQUEST,
  GET_SPECIFICATIONS_BY_CATEGORY_SUCCESS,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCESSS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_FAIL,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT__DELETE_FROM_API_FAIL,
  PRODUCT__DELETE_FROM_API_REQUEST,
  PRODUCT__DELETE_FROM_API_SUCCESS,
  SINGLE_PRODUCT_FAIL,
  SINGLE_PRODUCT_REQUEST,
  SINGLE_PRODUCT_SUCCESS
} from "./type";

// category intial state
const initialProducts = {
  products: [],
  product: null,
  statusCode: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  isSingleLoading: false,
  specificationOptions: []
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return { ...state, isLoading: true };
    case PRODUCTS_SUCESSS: {
      const products = action.payload.data.records;
      return {
        ...state,
        products: products,
        isLoading: false,
        success: true,
        total: action.payload.data.totalRecords
      };
    }
    case PRODUCT_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case PRODUCT_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case PRODUCT_UPDATE_SUCCESS:
      if (action.payload.json.hasOwnProperty("brandId")) {
        return {
          ...state,
          isLoading: false,
          success: true,
          product: { productShow: { ...state?.product?.productShow, brandData: { _id: action.payload.json.brandId } } }
        };
      }
      if (action.payload.json.hasOwnProperty("status")) {
        return {
          ...state,
          isLoading: false,
          success: true,
          product: {
            productShow: {
              ...state?.product?.productShow,
              status: action.payload.json.status === "true" ? true : action.payload.json.status === "false" ? false : ""
            }
          }
        };
      }
      if (action.payload.json.hasOwnProperty("addToHomePage")) {
        return {
          ...state,
          isLoading: false,
          success: true,
          product: {
            productShow: {
              ...state?.product?.productShow,
              addToHomePage:
                action.payload.json.addToHomePage === "true"
                  ? true
                  : action.payload.json.addToHomePage === "false"
                  ? false
                  : ""
            }
          }
        };
      } else {
        return { ...state, isLoading: false, success: true };
      }

    case PRODUCT_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case PRODUCT_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case PRODUCT_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case SINGLE_PRODUCT_REQUEST:
      return { ...state, isSingleLoading: true };
    case SINGLE_PRODUCT_SUCCESS: {
      const product = action.payload.data;
      return { ...state, isSingleLoading: false, product: product, success: true, statusCode: null };
    }
    case SINGLE_PRODUCT_FAIL: {
      return {
        ...state,
        isSingleLoading: false,
        error: action.error,
        statusCode: action.statusCode
      };
    }

    case PRODUCT__DELETE_FROM_API_REQUEST:
      return { ...state, isLoading: true };
    case PRODUCT__DELETE_FROM_API_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case PRODUCT__DELETE_FROM_API_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case GET_SPECIFICATIONS_BY_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case GET_SPECIFICATIONS_BY_CATEGORY_SUCCESS:
      const specificationOptions = action.payload.data;
      return { ...state, isLoading: false, specificationOptions: specificationOptions };
      case GET_SPECIFICATIONS_BY_CATEGORY_INITIAL:
        return { ...state, isLoading: false, specificationOptions: [] };
    case GET_SPECIFICATIONS_BY_CATEGORY_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    default:
      return state;
  }
};
