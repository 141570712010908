import { Checkbox, FormControlLabel, IconButton, List, ListItem } from "@mui/material";
import { useState } from "react";
import noImage from "../../images/no-image.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";

export default function MyCheckboxTree({ nodes, checked, setChecked, disabled }) {
  return (
    <List sx={{ width: "100%" }}>
      {nodes?.map((item, i) => {
        return <RowData item={item} key={i} checked={checked} setChecked={setChecked} disabled={disabled} />;
      })}
    </List>
  );
}

function RowData({ item, checked, setChecked, disabled }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_URI;
  const onImageError = (e) => {
    e.target.src = noImage;
  };
  return (
    <ListItem className={`${isExpanded ? "treeOddBg" : ""}`}>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ visibility: `${item?.subProduct && item.subProduct?.length > 0 ? "visible" : "hidden"}` }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 0.5 }}
                disabled={!disabled || item.subProduct?.length <= 0}
                checked={
                  checked.filter((x) => x === item._id).length > 0 ||
                  (item?.subProduct &&
                    item.subProduct?.length > 0 &&
                    item?.subProduct.filter((y) => checked.filter((x) => x === y._id).length > 0 && y).length > 0)
                }
                onChange={() => {
                  let array = item.subProduct.map((x) => x._id);

                  if (item?.subProduct && item.subProduct?.length > 0) {
                    if (item?.subProduct.filter((y) => checked.some((x) => x === y._id)).length > 0) {
                      let newArray = checked.filter((y) => !array.some((z) => y === z));
                      setChecked([...newArray]);
                    } else {
                      setChecked([...checked, ...array]);
                    }
                  } else {
                    if (checked.filter((x) => x === item._id).length > 0) {
                      let newArray = checked.filter((y) => item._id !== y);
                      setChecked([...newArray]);
                    } else {
                      setChecked([...checked, item._id]);
                    }
                  }
                }}
              />
            }
            label={
              <Box className="treeValue">
                <img
                  alt={item?.name}
                  style={{ width: 50, objectFit: "contain", pointerEvents: "none", userSelect: "none" }}
                  src={
                    item?.productImages[0] && item?.productImages[0]?.fileUrl
                      ? baseUrl + "/product/thumb-" + item?.productImages[0]?.fileName
                      : noImage
                  }
                  onError={onImageError}
                />
                <span style={{ userSelect: "none" }}>{item.name}</span>
              </Box>
            }
          />
        </Box>
        {isExpanded && item?.subProduct && item.subProduct?.length > 0 && (
          <List className="treeSubRow">
            {item?.subProduct.map((subItem, index) => {
              return (
                <ListItem key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!disabled}
                        sx={{ mr: 0.5 }}
                        checked={checked.filter((x) => x === subItem._id).length > 0}
                        onChange={() => {
                          if (checked.filter((x) => x === subItem._id).length > 0) {
                            let newArray = checked.filter((y) => subItem._id !== y);
                            setChecked([...newArray]);
                          } else {
                            setChecked([...checked, subItem._id]);
                          }
                        }}
                      />
                    }
                    label={
                      <Box className="treeValue">
                        <img
                          alt={item?.name}
                          style={{ width: 50, objectFit: "contain", pointerEvents: "none", userSelect: "none" }}
                          src={
                            subItem?.subProductImages && subItem?.subProductImages?.length
                              ? baseUrl + "/subProduct/thumb-" + subItem?.subProductImages[0]?.fileName
                              : noImage
                          }
                          onError={onImageError}
                        />
                        <span style={{ userSelect: "none" }}>{subItem.name}</span>
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </ListItem>
  );
}
