import { memo } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography, Box } from "@mui/material";
import moment from "moment";
import { RETURNREPLACESTATUS } from "../utils/common";

const RailView = ({ track = [] }) => {
  // const getStatusLabel = (data, statuses) => {
  //   const status = data?.status;
  //   if (status === undefined) {
  //     return null;
  //   }

  //   const statusObject = statuses.find(({ value }) => value === status);

  //   if (!statusObject) {
  //     return null;
  //   }

  //   return statusObject.label;
  // }
  // const label = useMemo(() => getStatusLabel(data, data?.status), [data, statuses]);

  return (
    <ol className="track-progress-verticle px-0 m-0 bg-extraLight">
      {track.map((data, i) => {
        return (
          <li className="done" key={i}>
            <div className="checkMark">
              <CheckCircleOutlineIcon />
            </div>
            <Box>
              <Typography variant="h5" fontWeight="bold" textTransform="capitalize">
                {RETURNREPLACESTATUS?.find(({ value }) => value === data?.status)?.label}
              </Typography>
              <Typography className="opacity-50" variant="h6">
                {moment(data?.stamp).format("DD/MM/YYYY hh:mm A")}
              </Typography>
            </Box>
          </li>
        );
      })}
    </ol>
  );
};

export default memo(RailView);
