import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ListAltIcon from "@mui/icons-material/ListAlt";
// import GetUserRole from "../../utils/GetUserRole";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { getToggleState } from "./redux/action";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import logo from "../../media/Images/logo.svg";

const Item = ({ title, to, icon }) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={location.pathname.includes(to)}
      style={{
        color: colors.grey[100],
        fontSize: "16px"
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();

  const toggledState = useSelector((state) => state.layout.toggleState);
 const GetUserRole = (value)=>{
  const index = user && user?.userRole?.includes(value);
  return index;
 }
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `#E9ECEF !important`,
          fontSize: "16px !important"
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important"
        },
        "& .pro-inner-item": {
          padding: "8px 15px 8px 20px!important",
          fontSize: "16px !important"
        },
        "& .pro-inner-item:focus": {
          color: "rgb(20, 20, 20) !important"
        },
        "& .pro-inner-item:hover": {
          color: "#e32b29 !important"
        },
        "& .pro-menu-item.active": {
          color: "#e32b29 !important"
        },
        "& .pro-menu-item.active .pro-item-content .MuiTypography-root": {
          // fontWeight: 900,
        },
        "& .pro-menu-item.active .pro-inner-item": {
          backgroundColor: "#E0E3E6 !important",
          borderLeft: "4px solid #e32b29",
          borderRadius: "4px 0 0 4px"
        }
      }}
    >
      <ProSidebar collapsed={isCollapsed} toggled={toggledState} breakPoint="md">
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {isCollapsed && (
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0",
                color: colors.grey[100],
                fontSize: "18px !important"
              }}
            ></MenuItem>
          )}
          {!isCollapsed && (
            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px" my="5px" p="0 15px 5px 0">
              <img src={logo} alt="logo" style={{ maxWidth: "175px" }} />

              {window.innerWidth >= 768 ? (
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={async () => {
                    await dispatch(getToggleState(!toggledState));
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          )}

          {/* {!isCollapsed && (
            <Box pb="15px" sx={{ borderBottom: 1, borderColor: "#d5d4d4" }}>
              <Box textAlign="center">
                <Typography variant="h4" color={colors.greenAccent[500]}>
                  {user.companyName}
                </Typography>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "5%"} sx={{ mt: "25px" }}>
            <Item title="Dashboard" to="/dashboard" icon={<HomeOutlinedIcon />} />

            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
              Modules
            </Typography>
            {/* {GetUserRole("order_list") && <Item title="Invoice Management" to="/orders" icon={<GradingIcon />} />} */}
            {GetUserRole("order_list") && <Item title="Orders" to="/orders" icon={<ListAltIcon />} />}
            {GetUserRole("product_list") && <Item title="Products" to="/products" icon={<Inventory2OutlinedIcon />} />}
            {GetUserRole("customer_list") && (
              <Item title="Customers" to="/customers" icon={<PeopleAltOutlinedIcon />} />
            )}
            {GetUserRole("category_list") && <Item title="Categories" to="/category" icon={<CategoryOutlinedIcon />} />}
            {GetUserRole("brand_list") && <Item title="Brands" to="/brand" icon={<StorefrontIcon />} />}
            {GetUserRole("tax_list") && <Item title="Tax" to="/tax" icon={<AttachMoneyIcon />} />}
            {GetUserRole("store_list") && (
              <Item title="Locations" to="/locations" icon={<StoreMallDirectoryOutlinedIcon />} />
            )}
            {user?.userType === "store" && (GetUserRole("store_view") || GetUserRole("store_tax_comm_view")) && (
              <>
                {GetUserRole("store_view") && <Item title="Assign Products" to="/locations" icon={<InventoryIcon />} />}
                {GetUserRole("store_tax_comm_view") && (
                  <Item title="Category Tree" to="/locationTax" icon={<AttachMoneyIcon />} />
                )}
              </>
              // <SubMenu title="Locations" icon={<StoreMallDirectoryOutlinedIcon />}>
              // </SubMenu>
            )}
            {/* {GetUserRole("category_list") && (
              <Item title="Payment Management" to="/category" icon={<AttachMoneyIcon />} />
            )} */}
            {/* {GetUserRole("inventory_list") && (
              <Item title="Inventories" to="/inventory" icon={<Inventory2OutlinedIcon />} />
            )} */}
            {GetUserRole("driver_list") && <Item title="Drivers" to="/driver" icon={<LocalShippingOutlinedIcon />} />}
            {/* {GetUserRole("vendor_list") && <Item title="Vendors" to="/vendors" icon={<StorefrontIcon />} />} */}
            {GetUserRole("user_list") && <Item title="Users" to="/users" icon={<PersonOutlineOutlinedIcon />} />}
            {GetUserRole("support_list") && <Item title="Support" to="/supports" icon={<SupportAgentIcon />} />}
            {GetUserRole("statement_all_view") && (
              <Item title="Statement" to="/statement" icon={<AssessmentOutlinedIcon />} />
            )}
            {(
              GetUserRole("location_report") 
            // || GetUserRole("product_view")
            ) && (
              <SubMenu defaultOpen={location?.pathname === "/report/location"} title="Reports" icon={<AssignmentOutlinedIcon />}>
                {/* {GetUserRole("store_view") && ( */}
                  <Item title="Location Income" to="/report/location" icon={<StoreMallDirectoryOutlinedIcon />} />
                {/* )} */}
                {/* {GetUserRole("product_view") && (
                  <Item title="Product Report" to="/report/product" icon={<Inventory2OutlinedIcon />} />
                )} */}
              </SubMenu>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
