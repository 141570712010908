import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
const OrderTabs = ({ value = "Orders", handlechange, items }) => {
  return (
    <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider", position: "sticky", top: 0 }}>
      <Tabs
        value={value}
        onChange={handlechange}
        textColor="primary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ "& .MuiButtonBase-root": { fontWeight: "bold" } }}
      >
        <Tab value="Orders" label="Order Items" />
        {items?.some((e) => e?.itemStatus === "returned") && (
          <Tab value="Return" label="Return" className="disabledTab" />
        )}
        {items?.some((e) => e?.itemStatus === "replaced") && (
          <Tab value="Replace" label="Replace" className="disabledTab" />
        )}
      </Tabs>
    </Box>
  );
};

export default OrderTabs;
