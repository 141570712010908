import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../theme";

export default function MyDeleteDialog({ handleClose, openDelete, name, onClick }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={openDelete} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
      <IconButton sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle variant="h4" sx={{ alignItems: "center", display: "flex", p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            alignItems: "center",
            display: "flex",
            mt: 0.5,
            textTransform: "capitalize"
          }}
        >
          <DeleteIcon size="medium" className="danger" />
          Delete The {name}?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure that you want to delete the {name} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          Disagree
        </Button>
        <Button
          variant="contained"
          sx={[
            {
              "&:hover": {
                bgcolor: colors.themeSecondaryBlueColor[100],
                color: colors.white[100]
              }
            },
            { bgcolor: colors.themeDarkGrey[100] }
          ]}
          onClick={onClick}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
