import { StoreDashboardMap, StoreMap } from "./type";

// category intial state
const initialStores = {
  stores: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  isEditLoading: false,
  storesDropdownData: [],
  dashboardDetailsFlag1: null,
  dashboardDetailsFlag2: null,
  dashboardDetailsFlag3: null,
  dashboardDetailsFlag4: null,
  dash1Loader: true,
  dash2Loader: true,
  dash3Loader: true,
  dash4Loader: true
};

export const reducer = (state = initialStores, action) => {
  switch (action.type) {
    case StoreMap.STORES_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.STORES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stores: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case StoreMap.STORES_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case StoreMap.STORE_ADD_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.STORE_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case StoreMap.STORE_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case StoreMap.STORE_UPDATE_REQUEST:
      return { ...state, isEditLoading: true };
    case StoreMap.STORE_UPDATE_SUCCESS: {
      return {
        ...state,
        isEditLoading: false
      };
    }

    case StoreMap.GET_STORES_DROPDOWN_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.GET_STORES_DROPDOWN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        storesDropdownData: action.payload.data
      };
    }
    case StoreMap.GET_STORES_DROPDOWN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case StoreMap.STORE_UPDATE_FAIL:
      return { ...state, isEditLoading: false, error: action.error };
    case StoreMap.STORE_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.STORE_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case StoreMap.STORE_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case StoreMap.GET_STORE_PRODCUTS_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.GET_STORE_PRODCUTS_SUCCESS:
      return { ...state, isLoading: false };
    case StoreMap.GET_STORE_PRODCUTS_FAIL:
      return { ...state, isLoading: false };
    case StoreMap.ADD_STORE_PRODCUTS_REQUEST:
      return { ...state, isLoading: true };
    case StoreMap.ADD_STORE_PRODCUTS_SUCCESS:
      return { ...state, isLoading: false };
    case StoreMap.ADD_STORE_PRODCUTS_FAIL:
      return { ...state, isLoading: false };
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1_REQUESTED: {
      const isLoading = true;
      return {
        ...state,
        isLoading: isLoading,
        error: null,
        dash1Loader: true,
        dash2Loader: true,
        dash3Loader: true,
        dash4Loader: true
      };
    }
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1:
      return { ...state, isLoading: false, dashboardDetailsFlag1: action.payload.data, dash1Loader: false };
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG2:
      return { ...state, isLoading: false, dashboardDetailsFlag2: action.payload.data, dash2Loader: false };
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG3:
      return { ...state, isLoading: false, dashboardDetailsFlag3: action.payload.data, dash3Loader: false };
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG4:
      return { ...state, isLoading: false, dashboardDetailsFlag4: action.payload.data, dash4Loader: false };
    case StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1_FAILED: {
      const error = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
        dash1Loader: false,
        dash2Loader: false,
        dash3Loader: false,
        dash4Loader: false
      };
    }
    default:
      return state;
  }
};
