import {
  SINGLEB_SUB_PRODUCT_FAIL,
  SINGLEB_SUB_PRODUCT_REQUEST,
  SINGLEB_SUB_PRODUCT_SUCESSS,
  SINGLE_PRODUCT_ADD_REQUEST,
  SINGLE_PRODUCT_ADD_SUCCESS,
  SINGLE_PRODUCT_DELETE_FAIL,
  SINGLE_PRODUCT_DELETE_REQUEST,
  SINGLE_PRODUCT_DELETE_SUCCESS,
  SINGLE_PRODUCT_UPDATE_FAIL,
  SINGLE_PRODUCT_UPDATE_REQUEST,
  SINGLE_PRODUCT_UPDATE_SUCCESS,
  SINGLE_SUB_PRODUCT_FAIL,
  SINGLE_SUB_PRODUCT_REQUEST,
  SINGLE_SUB_PRODUCT_SUCESSS,
  SINGLE_PRODUCT_ADD_FAIL,
  SINGLE_SUB_PRODUCT_RESET,
  GET_BRAND_DROPDOWN_REQUEST,
  GET_BRAND_DROPDOWN_FAIL,
  GET_BRAND_DROPDOWN_SUCESSS
} from "./type";
// category intial state
const initialSubProducts = {
  subProducts: null,
  subProduct: null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  brandsData: null
};

export const reducer = (state = initialSubProducts, action) => {
  switch (action.type) {
    case SINGLE_SUB_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case SINGLE_SUB_PRODUCT_SUCESSS: {
      const subProducts = action.payload.data;
      return {
        ...state,
        subProducts: subProducts,
        isLoading: false,
        success: true,
        total: action.payload.data.totalDocs
      };
    }
    case SINGLE_SUB_PRODUCT_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case SINGLE_PRODUCT_ADD_REQUEST:
      return { ...state, isLoading: true };
    case SINGLE_PRODUCT_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case SINGLE_PRODUCT_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case SINGLE_PRODUCT_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case SINGLE_PRODUCT_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case SINGLE_PRODUCT_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case GET_BRAND_DROPDOWN_REQUEST:
      return { ...state, isLoading: true };
    case GET_BRAND_DROPDOWN_SUCESSS:
      return { ...state, isLoading: false, success: true, brandsData: action.payload.data };
    case GET_BRAND_DROPDOWN_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case SINGLE_PRODUCT_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case SINGLE_PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        subProducts: {
          ...state.subProducts,
          records: state.subProducts.records.filter((x) => x._id !== action.payload)
        }
      };
    case SINGLE_PRODUCT_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case SINGLEB_SUB_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case SINGLEB_SUB_PRODUCT_SUCESSS: {
      const subProduct = action.payload;
      return { ...state, isLoading: false, subProduct: subProduct, success: true };
    }
    case SINGLEB_SUB_PRODUCT_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case SINGLE_SUB_PRODUCT_RESET: {
      return { ...state, subProducts: null };
    }
    default:
      return state;
  }
};
