import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteSubProduct, fetchSubProduct } from "./redux/action";
import MyDeleteDialog from "../../components/MyDeleteDialog";
import { useParams } from "react-router-dom";

export default function DeleteDialog({ openDelete, setDeleteOpen, rowId }) {
  const dispatch = useDispatch();
  const {id} = useParams();
  return (
    <MyDeleteDialog
      handleClose={() => setDeleteOpen(false)}
      openDelete={openDelete}
      name="sub product"
      onClick={() => {
        dispatch(deleteSubProduct(rowId)).then((data) => {
          if (data && data?.payload?.status) {
            setDeleteOpen(false);
            toast.success(data.payload?.message);
            dispatch(fetchSubProduct({ id: id }));
          } else {
            toast.error(data.error);
            setDeleteOpen(false);
          }
        });
      }}
    />
  );
}
