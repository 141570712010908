export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString; // In case if the number will be more than 10 digit, this will return the number itself without formating
}

export const validImages = ["jpg", "gif", "jpeg", "png"];
export const validFiles = ["jpg", "gif", "jpeg", "png", "pdf"];

export const isMaxLimitCross = (file) => {
  return file?.size >= 2000000;
};

export function fixFloating(value = 10, number = 2) {
  if (typeof value === "number") {
    return value.toFixed(number);
  } else if (typeof value === "string") {
    try {
      Number(value).toFixed(number);
    } catch (error) {
      console.log(error);
    }
  } else {
    return 0;
  }
}

export const reportPeriodOption = [
  {
    value: "all",
    name: "reportPeriodOption",
    label: "All Dates"
  },
  {
    value: "custom",
    name: "reportPeriodOption",
    label: "Custom"
  },
  {
    value: "today",
    name: "reportPeriodOption",
    label: "Today"
  },
  {
    value: "this_week",
    name: "reportPeriodOption",
    label: "This Week"
  },
  {
    value: "this_Week_to_date",
    name: "reportPeriodOption",
    label: "This Week To Date"
  },
  {
    value: "this_month_to_date",
    name: "reportPeriodOption",
    label: "This Month To Date"
  },
  {
    value: "previous_month",
    name: "reportPeriodOption",
    label: "Previous Month"
  },
  {
    value: "this_financial_quarter_to_date",
    name: "reportPeriodOption",
    label: "This Financial Quarter-to-date"
  },
  {
    value: "previous_financial_quarter",
    name: "reportPeriodOption",
    label: "previous Financial Quarter-to-date"
  },
  {
    value: "this_calendar_year_to_date",
    name: "reportPeriodOption",
    label: "This Calendar Year-to-date"
  },
  {
    value: "previous_calendar_year",
    name: "reportPeriodOption",
    label: "Previous Calendar Year"
  }
];
export const ORDERSTATUS = [
  {
    value: "orderPlaced",
    name: "ORDER_PLACED",
    label: "Order Placed"
  },
  {
    value: "orderFailed",
    name: "ORDER_FAILED",
    label: "Order Failed"
  },
  {
    value: "orderNotConfirmed",
    name: "ORDER_NOT_CONFIRMED",
    label: "Order Not Confirmed"
  },
  {
    value: "orderProcessing",
    name: "ORDER_PROCESSING",
    label: "Order Processing"
  },
  {
    value: "underModification",
    name: "UNDER_MODIFICATION",
    label: "Under Modification"
  },
  {
    value: "awaitingForDelivery",
    name: "AWAITING_FOR_DELIVERY",
    label: "Awaiting For Delivery"
  },
  {
    value: "driverConfirmed",
    name: "DRIVER_CONFIRMED",
    label: "Driver Confirmed"
  },
  {
    value: "outOfDelivery",
    name: "OUT_OF_DELIVERY",
    label: "Out Of Delivery"
  },
  {
    value: "delivered",
    name: "DELIVERED",
    label: "Delivered"
  },
  {
    value: "cancelled",
    name: "CANCELLED",
    label: "Cancelled"
  },
  {
    value: "returned",
    name: "RETURNED",
    label: "Returned"
  },
  {
    value: "replaced",
    name: "REPLACED",
    label: "Replaced"
  },
  {
    value: "returnRejected",
    name: "RETURN_REJECTED",
    label: "Return Rejected"
  },
  {
    value: "replacementRejected",
    name: "REPLACEMENT_REJECTED",
    label: "Replacement Rejected"
  }
];
// keep the spelling of rejected same in both return and replace status
export const RETURNREPLACESTATUS = [
  {
    value: "requested",
    name: "REQUESTED",
    label: "Requested"
  },
  {
    value: "approved",
    name: "APPROVED",
    label: "Approved"
  },
  {
    value: "rejected",
    name: "REJECTED",
    label: "Rejected"
  },
  {
    value: "driverApproved",
    name: "DRIVER_APPROVED",
    label: "Driver Approved"
  },
  {
    value: "driverOnTheWay",
    name: "DRIVER_ON_THE_WAY",
    label: "driver On The Way"
  },
  {
    value: "driverPickedUp",
    name: "DRIVER_PICKED_UP",
    label: "driver Picked Up"
  },
  {
    value: "locationReceived",
    name: "LOCATION_RECEIVED",
    label: "Location Received"
  },
  {
    value: "refundProcessing",
    name: "REFUND_PROCESSING",
    label: "Refund Processing"
  },
  {
    value: "returned",
    name: "RETURNED",
    label: "Returned"
  },
  {
    value: "returnFailed",
    name: "RETURN_FAILED",
    label: "Return Failed"
  },
  {
    value: "driverPickupPending",
    name: "DRIVER_PICKUP_PENDING",
    label: "Driver Pickup Pending"
  },
  {
    value: "awaitingForDelivery",
    name: "AWAITING_FOR_DELIVERY",
    label: "Awaiting For Delivery"
  },
  {
    value: "driverPickupConfirmed",
    name: "DRIVER_PICKUP_CONFIRMED",
    label: "Driver Pickup Confirmed"
  },
  {
    value: "outOfDelivery",
    name: "OUT_OF_DELIVERY",
    label: "Out Of Delivery"
  },
  {
    value: "replaced",
    name: "REPLACED",
    label: "Replaced"
  },
  {
    value: "replaceDelivered",
    name: "REPLACE_DELIVERED",
    label: "Replace Delivered"
  }
];

export const chartOptions = (dashboardOne) => {
  return {
    colors: ["#009393"],
    fill: {
      type: "gradient"
    },
    stroke: {
      curve: "smooth"
    },
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    // legend: {
    //   tooltipHoverFormatter: function (val, opts) {
    //     return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
    //   }
    // },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: dashboardOne && dashboardOne.length > 0 ? dashboardOne.map((x) => x.orderDate) : ["0"]
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value?.toFixed(2);
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    }
  };
};

export const chartSeries = (dashboardOne) => [
  {
    name: "Sales",
    data: dashboardOne && dashboardOne?.length > 0 ? dashboardOne.map((x) => x.totalAmount) : [0]
  }
];
