export default function SpecificationGRP(parent, current, all) {
  let newArray = [];
  all?.forEach((x) => {
    if (parent?.length > 0) {
      parent?.forEach((rx) => {
        if (rx._id === x._id) {
          return newArray.push({ value: x._id, label: `${x.name}`, isFixed: true });
        }
      });
    }
    if (current?.length > 0) {
      current?.forEach((rx) => {
        if (rx._id === x._id) {
          return newArray.push({ value: x._id, label: `${x.name}`, isFixed: false });
        }
      });
    }
  });
  return newArray;
}
