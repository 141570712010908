import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import {
  FETCH_DRIVER,
  GET_DRIVERS,
  DRIVERS_FAIL,
  DRIVERS_REQUEST,
  DRIVERS_SUCCESS,
  DRIVER_ADD_FAIL,
  DRIVER_ADD_REQUEST,
  DRIVER_ADD_SUCCESS,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_SUCCESS,
  DRIVER_FAIL,
  DRIVER_REQUEST,
  DRIVER_UPDATE_FAIL,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS,
  GENERATE_REQUEST,
  GENERATE_SUCCESS,
  GENERATE_FAIL,
  STATEMENTS_REQUEST,
  STATEMENTS_SUCCESS,
  STATEMENTS_FAIL,
  STATEMENTS_VIEW_REQUEST,
  STATEMENTS_VIEW_SUCCESS,
  STATEMENTS_VIEW_FAIL,
  INVOICE_STATEMENTS_VIEW_REQUEST,
  INVOICE_STATEMENTS_VIEW_SUCCESS,
  INVOICE_STATEMENTS_VIEW_FAIL,
  PAYOUT_REQUEST,
  PAYOUT_SUCCESS,
  PAYOUT_FAIL,
  PDF_GET_REQUEST,
  PDF_GET_SUCCESS
} from "./type";
// import useEncryption from "../../components/Common/useEncryption";

export const fetchDrivers = (body) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_REQUEST });
    let apiEndpoint = `${API_END_POINTS.drivers}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: GET_DRIVERS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: DRIVER_FAIL, error: message });
  }
};

export const addDriver = (body) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addDriver}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: DRIVER_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: DRIVER_ADD_FAIL, error: message });
  }
};

export const getDriver = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_DRIVER,
      payload: id
    });
  } catch (error) {}
};

export const updateDriver = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editDriver}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: DRIVER_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: DRIVER_UPDATE_FAIL, error: message });
  }
};

export const deleteDriver = (id) => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteDriver}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);

    return dispatch({
      type: DRIVER_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: DRIVER_UPDATE_SUCCESS, error: message });
  }
};

export const fetchDropdownDrivers = () => async (dispatch) => {
  try {
    dispatch({ type: DRIVERS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getDriversEndpoint}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`);
    return dispatch({
      type: DRIVERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: DRIVERS_FAIL, error: message });
  }
};

export const generateStatement = (body) => async (dispatch) => {
  try {
    dispatch({ type: GENERATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.generateStatements}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`, body);
    return dispatch({
      type: GENERATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: GENERATE_FAIL, error: message });
  }
};

export const getStatements = (body) => async (dispatch) => {
  try {
    dispatch({ type: STATEMENTS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getStatements}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: STATEMENTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: STATEMENTS_FAIL, error: message });
  }
};
export const getLocationStatements = (body) => async (dispatch) => {
  try {
    dispatch({ type: STATEMENTS_VIEW_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getLocationStatements}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: STATEMENTS_VIEW_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: STATEMENTS_VIEW_FAIL, error: message });
  }
};
export const viewStatements = (body, noerr) => async (dispatch) => {
  try {
    dispatch({ type: STATEMENTS_VIEW_REQUEST });
    let apiEndpoint = `${API_END_POINTS.viewStatements}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: STATEMENTS_VIEW_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    !noerr && toast.error(message);
    return dispatch({ type: STATEMENTS_VIEW_FAIL, error: message });
  }
};
export const viewInvoiceStatements = (body) => async (dispatch) => {
  try {
    dispatch({ type: INVOICE_STATEMENTS_VIEW_REQUEST });
    let apiEndpoint = `${API_END_POINTS.viewInvoiceStatements}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: INVOICE_STATEMENTS_VIEW_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: INVOICE_STATEMENTS_VIEW_FAIL, error: message });
  }
};

export const payout = (body) => async (dispatch) => {
  try {
    dispatch({ type: PAYOUT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.payout}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PAYOUT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: PAYOUT_FAIL, error: message });
  }
};

export const viewPDFData = (id) => async (dispatch) => {
  try {
    dispatch({ type: PDF_GET_REQUEST });
    let apiEndpoint = `${API_END_POINTS.satamentPDF}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, { invoiceNo: id });
    //
    return dispatch({
      type: PDF_GET_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (error?.data?.message) {
      const message = error && error.data.message ? error.data.message : error.message;
      toast.error(message);
      return dispatch({ type: PDF_GET_REQUEST, error: message });
    } else {
      toast.error("error occur");
      return dispatch({ type: PDF_GET_REQUEST, error: "error occur" });
    }
  }
};
