import { CategoryMap } from "./type";
import { TAXES_FAIL, TAXES_REQUEST, TAXES_SUCCESS } from "../../tax/redux/type";

// category intial state
const initialCategories = {
  categories: null,
  categoryTreeList: null,
  categoriesTreeListTrue:null,
  isLoading: false,
  error: null,
  success: false,
  total: null,
  dropDownTaxes: null,
  isSpecificationLoading: false,
  specifications: [],
  categoriesTaxTreeList: null,
  isTaxCategoryLoading: false
};

export const reducer = (state = initialCategories, action) => {
  switch (action.type) {
    case CategoryMap.CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.GET_CATEGORIES: {
      const categories = action.payload.data.records;
      return {
        ...state,
        categories: categories,
        isLoading: false,
        total: action.payload.data.totalRecords
      };
    }
    case CategoryMap.CATEGORY_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CategoryMap.CATEGORY_ADD_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_TREE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_TREE_SUCCESS: {
      const categoriesTreeList = action.payload.data;
      if (action.payload.flag === false) {
        return {
          ...state,
          categoriesTreeList: categoriesTreeList,
          isLoading: false
        };
      } else {
        return {
          ...state,
          categoriesTreeListTrue: categoriesTreeList,
          isLoading: false
        };
      }
    }
    case CategoryMap.CATEGORY_TREE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case CategoryMap.CATEGORY_TAX_TREE_REQUEST:
      return { ...state, isTaxCategoryLoading: true };
    case CategoryMap.CATEGORY_TAX_TREE_SUCCESS: {
      const categoriesTaxTreeList = action.payload.data;
      return {
        ...state,
        categoriesTaxTreeList: categoriesTaxTreeList,
        isTaxCategoryLoading: false
      };
    }
    case CategoryMap.CATEGORY_TAX_TREE_FAIL: {
      return { ...state, isTaxCategoryLoading: false, error: action.error };
    }
    case TAXES_REQUEST:
      return { ...state, isLoading: true };
    case TAXES_SUCCESS:
      const dropDownTaxes = action.payload.data;
      return {
        ...state,
        dropDownTaxes: dropDownTaxes,
        isLoading: false,
        success: true
      };
    case TAXES_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.CATEGORY_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.CATEGORY_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.CATEGORY_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case CategoryMap.STATUS_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case CategoryMap.STATUS_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case CategoryMap.STATUS_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case CategoryMap.SPECIFICATIONS_REQUEST:
      return { ...state, isSpecificationLoading: true };
    case CategoryMap.SPECIFICATIONS_SUCCESS:
      const specifications = action.payload.data;
      return { ...state, isSpecificationLoading: false, specifications: specifications };
    case CategoryMap.SPECIFICATIONS_FAIL: {
      return { ...state, isSpecificationLoading: false, error: action.error };
    }
    default:
      return state;
  }
};
