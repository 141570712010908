import React from "react";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetPasswordResend } from "../pages/login/redux/action";

export default function ResendPasswordPopover({ resetData }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} sx={{ p: 0.5 }} onClick={handleClick}>
        <ErrorIcon fontSize="small" color="error" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        // PaperProps={{
        //   style: {
        //     overflow: "visible"
        //   }
        // }}
      >
        <Box
          sx={{
            p: 2
            // position: "relative",
            // "&::before": {
            //   backgroundColor: "white",
            //   content: '""',
            //   display: "block",
            //   position: "absolute",
            //   width: "12px",
            //   height: "12px",
            //   top: "0",
            //   left: "49%",
            //   transform: "rotate(45deg) translateX(-40%)"
            // }
          }}
        >
          <Typography>Are you sure you want to reset password link?</Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "8px" }}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                await dispatch(resetPasswordResend(resetData)).then(async (data) => {
                  if (data && data?.payload?.status) {
                    setLoading(false);
                    toast.success(data.payload.message);
                    handleClose();
                  }
                });
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
