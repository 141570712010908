import {
  GET_DRIVERS,
  DRIVER_ADD_FAIL,
  DRIVER_ADD_REQUEST,
  DRIVER_ADD_SUCCESS,
  DRIVER_DELETE_FAIL,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_SUCCESS,
  DRIVER_FAIL,
  DRIVER_REQUEST,
  DRIVER_UPDATE_FAIL,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS
} from "./type";

// category intial state
const initialDrivers = {
  drivers: [],
  isLoading: false,
  isEditLoading: true,
  driversForEdit: null,
  error: null,
  success: false,
  total: null
};

export const reducer = (state = initialDrivers, action) => {
  switch (action.type) {
    case DRIVER_REQUEST:
      return { ...state, isLoading: true };
    case GET_DRIVERS: {
      return {
        ...state,
        drivers: action.payload.data.records,
        isLoading: false,
        success: true,
        total: action.payload.data.totalRecords
      };
    }
    case DRIVER_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case DRIVER_ADD_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case DRIVER_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case DRIVER_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    default:
      return state;
  }
};
