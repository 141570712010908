import { Box, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "calc(100vh - 121px)",
        textAlign: "center"
      }}
    >
      <Typography variant="h1" sx={{ fontSize: 220 }}>
        404
      </Typography>
      <Typography variant="h4">The page you’re looking for doesn’t exist.</Typography>
    </Box>
  );
}
