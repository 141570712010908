import React, { useState } from "react";
import {
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { convertToDollar, formatDate } from "../../../utils/custom";
import StatementAddModal from "../StatementAddModal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getStatements, payout, viewInvoiceStatements, viewPDFData, viewStatements } from "../redux/action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GetUserRole from "../../../utils/GetUserRole";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import TableWrapper from "../../../components/TableWrapper";
import DataNotFound from "../../../components/DataNotFound";
import MyTaxGridToolbar from "../filter/MyDriverGridToolbar";

export default function StatementsViewTable({
  statements,
  isLoading,
  currentStatementId,
  location,
  setReqData,
  reqData,
  total,
  setCurrentStatementId
}) {
  const [openModal, setOpenModal] = useState(false);
  const [storeName, setStoreName] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const statemetPayment = GetUserRole("statement_payment");

  const printInvoiceData = async (pdfWindow, id) => {
    await dispatch(viewPDFData(id)).then(async (data) => {
      if (data && data?.payload?.status) {
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + data?.payload?.data + "'></iframe>");
        window.open(data?.payload?.data, "_blank");
      }
    });
  };

  const apiRef = useGridApiRef();
  const restorePaginationState = () => {
    const state = apiRef.current.exportState();
    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
          pageSize: 10
        }
      }
    };
    apiRef.current.restoreState(restoredState);
  };

  const columns = [
    {
      field: "ID",
      headerName: "ID",
      flex: 1,
      width: 100,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography color={colors.secondary[900]} fontWeight="bold">
          {id}
        </Typography>
      )
    },
    {
      field: "Statement Id",
      headerName: "Statement Id",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography
          color={colors.secondary[900]}
          fontWeight="bold"
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => {
            setOpenModal(true);
            setStoreName(row.storeName);
            dispatch(viewInvoiceStatements({ itemId: row._id })).then(async (data) => {
              if (data && data?.payload?.status) {
                toast.success(data.payload.message);
              }
            });
          }}
        >
          {row.invoiceNo}
        </Typography>
      )
    },
    {
      field: "Generated Date",
      headerName: "Generated Date",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography sx={{ textTransform: "capitalize" }}>{row?.createdDate && formatDate(row.createdDate)}</Typography>
      )
    },
    // {
    //   field: "Location Name",
    //   headerName: "Location Name",
    //   sortable: false,
    //   renderCell: ({ row, id }) => <Typography sx={{ textTransform: "capitalize" }}>{row.storeName}</Typography>,
    //   hideable: !location
    // },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => {
        return (
          <>
            {row.status === "paid" || !statemetPayment ? (
              <Typography className="text-capitalize">{row.status}</Typography>
            ) : (
              <Select
                value={row.status}
                onChange={(e) => {
                  dispatch(
                    payout({
                      itemId: row._id
                    })
                  ).then(async (data) => {
                    if (data && data?.payload?.status) {
                      toast.success(data.payload.message);
                      await dispatch(viewStatements({ statementId: currentStatementId && currentStatementId }));
                      // await dispatch(getStatements(reqData));
                    } else {
                      toast.error(data.error);
                    }
                  });
                }}
                className={`orderTableSelect${id}`}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"paid"}>Paid</MenuItem>
              </Select>
            )}
          </>
        );
      }
    },
    // {
    //   field: "Total Admin Comission",
    //   headerName: "Total Admin Comission",
    //   sortable: false,
    //   renderCell: ({ row, id }) => (
    //     <Typography sx={{ textTransform: "capitalize" }}>
    //       {row.totalAdminComm ? convertToDollar(row.totalAdminComm) : "$0.00"}
    //     </Typography>
    //   )
    // },
    {
      field: "Total Location Amount",
      headerName: "Total Location Amount",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {row.totalLocationAmount ? convertToDollar(row.totalLocationAmount.toFixed(2)) : "$0.00"}
        </Typography>
      )
    },
    {
      field: "Total Amount",
      headerName: "Total Amount",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {row.totalAmount ? convertToDollar(row.totalAmount.toFixed(2)) : "$0.00"}
        </Typography>
      )
    },
    {
      field: "PDF",
      headerName: "PDF",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <span>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              var pdfWindow = window.open("", "_blank");
              printInvoiceData(pdfWindow, row.invoiceNo);
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </span>
      )
    }
  ];
  return (
    <>
      {!location ? (
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader aria-label="sticky table" sx={{ borderBottom: "1px solid #ddd" }}>
            <TableHead sx={{ textWrap: "nowrap" }}>
              <TableRow>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>ID</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Statement Id</Typography>
                </TableCell>
                {!location && (
                  <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                    <Typography>Location Name</Typography>
                  </TableCell>
                )}
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Status</Typography>
                </TableCell>
                {!location && (
                  <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                    <Typography>Total Admin Comission</Typography>
                  </TableCell>
                )}
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Total Location Amount</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Total Amount</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>PDF</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements && statements.length > 0 ? (
                statements.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Typography color={colors.secondary[900]} fontWeight="bold">
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color={colors.secondary[900]}
                        fontWeight="bold"
                        sx={{ cursor: "pointer", width: "fit-content" }}
                        onClick={() => {
                          setOpenModal(true);
                          setStoreName(row.storeName);
                          dispatch(viewInvoiceStatements({ itemId: row._id })).then(async (data) => {
                            if (data && data?.payload?.status) {
                              toast.success(data.payload.message);
                            }
                          });
                        }}
                      >
                        {row.invoiceNo}
                      </Typography>
                    </TableCell>

                    {!location && (
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>{row.storeName}</Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      <>
                        {row.status === "paid" || !statemetPayment ? (
                          <Typography className="text-capitalize">{row.status}</Typography>
                        ) : (
                          <Select
                            value={row.status}
                            onChange={(e) => {
                              dispatch(
                                payout({
                                  itemId: row._id
                                })
                              ).then(async (data) => {
                                if (data && data?.payload?.status) {
                                  toast.success(data.payload.message);
                                  await dispatch(
                                    viewStatements({ statementId: currentStatementId && currentStatementId })
                                  );
                                  await dispatch(getStatements(reqData));
                                  setCurrentStatementId("");
                                } else {
                                  toast.error(data.error);
                                }
                              });
                            }}
                            className={`orderTableSelect${index}`}
                          >
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"paid"}>Paid</MenuItem>
                          </Select>
                        )}
                      </>
                    </TableCell>
                    {!location && (
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {row.totalAdminComm ? convertToDollar(row.totalAdminComm) : "$0.00"}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell>
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {row.totalLocationAmount ? convertToDollar(row.totalLocationAmount) : "$0.00"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {row.totalAmount ? convertToDollar(row.totalAmount) : "$0.00"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <span>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            var pdfWindow = window.open("", "_blank");
                            printInvoiceData(pdfWindow, row.invoiceNo);
                          }}
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} sx={{ p: 0 }}>
                    <DataNotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableWrapper sx={{ mb: 4 }}>
          <DataGrid
            apiRef={apiRef}
            getRowHeight={() => "auto"}
            disableColumnFilter
            rows={
              statements?.records?.length > 0
                ? statements?.records?.map((product, i) => ({
                    id: reqData.limit * reqData.page - reqData.limit + (i + 1),
                    ...product
                  }))
                : []
            }
            disableRowSelectionOnClick={true}
            columns={columns}
            slotProps={{
              toolbar: {
                printOptions: { hideFooter: true, hideToolbar: true },
                reqData,
                setReqData,
                location,
                restorePaginationState
              }
            }}
            components={{ Toolbar: GridToolbar }}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: MyTaxGridToolbar
            }}
            loading={isLoading}
            selectable="false"
            rowCount={total || 0}
            paginationMode="server"
            sortingMode="server"
            onRowClick={(rowData) => {}}
            pagination={location}
            onPaginationModelChange={(e) => {
              setReqData(
                Object.assign({}, reqData, {
                  page: e.page + 1,
                  limit: e.pageSize
                })
              );
            }}
            rowsPerPage={reqData.limit}
            onSortModelChange={(newSortModel) => {
              if (newSortModel.length > 0) {
                setReqData(
                  Object.assign({}, reqData, {
                    key: newSortModel[0]?.field,
                    desc: newSortModel[0]?.sort === "asc" ? false : true
                  })
                );
              } else {
                setReqData(
                  Object.assign({}, reqData, {
                    key: "_id",
                    desc: true
                  })
                );
              }
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            sx={{
              "@media print": {
                ".MuiDataGrid-main": {
                  width: "fit-content",
                  fontSize: "14px",
                  overflow: "visible",
                  border: "none !important"
                },
                border: "none !important",
                ".MuiDataGrid-main .MuiDataGrid-columnHeader": {
                  color: "#000 !important"
                },
                ".MuiDataGrid-main .MuiDataGrid-cell": {
                  whiteSpace: "normal !important",
                  maxHeight: "unset !important"
                },
                ".MuiDataGrid-main .MuiDataGrid-row": {
                  maxHeight: "unset !important"
                },
                ".MuiDataGrid-main .MuiDataGrid-virtualScroller": {
                  height: "auto !important",
                  overflow: "unset !important"
                }
              }
            }}
          />
        </TableWrapper>
      )}

      {openModal && <StatementAddModal openModal={openModal} setOpenModal={setOpenModal} storeName={storeName} />}
    </>
  );
}
