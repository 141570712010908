import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { Box, Button, TextField, Grid, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { createBrand, fetchBrands, updateBrand } from "./redux/action";
import { tokens } from "../../theme";
import MyDialog from "../../components/MyDialog";
import { toast } from "react-toastify";
import ImageClose from "../../components/ImageClose";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";
import { fetchBrandsData } from "../subproduct/redux/action";
import { isMaxLimitCross, validImages } from "../../utils/common";

export default function BrandAddModal({
  open,
  handleClose,
  reqData,
  rowData,
  isLoading,
  isBrandFromProduct,
  setNewBrand
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  // const [zipFlag, setZipFlag] = useState(false);
  const [files, setFiles] = useState([]);

  const defaultStates = {
    brandName: "",
    brandImg: []
  };

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: defaultStates
  });

  useEffect(() => {
    if (!rowData?._id && !open) {
      reset(defaultStates);
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [open, reset, rowData?._id]);

  useEffect(() => {
    if (rowData?._id) {
      reset({
        brandName: rowData?.brandName,
        brandImg: []
      });
      if (rowData.brandImg && rowData.brandImg.fileUrl) {
        setFiles([rowData.brandImg]);
      }
    } else {
      reset(defaultStates);
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [rowData?._id]);

  const onSubmit = async (data) => {
    let formDataInfo = new FormData();
    formDataInfo.append("brandName", data.brandName);
    if (data.brandImg.length > 0)
      data.brandImg.map((x) => {
        return formDataInfo.append("brandImg[]", x);
      });
    if (Object.keys(errors).length === 0) {
      if (rowData?._id) {
        await dispatch(updateBrand(formDataInfo, rowData?._id)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              toast.success(data.payload.message);
              setNewBrand && setNewBrand(data?.payload?.data?._id);
              dispatch(fetchBrands(reqData));
              reset(defaultStates);
              handleClose();
              setFiles([]);
            }
          }
        });
      } else {
        await dispatch(createBrand(data.brandImg && formDataInfo)).then(async (data) => {
          if (data && data.payload) {
            if (data && data.payload.status) {
              toast.success(data.payload.message);
              setNewBrand && setNewBrand(data?.payload?.data?._id);
              dispatch(fetchBrands(reqData));
              if (isBrandFromProduct) {
                dispatch(fetchBrandsData());
              }
              reset(defaultStates);
              handleClose();
              setFiles([]);
            }
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
          setFiles([]);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">{rowData?._id ? "Edit Brand" : "Create New Brand"}</DialogTitle>
          <DialogContent>
            <RequiredFieldLabel />
            <Box>
              <Grid container spacing={gridSpacing}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Brand Name <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("brandName", { required: true, maxLength: 100 })}
                    />
                    {errors.brandName && errors.brandName.type === "required" && (
                      <ErrorMSG text="Brand Name is required" />
                    )}
                    {errors.brandName && errors.brandName.type === "maxLength" && (
                      <ErrorMSG text="Brand name should not be greater than length 100" />
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12} sx={{ mx: "", mb: 1 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap="4px">
                    <FormControl sx={{ width: "100%" }}>
                      <label className="fileUploaderLabel z-index-2">
                        Brand Image <span className="danger mr-0">*</span>
                      </label>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              type="file"
                              size="small"
                              className={` z-index-1 customFileInput customFileInputButton customFileInputButton-${
                                navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                              }`}
                              inputProps={{
                                multiple: false,
                                accept: "image/png, image/gif, image/jpg, image/jpeg"
                              }}
                              onChange={async (e) => {
                                let newArray = [];
                                if (isMaxLimitCross(e.target.files[0])) {
                                  e.target.value = null;
                                  return toast.error("Maximum image size is 2MB");
                                }
                                for await (const [, value] of Object.entries(e.target.files)) {
                                  const idxDot = value.name.lastIndexOf(".") + 1;
                                  const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                  if (validImages.includes(extFile)) {
                                    newArray = [...newArray, value];
                                  } else {
                                    return toast.error("Only jpg/jpeg, gif and png files are allowed!");
                                  }
                                }
                                await onChange(newArray);
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </>
                        )}
                        control={control}
                        name="brandImg"
                        rules={{ required: files.length > 0 ? false : true }}
                      />
                      {errors.brandImg && errors.brandImg.type === "required" && (
                        <ErrorMSG text="Brand Image is required" />
                      )}
                    </FormControl>
                    {files && rowData?._id ? (
                      <Box className="vendorModalImageThumb" sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                        {rowData?._id && (
                          <ImageClose files={files} setFiles={setFiles} rowData={rowData} type="vendor" />
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
                setFiles([]);
              }}
            >
              Close
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
