import { useTheme } from "@emotion/react";
import { Box, Button, Card, FormControl, Grid, TextareaAutosize, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addMessageToSupport, getSingleSupport } from "./redux/action";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import SupportChat from "./SupportChat";
import SupportBox from "../../components/SupportBox";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SendIcon from "@mui/icons-material/Send";
import { InternalPageHeader } from "../../components/Header";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { formatPhoneNumber, validFiles } from "../../utils/common";
import GetUserRole from "../../utils/GetUserRole";

export default function SupportView() {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const imagesref = useRef(null);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const { support } = useSelector((state) => state.support);
  const [disableBtn, setDisableBtn] = useState(true);
  const [messageForm, setMessageForm] = useState({ comment: "" });
  const containerRef = useRef(null);
  const viewSupportRole = GetUserRole("support_view");

  useEffect(() => {
    if (id) {
      dispatch(getSingleSupport(id));
    }
  }, [dispatch, id]);

  // submit handler
  const addMessageToSupportHandler = async (data) => {
    let formDataInfo = new FormData();
    formDataInfo.append("id", id);
    formDataInfo.append("comment", messageForm.comment);
    formDataInfo.append("isSolved", false);
    if (file.length > 0) {
      file?.map((x) => formDataInfo.append("attachments[]", x));
    }
    await dispatch(addMessageToSupport(formDataInfo)).then(async (res) => {
      if (res?.payload.status === true) {
        dispatch(getSingleSupport(id));
        setMessageForm({ comment: "" });
        setFile([]);
      }
    });
  };

  useEffect(() => {
    // Scroll to the bottom of the container on initial render
    scrollToBottom();

    // Scroll to the bottom whenever new content is added
    const interval = setTimeout(() => {
      scrollToBottom();
    }, 1000); // Adjust the interval as needed

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [messageForm]);

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  return (
    <>
      <Box mb={3} mx="20px">
        {/* <Button
          color="primary"
          variant="outlined"
          sx={{ mb: 2 }}
          //   startIcon={<BackIcon width="20px" height="20px" color={colors.primary[900]} />}
          onClick={() => {
            navigate(`/supports`);
          }}
        >
          Back
        </Button> */}
        <InternalPageHeader to="/supports" title="Support View" />
        <Card sx={{ p: 2, mt: 2, mb: 3 }}>
          {support?.type === "Feedback" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px dashed #e0e0e0",
                mb: 2,
                pb: 2
              }}
            >
              <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
                FEEDBACK VIEW:
              </Typography>
            </Box>
          )}
          {support?.type === "Support" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px dashed #e0e0e0",
                mb: 1.5,
                pb: 1
              }}
            >
              <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
                SUPPORT ID:{" "}
                <span style={{ fontWeight: "bold", color: colors.themePrimary[900], lineHeight: "0px" }}>
                  {support?.requestId ? support?.requestId : "NA"}
                </span>
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                {support?.status === "created" ? (
                  <Box sx={{ mr: 1, width: "24px", height: "24px", color: colors.themeGray[900] }}>
                    <DriveFileRenameOutlineIcon />
                  </Box>
                ) : support?.status === "awaiting_response" ? (
                  <Box sx={{ mr: 1, width: "24px", height: "24px", color: colors.secondary[900] }}>
                    <HourglassBottomIcon />
                  </Box>
                ) : support?.status === "under_review" ? (
                  <Box sx={{ mr: 1, width: "24px", height: "24px", color: colors.warning[900] }}>
                    <VisibilityIcon />
                  </Box>
                ) : support?.status === "solved" ? (
                  <Box sx={{ mr: 1, width: "24px", height: "24px", color: colors.success[900] }}>
                    <DoneAllIcon />
                  </Box>
                ) : (
                  ""
                )}
                <Typography
                  variant="h4"
                  mb={0}
                  color={
                    support?.status === "created"
                      ? colors.themeGray[900]
                      : support?.status === "awaiting_response"
                      ? colors.secondary[900]
                      : support?.status === "under_review"
                      ? colors.warning[900]
                      : support?.status === "solved"
                      ? colors.success[900]
                      : colors.themePrimary[900]
                  }
                  sx={{ textTransform: " capitalize", fontWeight: "bold" }}
                >
                  {support?.status ? (support?.status).replace(/_/, " ") : "N/A"}
                  {/* {support?.status ? stringFormat(support?.status) : "N/A"} */}
                </Typography>
              </Box>
            </Box>
          )}

          <Grid container spacing={gridSpacing}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                {support?.type === "Support" && (
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography variant="h5" color={colors.secondary[900]}>
                      Request ID:
                    </Typography>
                    <Typography variant="h5">{support?.requestId ? support?.requestId : "NA"}</Typography>
                  </Grid>
                )}
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant="h5" color={colors.secondary[900]}>
                    Full Name:
                  </Typography>
                  <Typography variant="h5" className="word-break-all">
                    {support?.firstName || support?.lastName ? `${support?.firstName} ${support?.lastName}` : "NA"}
                  </Typography>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant="h5" color={colors.secondary[900]}>
                    Email:
                  </Typography>
                  <Typography variant="h5" sx={{ wordBreak: "break-all" }}>
                    {support?.email ? support?.email : "NA"}
                  </Typography>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant="h5" color={colors.secondary[900]}>
                    Phone:
                  </Typography>
                  <Typography variant="h5">
                    {support?.phone && formatPhoneNumber(`+1${support?.phone ? support.phone : "N/A"}`)}
                  </Typography>
                </Grid>
                {support?.type === "Support" && (
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography variant="h5" color={colors.secondary[900]}>
                      Type Of Issue:
                    </Typography>
                    <Typography variant="h5">{support?.typeOfIssue ? support?.typeOfIssue : "NA"}</Typography>
                  </Grid>
                )}
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant="h5" color={colors.secondary[900]}>
                    {support?.type === "Support" ? "Subject" : "Feedback"}
                  </Typography>
                  <Typography variant="h5">{support?.subject ? support?.subject : "NA"}</Typography>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography variant="h5" color={colors.secondary[900]}>
                    Created At:
                  </Typography>

                  <Typography variant="h5">{moment(support?.createdAt).format("MM-DD-YYYY z")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setDisableBtn(true);
              addMessageToSupportHandler();
            }}
          >
            <Box>
              <Box
                ref={containerRef}
                sx={{
                  p: 1,
                  borderRadius: "4px",
                  background: "#e0e0e0",
                  border: "1px solid #d0d7de",
                  minHeight: "20vh",
                  maxHeight: "60vh",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <ul style={{ paddingLeft: 0, display: "table" }}>
                  {support?.subSupport.length > 0 &&
                    support?.subSupport.map((e, i) => {
                      return (
                        <li
                          key={i}
                          className={`list-style-none ${
                            e.repliedBy === undefined ? "organizerChatBubble " : "userChatBubble"
                          }`}
                        >
                          <Box
                            sx={{
                              m: 1,
                              p: 1.5,
                              background: `${e.repliedBy === undefined ? colors.white[100] : "#e9f4ff"}`,
                              borderRadius: "4px",
                              maxWidth: "70vw",
                              width: "max-content",
                              ml: `${e.repliedBy === undefined ? "0" : "auto"}`,
                              mr: `${e.repliedBy === undefined ? "auto" : "0"}`
                            }}
                          >
                            <Typography sx={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}>
                              {e.comment}
                            </Typography>

                            {e.attachments.length > 0 && (
                              <SupportChat files={e.attachments.length > 0 ? e.attachments : []} type="attachment1" />
                            )}

                            <Typography color="#909090" mb="0" textAlign="right" fontSize="14px">
                              <i>{moment(e?.createdAt).format("MM-DD-YYYY z")}</i>
                            </Typography>
                          </Box>
                        </li>
                      );
                    })}
                </ul>
              </Box>
            </Box>
            {GetUserRole("support_reply") && support?.type === "Support" && (
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "8px",
                    flexWrap: "wrap"
                  }}
                >
                  {file.length > 0 && <SupportBox files={file.length > 0 ? file : []} setFile={setFile} />}
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" gap="8px">
                  <Box sx={{ position: "relative", width: "100%" }}>
                    <FormControl fullWidth>
                      <TextareaAutosize
                        className="customTextarea"
                        placeholder="Enter your message here..."
                        onChange={(e) => {
                          setMessageForm({ ...messageForm, comment: e.target.value });
                          if (e.target.value) {
                            setDisableBtn(false);
                          } else {
                            setDisableBtn(true);
                          }
                        }}
                        value={messageForm.comment}
                        minRows={1}
                        // InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>

                    <Box sx={{ position: "absolute", bottom: "10px", right: "10px" }}>
                      <label htmlFor="supportAttachMedia">
                        <Box className="attachFileBtn" color={colors.secondary[900]}>
                          <AttachmentIcon width="20px" height="20px" />
                        </Box>
                      </label>
                      <input
                        ref={imagesref}
                        hidden
                        type="file"
                        multiple={true}
                        id="supportAttachMedia"
                        name="supportAttachMedia"
                        onChange={async (e) => {
                          setDisableBtn(false);
                          if (e.target.files) {
                            let imageArray = [];
                            // eslint-disable-next-line array-callback-return
                            await Array.from(e.target.files).map((f) => {
                              const idxDot = f.name.lastIndexOf(".") + 1;
                              const extFile = f.name.substr(idxDot, f.name.length).toLowerCase();
                              if (validFiles.includes(extFile)) {
                                if (f?.size <= 2000000) {
                                  imageArray = [...imageArray, f];
                                } else {
                                  setDisableBtn(false);
                                  toast.error("Please Select image of size max 2MB.");
                                }
                              } else {
                                setDisableBtn(false);
                                toast.error("Only jpg/jpeg, png and pdf files are allowed!");
                              }
                            });
                            imagesref.current.value = null;
                            imagesref.current.files = null;
                            await setFile([...imageArray, ...file]);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    {viewSupportRole && (
                      <Button variant="contained" color="error" sx={{ p: "12px" }} type="submit" disabled={disableBtn}>
                        <SendIcon width="25px" height="25px" color={colors.white[900]} />
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </form>
        </Card>
      </Box>
    </>
  );
}
