export default function Taxs(parentTaxs, currentTaxs, allTaxs) {
  let taxs = [];
  allTaxs?.forEach((x) => {
    if (parentTaxs?.length > 0) {
      parentTaxs?.forEach((rx) => {
        if (rx._id === x._id) {
          return taxs.push({ value: x._id, label: `${x.label}(${x.taxPercent}%)`, isFixed: true });
        }
      });
    }
    if (currentTaxs?.length > 0) {
      currentTaxs?.forEach((rx) => {
        if (rx._id === x._id) {
          return taxs.push({ value: x._id, label: `${x.label}(${x.taxPercent}%)`, isFixed: false });
        }
      });
    }
  });
  return taxs;
}
