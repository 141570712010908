import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { NavLink } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export function Header({ title, subtitle, sx }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={sx}>
      <Typography variant="h3" color={colors.grey[100]} textTransform="uppercase" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="h5" color={colors.secondary[900]}>
        {subtitle}
      </Typography>
    </Box>
  );
}

export function InternalPageHeader({ to, title, subtitle, sx, onClick }) {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" gap="4px" sx={sx}>
      {onClick ? (
        <KeyboardArrowLeftIcon fontSize="large" color="primary" className="cursor-pointer" onClick={onClick} />
      ) : (
        <NavLink to={to} className="lh-0">
          <KeyboardArrowLeftIcon fontSize="large" color="primary" />
        </NavLink>
      )}
      <Header title={title} subtitle={subtitle} />
    </Box>
  );
}
