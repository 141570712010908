// user module types
export const DashboardMap = {
  GET_DASHBOARD_DETAILS_FLAG1: "GET_DASHBOARD_DETAILS_FLAG1",
  GET_DASHBOARD_DETAILS_FLAG1_REQUESTED: "GET_DASHBOARD_DETAILS_FLAG1_REQUESTED",
  GET_DASHBOARD_DETAILS_FLAG1_FAILED: "GET_DASHBOARD_DETAILS_FLAG1_FAILED",
  GET_DASHBOARD_DETAILS_FLAG2: "GET_DASHBOARD_DETAILS_FLAG2",
  GET_DASHBOARD_DETAILS_FLAG3: "GET_DASHBOARD_DETAILS_FLAG3",
  GET_DASHBOARD_DETAILS_FLAG5: "GET_DASHBOARD_DETAILS_FLAG5",
  GET_DASHBOARD_DETAILS_FLAG4: "GET_DASHBOARD_DETAILS_FLAG4",
  GET_DASHBOARD_DETAILS_FLAG6: "GET_DASHBOARD_DETAILS_FLAG6",
  GET_DASHBOARD_DETAILS_FLAG7: "GET_DASHBOARD_DETAILS_FLAG7",
};
