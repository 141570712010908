import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { assignUserRole, getUserRole } from "./redux/action";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import GetTreeViewData from "./GetTreeViewData";
import { Button, Card, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import MyCard from "../../components/MyCard";
import { tokens } from "../../theme";
import GlobalLoader from "../../components/GlobalLoader";
import { dependentRoles } from "../../utils/custom";
import { InternalPageHeader } from "../../components/Header";

export default function UserRole() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [checked, setChecked] = useState([""]);
  const [userInfo, setUserInfo] = useState([]);
  const [expanded, setExpanded] = useState(["all"]);
  const [nodes, setNodes] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { isLoading } = useSelector((state) => state.users, shallowEqual);

  useEffect(() => {
    if (id) {
      dispatch(getUserRole(id)).then((data) => {
        if (data.type === "USERS_ROLE_SUCCESS") {
          setChecked(data?.payload?.data.userRoles);
          setUserInfo(data?.payload?.data);
          const treeOfRoles = GetTreeViewData(data?.payload?.data.totalRoles);
          setNodes([treeOfRoles]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const assignRole = () => {
    dispatch(assignUserRole({ userId: id, userRole: [...new Set(checked)] })).then((data) => {
      if (data.type === "USERS_ROLE_ASSIGN_SUCCESS") {
        toast.success(data.payload.message);
        // navigate("/users");
      }
    });
  };

  return (
    <Box mx="20px">
      <Grid container spacing={1} my="20px">
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <InternalPageHeader to="/users" title="Users Role" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ textAlign: "right" }}>
          {isLoading ? null : (
            <Button
              variant="contained"
              onClick={assignRole}
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              Assign Role
            </Button>
          )}
        </Grid>
      </Grid>
      {isLoading ? (
        <Card sx={{ maxHeight: 500, minHeight: 500, display: "flex", alignItems: "center" }}>
          <GlobalLoader />
        </Card>
      ) : (
        <MyCard>
          <Box display="flex" mb={1} p={1}>
            <Typography color={colors.greenAccent[500]} variant="h4" fontWeight="600">
              Username:
            </Typography>
            <Typography color={colors.blueAccent[100]} variant="h4" fontWeight="600" sx={{ pl: 1 }}>
              {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
          </Box>
          <Box sx={{ overflowY: "auto", maxHeight: 500, minHeight: 300 }}>
            <CheckboxTree
              nodes={nodes}
              checked={checked}
              expanded={expanded}
              onCheck={async (c) => {
                var singleChecked = c.filter(function (obj) {
                  return checked.indexOf(obj) === -1;
                });
                let dependentProduct = ["sub_product_list", "product_view", "product_list", "product_edit"];
                let dependentOrder = ["payment_view", "order_view", "order_list"];
                let dependentCustomer = ["customer_list", "customer_view", "customer_edit"];
                let dependentSupport = ["support_list", "support_reply", "support_view"];
                let dependentStore = ["store_list", "store_tax_comm_view", "store_view"];
                let dependentDriverRole = ["driver_list", "driver_view", "driver_edit"];

                if (singleChecked[0] in dependentRoles === true) {
                  if (singleChecked[0] === "product_edit") {
                    await setChecked([...c, "product_list", "product_view"]);
                  } else if (singleChecked[0] === "customer_edit") {
                    let temp1 = [...c, ...dependentCustomer];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "driver_edit") {
                    let temp1 = [...c, ...dependentDriverRole];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "store_tax_comm_edit") {
                    let temp1 = [...c, ...dependentStore];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "store_tax_comm_view") {
                    let temp1 = [...c, ...dependentStore];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "category_product_tree") {
                    let temp1 = [...c, "store_list", "category_product_tree", "store_view"];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "support_reply") {
                    let temp1 = [...c, ...dependentSupport];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "store_edit") {
                    let temp1 = [...c, "store_list", "store_view"];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "statement_generate") {
                    let temp1 = [...c, "statement_all_view"];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "statement_payment") {
                    let temp1 = [...c, "statement_all_view"];
                    setChecked([...temp1]);
                  } else if (singleChecked[0] === "sub_product_list") {
                    await setChecked([...c, "product_list", "product_view"]);
                  } else if (singleChecked[0] === "order_assign") {
                    await setChecked([...c, "order_list", "order_view"]);
                  } else if (singleChecked[0] === "sub_product_edit") {
                  } else if (singleChecked[0] === "Assign_Product_View") {
                    await setChecked([...c, "order_list", "order_view"]);
                  } else if (singleChecked[0] === "sub_product_edit") {
                    await setChecked([...c, "product_list", "product_view", "sub_product_list"]);
                  } else if (singleChecked[0] === "cancel_order") {
                    await setChecked([...c, "order_list", "order_view"]);
                  } else if (singleChecked[0] === "sub_product_edit") {
                    await setChecked([...c, ...dependentProduct]);
                  } else if (singleChecked[0] === "sub_product_add") {
                    await setChecked([...c, ...dependentProduct]);
                  } else if (singleChecked[0] === "sub_product_view") {
                    await setChecked([...c, ...dependentProduct]);
                  } else if (singleChecked[0] === "sub_product_delete") {
                    await setChecked([...c, ...dependentProduct]);
                  } else if (singleChecked[0] === "payment_view") {
                    await setChecked([...c, "order_list", "order_view"]);
                  } else if (singleChecked[0] === "payment_edit") {
                    await setChecked([...c, ...dependentOrder]);
                  } else if (singleChecked[0] === "payment_add") {
                    await setChecked([...c, ...dependentOrder]);
                  } else {
                    await setChecked([...c, dependentRoles[singleChecked[0]]]);
                  }
                } else {
                  setChecked([...c]);
                }
              }}
              onExpand={(expanded) => setExpanded(expanded)}
              showNodeIcon={false}
              icons={{
                check: <CheckBoxOutlinedIcon />,
                uncheck: <CheckBoxOutlineBlankOutlinedIcon />,
                halfCheck: <CheckBoxOutlinedIcon />,
                expandClose: <KeyboardArrowDownIcon />,
                expandOpen: <KeyboardArrowUpIcon />,
                expandAll: <KeyboardArrowUpIcon />
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
            width="100%"
            bgcolor="#fff"
            position="sticky"
            bottom="0"
          >
            <Box>
              <NavLink to="/users">
                <Button variant="outlined">Cancel</Button>
              </NavLink>
            </Box>
            <Box>
              {isLoading ? null : (
                <Button
                  variant="contained"
                  onClick={assignRole}
                  sx={[
                    {
                      "&:hover": {
                        bgcolor: colors.themeSecondaryBlueColor[100],
                        color: colors.white[100]
                      }
                    },
                    { bgcolor: colors.themeDarkGrey[100] }
                  ]}
                >
                  Assign Role
                </Button>
              )}
            </Box>
          </Box>
        </MyCard>
      )}
    </Box>
  );
}
