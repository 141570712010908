import { BrandsMap } from "./type";

// category intial state
const initialProducts = {
  brands: null,
  isLoading: false,
  error: null,
  success: false,
  total: null
};

export const reducer = (state = initialProducts, action) => {
  switch (action.type) {
    case BrandsMap.BRAND_REQUEST:
      return { ...state, isLoading: true };
    case BrandsMap.BRAND_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        brands: action.payload.data.records,
        total: action.payload.data.totalRecords
      };
    }
    case BrandsMap.BRAND_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case BrandsMap.BRAND_ADD_REQUEST:
      return { ...state, isLoading: true };
    case BrandsMap.BRAND_ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case BrandsMap.BRAND_ADD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case BrandsMap.BRAND_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case BrandsMap.BRAND_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case BrandsMap.BRAND_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case BrandsMap.BRAND_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case BrandsMap.BRAND_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case BrandsMap.BRAND_DELETE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
