import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { StoreDashboardMap, StoreMap } from "./type";

//fetch store
export const fetchStores = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.STORES_REQUEST });
    let apiEndpoint = `${API_END_POINTS.stores}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.STORES_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.STORES_FAIL, error: message });
  }
};

//Add store
export const createStore = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.STORE_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addStore}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.STORE_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.STORE_ADD_FAIL, error: message });
  }
};

//Edit store
export const updateStore = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.STORE_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editStore}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.STORE_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.STORE_UPDATE_FAIL, error: message });
  }
};

//Delete store
export const deleteStore = (id) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.STORE_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteStore}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: StoreMap.STORE_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    return dispatch({ type: StoreMap.STORE_DELETE_FAIL, error: message });
  }
};

//Edit store
export const getSingleStore = (id) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.GET_SINGLE_STORE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getSingleStore}`;
    const { data } = await axiosForMultipart.get(`${apiEndpoint}/${id}`);
    return dispatch({
      type: StoreMap.GET_SINGLE_STORE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.GET_SINGLE_STORE_FAIL, error: message });
  }
};

//Edit store
export const getStoreProductsData = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.GET_STORE_PRODCUTS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getStoreProductsEndpoint}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.GET_STORE_PRODCUTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.GET_STORE_PRODCUTS_FAIL, error: message });
  }
};

//addStoreProduct
export const addStoreProduct = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.ADD_STORE_PRODCUTS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addStoreProduct}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.ADD_STORE_PRODCUTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.ADD_STORE_PRODCUTS_FAIL, error: message });
  }
};

//updateCategoryTax
export const updateCategoryTax = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.UPDATE_CATEGORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.taxCategoryUpdateEndpoint}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: StoreMap.UPDATE_CATEGORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.UPDATE_CATEGORY_FAIL, error: message });
  }
};

export const getStoresDropdown = (body) => async (dispatch) => {
  try {
    dispatch({ type: StoreMap.GET_STORES_DROPDOWN_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getStoresDropdown}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);

    return dispatch({
      type: StoreMap.GET_STORES_DROPDOWN_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : error?.message;
    toast.error(message);
    dispatch({ type: StoreMap.GET_STORES_DROPDOWN_FAIL, error: message });
  }
};

export const GetLocationReport = (body) => async (dispatch) => {
  dispatch({
    type: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1_REQUESTED
  });
  let actions = {
    1: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1,
    2: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG2,
    3: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG3,
    4: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG4
  };
  try {
    let apiEndpoint = `${API_END_POINTS.getLocationReport}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: actions[body.flag],
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;

    toast.error(message);
    return dispatch({
      type: StoreDashboardMap.GET_DASHBOARD_DETAILS_FLAG1_FAILED,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    });
  }
};
