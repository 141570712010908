export default function ExitingValueFilter(array, id) {
  if (id && id !== "") {
    const filterArray = (array) => {
      return array.map((ele) => {
        return {
          ...ele,
          subcategories:
            ele?._id !== id && ele.subcategories && ele.subcategories.length > 0 ? filterArray(ele.subcategories) : null
        };
      });
    };
    return filterArray(array);
  }
}
