import { useTheme } from "@emotion/react";
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

export default function ComponentLoader() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      width="100%"
      textAlign="center"
      backgroundColor={colors.themeGray[100]}
      borderRadius={1}
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      p={2}
      lineHeight={0}
    >
      <CircularProgress />
    </Box>
  );
}
