// tax module types
export const GET_DRIVERS = "GET_DRIVERS";
export const FETCH_DRIVER = "FETCH_DRIVER";
export const ADD_DRIVER = "ADD_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";

export const DRIVER_REQUEST = 'DRIVER_REQUEST';
export const DRIVER_SUCCESS = 'DRIVER_SUCCESS';
export const DRIVER_FAIL = 'DRIVER_FAIL';
export const DRIVER_RESET = 'DRIVER_RESET';

export const DRIVER_ADD_REQUEST = 'DRIVER_ADD_REQUEST';
export const DRIVER_ADD_SUCCESS = 'DRIVER_ADD_SUCCESS';
export const DRIVER_ADD_FAIL = 'DRIVER_ADD_FAIL';
export const DRIVER_ADD_RESET = 'DRIVER_ADD_RESET';

export const DRIVER_UPDATE_REQUEST = 'DRIVER_UPDATE_REQUEST';
export const DRIVER_UPDATE_SUCCESS = 'DRIVER_UPDATE_SUCCESS';
export const DRIVER_UPDATE_FAIL = 'DRIVER_UPDATE_FAIL';
export const DRIVER_UPDATE_RESET = 'DRIVER_UPDATE_RESET';

export const DRIVERS_REQUEST = 'DRIVERS_REQUEST';
export const DRIVERS_SUCCESS = 'DRIVERS_SUCCESS';
export const DRIVERS_FAIL = 'DRIVERS_FAIL';
export const DRIVERS_RESET = 'DRIVERS_RESET';

export const DRIVER_DELETE_REQUEST = 'DRIVER_DELETE_REQUEST';
export const DRIVER_DELETE_SUCCESS = 'DRIVER_DELETE_SUCCESS';
export const DRIVER_DELETE_FAIL = 'DRIVER_DELETE_FAIL';
export const DRIVER_DELETE_RESET = 'DRIVER_DELETE_RESET';
