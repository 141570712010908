import { useTheme } from "@emotion/react";
import {
  Button,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ErrorMSG from "../../components/ErrorMSG";
import { tokens } from "../../theme";
import { passwordRegex } from "../../utils/regex";
import { resetPassword } from "../login/redux/action";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { token } = useParams();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // main state to store the payload
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      const resetData = {
        password: data.password,
        token: token
      };
      await dispatch(resetPassword(resetData)).then(async (data) => {
        if (data && data?.payload?.status) {
          toast.success(data.payload.message);
          navigate("/login");
          reset({ password: "", confirmPassword: "" });
        }
      });
    }
  };

  return (
    <>
      <div className="loginMain">
        <div className="logo"></div>

        <div className="container">
          <div className="card">
            <h1 className="title">Reset Password</h1>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Box m="10px">
                <Grid container spacing={gridSpacing}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        type={showPassword1 ? "text" : "password"}
                        size="small"
                        label={
                          <p className="my-0">
                            New Password <span className="danger mr-0">*</span>
                          </p>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword1}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                        {...register("password", { required: true, pattern: passwordRegex })}
                      />
                      {errors.password && errors.password.type === "required" && (
                        <ErrorMSG text="New Password is required" />
                      )}
                      {errors.password && errors.password.type === "pattern" && (
                        <ErrorMSG text="Password at least 1 uppercase, 1 lowercase, 1 special character, 1 number, Min 8, Max 30" />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        type={showPassword2 ? "text" : "password"}
                        size="small"
                        label={
                          <p className="my-0">
                            Confirm New Password <span className="danger mr-0">*</span>
                          </p>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword2 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                        {...register("confirmPassword", {
                          required: true,
                          validate: (val) => control._formValues.password === val
                        })}
                      />
                      {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                        <ErrorMSG text="Confirm New Password is required" />
                      )}
                      {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                        <ErrorMSG text="Your Confirm password does not match" />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  sx={[
                    {
                      "&:hover": {
                        bgcolor: colors.themeSecondaryBlueColor[100],
                        color: colors.white[100]
                      }
                    },
                    { bgcolor: colors.themeDarkGrey[100] }
                  ]}
                >
                  {/* {isLoading ? "Saving..." : "Save"} */}
                  Save
                </Button>
              </DialogActions>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
