import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  Divider,
  Grid,
  List,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";
import React from "react";
import { tokens } from "../theme";

const DashboardLoader = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  return (
    <Box my={2}>
      <Grid container spacing={gridSpacing}>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Card className="customCard" sx={{ p: 0 }}>
            <Box sx={{ p: 2, textAlign: "center", background: colors.themeGray[200] }}>
              <Skeleton variant="rectangular" width={"100%"} height={200} />
            </Box>
            <Divider />
            <Box sx={{ px: 2, pb: 2 }}>
              <List sx={{ pb: 0 }}>
                <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                <Divider sx={{ borderStyle: "dashed" }} />
                <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                <Divider sx={{ borderStyle: "dashed" }} />
                <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                <Divider sx={{ borderStyle: "dashed" }} />
                <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                <Divider sx={{ borderStyle: "dashed" }} />
                <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Skeleton variant="text" width={"100%"} height={72} />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                        <Skeleton variant="rectangular" width={72} height={72} />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Skeleton variant="text" width={"100%"} height={72} />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                        <Skeleton variant="rectangular" width={72} height={72} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ p: 1, background: "#E7F3F3", borderRadius: "4px" }}>
                  <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                  <Divider sx={{ mt: 0.5 }} />
                  <List sx={{ pb: 0 }}>
                    <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                  </List>
                </Box>
                <Skeleton variant="text" sx={{ fontSize: "50px" }} />
                <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Skeleton variant="text" width={"100%"} height={72} />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                      <Skeleton variant="rectangular" width={72} height={72} />
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Skeleton variant="text" width={"100%"} height={72} />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                      <Skeleton variant="rectangular" width={72} height={72} />
                    </Box>
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Card>
        </Grid>
        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="customCard">
                <Box
                  sx={{
                    pb: 1,
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "8px",
                    flexWrap: "wrap"
                  }}
                >
                  <Skeleton variant="text" width={150} height={50} />
                  <Skeleton variant="rectangular" width={150} height={50} />
                </Box>
                <TableContainer sx={{ maxHeight: "500px" }}>
                  <Table stickyHeader aria-label="sticky table" className="customTable">
                    <TableHead>
                      <TableRow>
                        {Array.from(Array(4).keys())?.map((x) => (
                          <TableCell
                            key={x}
                            sx={{ color: colors.white[900], p: 2, minWidth: x === 0 ? "none" : "110px" }}
                          >
                            <Skeleton variant="rectangular" width={"100%"} height={30} />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from(Array(5).keys())?.map((y) => {
                        return (
                          <TableRow key={y}>
                            {Array.from(Array(4).keys())?.map((x) => {
                              return (
                                <TableCell key={x}>
                                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardLoader;
