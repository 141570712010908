import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, Grid, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { addTax, fetchDropdownTaxs, fetchTaxs, updateTax } from "../tax/redux/action";
import { useForm, Controller } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { toast } from "react-toastify";
import { tokens } from "../../theme";
import MyDialog from "../../components/MyDialog";
import { inputScroll } from "../../utils/custom";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";

export default function TaxAdd({ open, handleClose, reqData, rowData }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;

  //   // main state to get the properties
  const { common } = useSelector(
    (state) => ({
      common: state && state.taxs && state.taxs ? state.taxs : []
    }),
    shallowEqual
  );
  const { isLoading } = common;

  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      label: "",
      status: "true",
      taxPercent: ""
    }
  });

  React.useEffect(() => {
    if (!rowData?._id && !open) {
      reset({
        label: "",
        status: "true",
        taxPercent: ""
      });
    }
    // eslint-disable-next-line no-use-before-define
  }, [open, reset, rowData?._id]);

  React.useEffect(() => {
    if (rowData?._id) {
      reset({
        label: rowData?.label,
        // status: rowData?.status,
        taxPercent: rowData?.taxPercent
      });
    } else {
      reset({
        label: "",
        status: "true",
        taxPercent: ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  React.useEffect(() => {}, [rowData?._id]);

  const onSubmit = async (data) => {
    const newReqData = await {
      label: data?.label,
      status: data?.status,
      taxPercent: data?.taxPercent
    };
    if (Object.keys(errors).length === 0) {
      if (rowData?._id) {
        await dispatch(updateTax(newReqData, rowData?._id)).then(async (data) => {
          if (data && data?.payload?.status) {
            toast.success(data.payload.message);
            reset({ label: "", status: "true", taxPercent: "" });
            handleClose();
            // navigate("/tax");
            // window.location.reload(true);
            dispatch(fetchTaxs(reqData));
          }
        });
      } else {
        await dispatch(addTax(newReqData)).then(async (data) => {
          if (data && data?.payload?.status) {
            toast.success(data.payload.message);
            reset({ label: "", status: "true", taxPercent: "" });
            handleClose();
            dispatch(fetchTaxs(reqData));
            dispatch(fetchDropdownTaxs());
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="sm"
        open={open}
        handleClose={() => {
          handleClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header"> {rowData?._id ? "Edit Tax" : "Create New Tax"}</DialogTitle>
          <DialogContent>
            <RequiredFieldLabel />
            <Box>
              <Grid container spacing={gridSpacing}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="text"
                      size="small"
                      label={
                        <p className="my-0">
                          Tax Name <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register("label", {
                            required: "Tax Name is required",
                            maxLength: { value: 20, message: "Tax name should not be greater than 20 characters" }, 
                            pattern : {value: /^[^\s]+(?:$|.*[^\s]+$)/, message: "Tax Name not allowed to be empty"}
                        })}
                    />
                    {errors.label && (
                      <ErrorMSG text={errors.label.message} />
                      )}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      onWheel={inputScroll}
                      label={
                        <p className="my-0">
                          Tax Percentage <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        step: 0.01,
                        min: 0,
                        max: 100
                      }}
                      {...register("taxPercent", {
                        required: "Tax Percentage is required",
                        validate: {
                          max: (value) => parseFloat(value) <= 100 || "Percentage should not be greater than 100%"
                        }
                      })}
                    />
                    {errors.taxPercent && (
                      <ErrorMSG text={errors.taxPercent.message} />
                    )}
                </FormControl>
                </Grid>
                {!rowData?._id ? (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={value}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                            >
                              <FormControlLabel value="true" control={<Radio />} label="Active" />
                              <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                            </RadioGroup>
                          )}
                          control={control}
                          name="status"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={[
                {
                  "&:hover": {
                    bgcolor: colors.themeSecondaryBlueColor[100],
                    color: colors.white[100]
                  }
                },
                { bgcolor: colors.themeDarkGrey[100] }
              ]}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </React.Fragment>
  );
}
