import { Box, Typography } from "@mui/material";
import React from "react";

export default function RequiredFieldLabel({ className, sx, mt, mb }) {
  return (
    <Box className={className} sx={sx} mt={mt ? mt : 0} mb={mb ? mb : 2}>
      <Typography variant="span" color="error" fontSize="14px">
        * Required Fields
      </Typography>
    </Box>
  );
}
