import { Box, FormControlLabel, Switch, LinearProgress, Grid } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { Header } from "../../components/Header";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import moment from "moment";
import { toast } from "react-toastify";
import SearchBar from "./SearchBar";
import TableWrapper from "../../components/TableWrapper";
import { updateStatus } from "../category/redux/action";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import MyStoreGridToolbar from "./filter/MyStoreGridToolbar";
import { fetchStores } from "./redux/action";
import { NavLink, useNavigate } from "react-router-dom";
import GetUserRole from "../../utils/GetUserRole";
import { formatPhoneNumber } from "../../utils/common";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import { CommanFormatDate } from "../../utils/custom";

const Store = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let roleVendorEdit = GetUserRole("store_edit");
  let locationViewRole = GetUserRole("store_view");
  const apiRef = useGridApiRef();
  const restorePaginationState = () => {
    const state = apiRef.current.exportState();
    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
          pageSize: 25
        }
      }
    };
    apiRef.current.restoreState(restoredState);
  };
  // main state to store the payload
  const [reqData, setReqData] = useState({
    key: "_id",
    desc: true,
    limit: 25,
    page: 1,
    search: "",
    fromDate: "",
    toDate: "",
    dateRange: "",
    status: ""
  });
  const { stores, total, isLoading } = useSelector((state) => state.store, shallowEqual);

  useEffect(() => {
    document.title = "Locations | Doorvenza";
  }, []);

  useEffect(() => {
    dispatch(fetchStores(reqData));
  }, [dispatch, reqData]);

  /*
   <<<<================== DELETE VENDOR ==================>>>>
   const [openDelete, setDeleteOpen] = useState(true);
  const [id, setId] = useState("");
   const handleDeleteClose = () => {
     setDeleteOpen(false);
     setId("");
    };
  */

  // columns data to the table
  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 60,
      sortable: false,
      renderCell: ({ row, i }) => <p>{row.id}</p>
    },
    {
      field: "storeName",
      headerName: "Location Name",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row, i }) => (
        <NavLink
          to={`${locationViewRole ? `/locations/view/${row._id}` : ""} `}
          style={{
            color: colors.secondary[900],
            textDecoration: "none",
            fontWeight: "600",
            cursor: locationViewRole ? "pointer" : "none"
          }}
        >
          {row.storeName}
        </NavLink>
      ),
      valueGetter: ({ row }) => row.storeName
    },
    {
      field: "fullName",
      headerName: "Full Name",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row, i }) => <span>{row.fullName}</span>,
      valueGetter: ({ row }) => row.fullName
    },
    {
      field: "phone",
      headerName: "Location Phone",
      width: 150,
      renderCell: ({ row }) => <span>{row.phone ? formatPhoneNumber(`+1${row.phone}`) : "N/A"}</span>,
      valueGetter: ({ row }) => row.phone
    },

    {
      field: "email",
      headerName: "Location Email",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }) => <span>{row.email ? row.email : "N/A"}</span>,
      valueGetter: ({ row }) => row.email
    },
    {
      field: "ownerPhone",
      headerName: "Owner Phone",
      width: 150,
      renderCell: ({ row }) => <span>{row.ownerPhone ? formatPhoneNumber(`+1${row.ownerPhone}`) : "N/A"}</span>,
      valueGetter: ({ row }) => row.ownerPhone
    },
    {
      field: "ownerEmail",
      headerName: "Owner Email",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }) => <span>{row.ownerEmail ? row.ownerEmail : "N/A"}</span>,
      valueGetter: ({ row }) => row.ownerEmail
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      type: "text",

      headerAlign: "left",
      align: "left",
      sortable: true,
      renderCell: ({ row }) => (
        <>
          <FormControlLabel
            control={
              <Switch
                disabled={!roleVendorEdit}
                checked={row.status}
                color="success"
                onChange={(e) => {
                  dispatch(
                    updateStatus({
                      key: "user",
                      id: row?._id,
                      status: row?.status ? false : true
                    })
                  ).then(async (data) => {
                    if (data && data?.payload?.status) {
                      toast.success(data.payload.message);
                      dispatch(fetchStores(reqData));
                    } else {
                      toast.error(data.error);
                    }
                  });
                }}
              />
            }
          />
        </>
      ),
      valueFormatter: (params) => (params.value ? "Active" : "Inactive")
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 100,
      // flex: 1,
      renderCell: ({ row }) => <span>{moment(row.createdAt).format(CommanFormatDate)}</span>,
      valueFormatter: (params) => moment(params.value).format(CommanFormatDate).toString()
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      width: 80,
      // flex: 1,
      sortable: false,
      getActions: ({ row }) => [
        <GridActionsCellItem
          disabled={GetUserRole("store_edit") ? false : true}
          icon={<CreateSharpIcon size="small" hidetext="true" recorditemid={row._id} />}
          label="Edit"
          onClick={() => {
            navigate(`/locations/edit/${row._id}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          disabled={GetUserRole("category_product_tree") ? false : true}
          icon={<InventoryIcon size="small" hidetext="true" recorditemid={row._id} />}
          label="Assign Products"
          onClick={() => {
            navigate(`/locations/tree/${row._id}`);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          disabled={GetUserRole("store_tax_comm_view") ? false : true}
          icon={<AttachMoneyIcon size="small" hidetext="true" recorditemid={row._id} />}
          label="Assign Tax"
          onClick={() => {
            navigate(`/locations/locationTax/${row._id}`);
          }}
          showInMenu
        />
      ]
    }
  ];

  return (
    <Box mx="20px">
      <Grid container spacing={1}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Header title="LOCATION LIST" />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <SearchBar reqData={reqData} setReqData={setReqData} />
        </Grid>
      </Grid>

      {/*
      <<<<================== DELETE VENDOR ==================>>>>
      <DeleteDialog openDelete={openDelete} handleClose={handleDeleteClose} id={id} reqData={reqData} /> 
      */}
      <TableWrapper>
        <DataGrid
          apiRef={apiRef}
          disableColumnFilter
          rows={
            stores !== null && stores.length
              ? stores?.map((vendor, i) => ({
                  id: reqData.limit * reqData.page - reqData.limit + (i + 1),
                  ...vendor
                }))
              : []
          }
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: MyStoreGridToolbar
          }}
          sx={{
            "@media print": {
              ".MuiDataGrid-main": {
                width: "fit-content",
                fontSize: "14px",
                overflow: "visible",
                border: "none !important"
              },
              border: "none !important",
              ".MuiDataGrid-main .MuiDataGrid-columnHeader": {
                color: "#000 !important"
              },
              ".MuiDataGrid-main .MuiDataGrid-cell[data-field='companyName'], .MuiDataGrid-main .MuiDataGrid-cell[data-field='firstName'], .MuiDataGrid-main .MuiDataGrid-cell[data-field='email']":
                {
                  minWidth: "150px !important",
                  width: "150px !important"
                },
              ".MuiDataGrid-main .MuiDataGrid-columnHeader[data-field='companyName'], .MuiDataGrid-main .MuiDataGrid-columnHeader[data-field='firstName'], .MuiDataGrid-main .MuiDataGrid-columnHeader[data-field='email']":
                {
                  minWidth: "150px !important",
                  width: "150px !important"
                },
              ".MuiDataGrid-main .MuiDataGrid-cell": {
                whiteSpace: "normal !important",
                wordBreak: "break-all",
                maxHeight: "unset !important"
              },
              ".MuiDataGrid-main .MuiDataGrid-row": {
                maxHeight: "unset !important"
              },
              ".MuiDataGrid-main .MuiDataGrid-virtualScroller": {
                height: "auto !important",
                overflow: "unset !important"
              }
            }
          }}
          slotProps={{
            toolbar: {
              printOptions: { hideFooter: true, hideToolbar: true },
              reqData,
              setReqData,
              restorePaginationState
            }
          }}
          loading={isLoading}
          selectable="false"
          rowCount={total || 0}
          disableRowSelectionOnClick={true}
          paginationMode="server"
          sortingMode="server"
          pagination
          onPaginationModelChange={(e) => {
            setReqData(
              Object.assign({}, reqData, {
                page: e.page + 1,
                limit: e.pageSize
              })
            );
          }}
          rowsPerPage={reqData.limit}
          onSortModelChange={(newSortModel) => {
            if (newSortModel.length > 0) {
              setReqData(
                Object.assign({}, reqData, {
                  key: newSortModel[0]?.field,
                  desc: newSortModel[0]?.sort === "asc" ? false : true
                })
              );
            } else {
              setReqData(
                Object.assign({}, reqData, {
                  key: "_id",
                  desc: true
                })
              );
            }
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } }
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </TableWrapper>
    </Box>
  );
};

export default Store;
