import { ClickAwayListener, IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export default function Search({ reqData, setReqData, placeholder, sx, isMark }) {
  const [searchText, setSearchText] = useState(reqData.search || "");
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

  //Can be shorten to
  const windowFindShort = (value) => {
    const t = document.getElementById("content");
    if (window.find(value, !1)) {
      const e = window.getSelection().getRangeAt(0).getBoundingClientRect();
      t.scrollTo({
        top: e.top - t.offsetTop + t.scrollTop
      });
    }
  };

  return (
    <Paper
      component="form"
      className="searchBar"
      sx={{
        py: "8px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        // width: 400,
        border: 1,
        borderColor: "#04050980",
        boxShadow: 0,
        ...sx
      }}
    >
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          arrow
          placement="top"
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Press enter to search"
        >
          <InputBase
            sx={{ ml: 1, flex: 1, p: 0 }}
            className="search_input"
            size="small"
            autoComplete="off"
            id="findInput"
            placeholder={placeholder ? placeholder : "Search Here..."}
            label="Outlined"
            variant="outlined"
            onChange={(e) => {
              setSearchText(e.target.value.trim());
              if (e.target.value.length > 0) {
                handleTooltipOpen();
              } else {
                setReqData({ ...reqData, search: e.target.value });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (isMark) {
                  windowFindShort(e.target.value);
                }
                e.target.value.trim() === ' '? setSearchText(e.target.value) : setReqData({ ...reqData, search: e.target.value });
              }
            }}
            name="label"
            value={searchText}
            inputProps={{ "aria-label": "search inputs" }}
          />
        </Tooltip>
      </ClickAwayListener>
      {searchText.length > 0 && (
        <IconButton
          className="searchClearBtn"
          type="button"
          onClick={() => {
            setSearchText("");
            setReqData({ ...reqData, search: "" });
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <IconButton
        className="searchBtn"
        type="button"
        aria-label="search"
        onClick={() => {
          if (isMark) {
            windowFindShort(searchText);
          }
          setReqData({ ...reqData, search: searchText });
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
