import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  useTheme
} from "@mui/material";
import { tokens } from "../../theme";
import Select from "react-select";
import { styles } from "../../utils/custom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ViewOrder, assignLocations, fetchOrders, getOrderLocationsData } from "./redux/action";
import MyDialog from "../../components/MyDialog";

export default function StoreConfirmDialog({ onClose, open, reqData, orderId, id }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [payloadData, setPayloadData] = useState("");
  const { orderLocations } = useSelector((state) => state.orders, shallowEqual);

  const dispatch = useDispatch();
  // get orders endpoint
  useEffect(() => {
    dispatch(getOrderLocationsData({ orderId: orderId }));
  }, [dispatch, orderId]);

  // store options
  const storeOptions = [
    ...orderLocations.map((x) => ({
      value: x.storeId,
      label: x.storeName,
      distance: (x.distance / 1000).toFixed(2),
      name: "storeName"
    }))
  ];
  // handle change
  const handleFilterChange = (e, name) => {
    setPayloadData(e.value);
  };

  const formatOptionLabel = (storeOptions) => (
    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <Box>{storeOptions?.label}</Box>
      <Box
        sx={{
          p: "2px 8px",
          background: colors.themeGray[600],
          fontWeight: "600",
          color: colors.white[100],
          fontSize: "12px",
          borderRadius: "50px"
        }}
      >
        {storeOptions?.distance} KM
      </Box>
    </Box>
  );

  return (
    <MyDialog maxWidth="sm" disableEscapeKeyDown={true} open={open} handleClose={onClose}>
      <DialogTitle variant="h5" sx={{ alignItems: "center", display: "flex", p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textTransform="capitalize"
          color={colors.secondary[900]}
        >
          Assign Location
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
            <FormControl fullWidth>
              <label className="react-select-labels z-index-10">
                Locations <span className="danger mr-0">*</span>
              </label>
              <Select
                styles={styles}
                theme={(x) => ({ ...x, borderRadius: 4 })}
                className="react-select-no-outline reactSelectFocus z-index-9"
                classNamePrefix="react-select"
                isClearable={true}
                isSearchable={true}
                value={storeOptions.find((x) => x.value === payloadData)}
                placeholder="Select Location"
                onChange={(e, option) => {
                  let temp = {
                    value: ""
                  };
                  if (option.action === "clear") {
                    handleFilterChange(temp);
                  } else {
                    handleFilterChange(e);
                  }
                }}
                options={storeOptions}
                formatOptionLabel={formatOptionLabel}
                name="storeId"
              />
            </FormControl>
          </Grid>
        </DialogContentText>
      </DialogContent>
      {/* )} */}
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={!payloadData}
          onClick={() => {
            dispatch(assignLocations({ orderId: orderId, storeId: payloadData })).then((data) => {
              if (data && data?.payload?.status) {
                toast.success(data.payload.message);
                reqData !== "" ? dispatch(fetchOrders(reqData)) : dispatch(ViewOrder(id));
                onClose();
              }
            });
          }}
          sx={[
            {
              "&:hover": {
                bgcolor: colors.themeSecondaryBlueColor[100],
                color: colors.white[100]
              }
            },
            { bgcolor: colors.themeDarkGrey[100] }
          ]}
        >
          Assign Order
        </Button>
      </DialogActions>
    </MyDialog>
  );
}
