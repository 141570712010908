import React, { useState } from "react";
import { Box, Button } from "@mui/material";
// import GetUserRole from "../../utils/GetUserRole";
import { generateStatement, getStatements } from "./redux/action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import GetUserRole from "../../utils/GetUserRole";
import Search from "../../components/Search";
export default function SearchBar({ LOCATION, setCurrentStatementId, reqData, setReqData }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <Box display="flex" justifyContent="end" flexWrap="wrap" gap="4px">
      {LOCATION && <Search reqData={reqData} setReqData={setReqData} />}
      {GetUserRole("statement_generate") && (
        <Button
          disabled={loading}
          variant="outlined"
          onClick={() => {
            setLoading(true);
            dispatch(generateStatement())
              .then(async (data) => {
                if (data && data?.payload?.status) {
                  toast.success(data.payload.message);
                  setCurrentStatementId("");
                  dispatch(getStatements());
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      )}
    </Box>
  );
}
