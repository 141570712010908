import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import {
  SINGLE_SUB_PRODUCT_REQUEST,
  SINGLE_SUB_PRODUCT_FAIL,
  SINGLE_SUB_PRODUCT_SUCESSS,
  SINGLE_PRODUCT_ADD_REQUEST,
  SINGLE_PRODUCT_ADD_SUCCESS,
  SINGLE_PRODUCT_ADD_FAIL,
  SINGLE_PRODUCT_UPDATE_REQUEST,
  SINGLE_PRODUCT_UPDATE_SUCCESS,
  SINGLE_PRODUCT_UPDATE_FAIL,
  SINGLE_PRODUCT_DELETE_REQUEST,
  SINGLE_PRODUCT_DELETE_SUCCESS,
  SINGLE_PRODUCT_DELETE_FAIL,
  SINGLEB_SUB_PRODUCT_REQUEST,
  SINGLEB_SUB_PRODUCT_SUCESSS,
  SINGLEB_SUB_PRODUCT_FAIL,
  GET_BRAND_DROPDOWN_REQUEST,
  GET_BRAND_DROPDOWN_SUCESSS,
  GET_BRAND_DROPDOWN_FAIL
} from "../../subproduct/redux/type";
// import useEncryption from "../../components/Common/useEncryption";

//fetch single products
export const fetchSubProduct = (subId, body) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_SUB_PRODUCT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.singleProduct}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, { ...body, id: subId, limit: 1000 });
    // var res = useEncryption(response.data);
    return dispatch({
      type: SINGLE_SUB_PRODUCT_SUCESSS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: SINGLE_SUB_PRODUCT_FAIL, error: message });
  }
};

// add single sub-product
export const addSubProduct = (body) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_PRODUCT_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addSingleProduct}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: SINGLE_PRODUCT_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: SINGLE_PRODUCT_ADD_FAIL, error: message });
  }
};

// update single sub-product
export const updateSubProduct = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_PRODUCT_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editSingleProduct}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: SINGLE_PRODUCT_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: SINGLE_PRODUCT_UPDATE_FAIL, error: message });
  }
};

//get single product
export const getSinglesSubProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: SINGLEB_SUB_PRODUCT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getSingleProduct}`;
    const { data } = await axiosForMultipart.get(`${apiEndpoint}/${id}`);
    // var res = useEncryption(response.data);
    return dispatch({
      type: SINGLEB_SUB_PRODUCT_SUCESSS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: SINGLEB_SUB_PRODUCT_FAIL, error: message });
  }
};

//delete single sub-product
export const deleteSubProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_PRODUCT_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteSingleProduct}`;
    // eslint-disable-next-line no-unused-vars
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: SINGLE_PRODUCT_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: SINGLE_PRODUCT_DELETE_FAIL, error: message });
  }
};

//fetch single products
export const fetchBrandsData = (subId, body) => async (dispatch) => {
  try {
    dispatch({ type: GET_BRAND_DROPDOWN_REQUEST });
    let apiEndpoint = `${API_END_POINTS.brandsData}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`);
    // var res = useEncryption(response.data);
    return dispatch({
      type: GET_BRAND_DROPDOWN_SUCESSS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: GET_BRAND_DROPDOWN_FAIL, error: message });
  }
};
