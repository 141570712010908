// import { SUPPORT_FAIL, SUPPORT_REQUEST, SUPPORT_SUCCESS } from "./type";

// // category intial state
// const initialSupports = {
//   supports: [],
//   isLoading: true,
//   isEditLoading: true,
//   error: null,
//   success: false,
//   total: null
// };

// export const reducer = (state = initialSupports, action) => {
//   switch (action.type) {
//     case SUPPORT_REQUEST:
//       return { ...state, isLoading: true };
//     case SUPPORT_SUCCESS: {
//       return {
//         ...state,
//         supports: action.payload.data.records,
//         isLoading: false,
//         success: true,
//         total: action.payload.data.totalRecords
//       };
//     }
//     case SUPPORT_FAIL:
//       return { ...state, isLoading: false, error: action.error };

//     // case TAX_ADD_REQUEST:
//     //   return { ...state, isLoading: true };
//     // case TAX_ADD_SUCCESS:
//     //   return { ...state, isLoading: false, success: true };
//     // case TAX_ADD_FAIL: {
//     //   return { ...state, isLoading: false, error: action.error };
//     // }

//     // case TAX_UPDATE_REQUEST:
//     //   return { ...state, isLoading: true };
//     // case TAX_UPDATE_SUCCESS:
//     //   return { ...state, isLoading: false, success: true };
//     // case TAX_UPDATE_FAIL: {
//     //   return { ...state, isLoading: false, error: action.error };
//     // }

//     // case TAX_DELETE_REQUEST:
//     //   return { ...state, isLoading: true };
//     // case TAX_DELETE_SUCCESS:
//     //   return { ...state, isLoading: false, success: true };
//     // case TAX_DELETE_FAIL: {
//     //   return { ...state, isLoading: false, error: action.error };
//     // }

//     default:
//       return state;
//   }
// };

import { SupportType } from "./type";

// Organizers intial state
const initialAuthState = {
  isLoading: true,
  error: null,
  supports: null,
  support: null,
  totalRecords: 0,
  isSupportLoading: true,
  supportDashboard: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SupportType.GET_SUPPORT_LIST_REQUEST:
      return { ...state, isLoading: true };
    case SupportType.GET_SUPPORT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supports: action.payload.data.records,
        totalRecords: action.payload.data.totalRecords
      };
    case SupportType.GET_SUPPORT_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case SupportType.GET_SINGLE_SUPPORT_REQUEST:
      return { ...state, isLoading: true };
    case SupportType.GET_SINGLE_SUPPORT_SUCCESS:
      return { ...state, isLoading: false, support: action.payload.data };
    case SupportType.GET_SINGLE_SUPPORT_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case SupportType.GET_DASHBOARD_SUPPORT_REQUEST:
      return { ...state, isSupportLoading: true };
    case SupportType.GET_DASHBOARD_SUPPORT_SUCCESS:
      return { ...state, isSupportLoading: false, supportDashboard: action.payload.data };
    case SupportType.GET_DASHBOARD_SUPPORT_FAIL:
      return { ...state, isSupportLoading: false, error: action.error };
    default:
      return state;
  }
};
