import { Box } from "@mui/material";
import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageViews from "../../components/ImageViewer";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function SupportChat({ files }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <>
      <ImageViews
        files={files}
        type="textBoxView"
        setCurrentImage={setCurrentImage}
        setIsViewerOpen={setIsViewerOpen}
        currentImage={currentImage}
        isViewerOpen={isViewerOpen}
      />
      <Box
        sx={{
          my: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap"
        }}
      >
        {files.map((file, index) => {
          return (
            <Box key={index}>
              {file.contentType === "application/pdf" ? (
                <>
                  <Box
                    key={index}
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      border: `1px solid ${colors.themeGray[300]}`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <a
                      href={baseUrl + file?.fileUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        maxWidth: "30px",
                        maxHeight: "30px",
                        color: colors.themeGray[600],
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <PictureAsPdfIcon width="30px" height="30px" />
                    </a>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      width: "50px",
                      height: "50px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      color: colors.themeGray[600],
                      border: `1px solid ${colors.themeGray[300]}`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    onClick={() => {
                      setIsViewerOpen(true);
                      setCurrentImage(index);
                    }}
                  >
                    <img
                      src={`${baseUrl}/${file.fileUrl}`}
                      alt="Contract Document"
                      style={{
                        width: "30px",
                        height: "30px"
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
