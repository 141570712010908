import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import {
  GET_SPECIFICATIONS_BY_CATEGORY_FAIL,
  GET_SPECIFICATIONS_BY_CATEGORY_REQUEST,
  GET_SPECIFICATIONS_BY_CATEGORY_SUCCESS,
  PRODUCTS_FAIL,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCESSS,
  PRODUCT_ADD_FAIL,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT__DELETE_FROM_API_FAIL,
  PRODUCT__DELETE_FROM_API_REQUEST,
  PRODUCT__DELETE_FROM_API_SUCCESS,
  SINGLE_PRODUCT_FAIL,
  SINGLE_PRODUCT_REQUEST,
  SINGLE_PRODUCT_SUCCESS
} from "./type";
// import useEncryption from "../../components/Common/useEncryption";

//fetch products
export const fetchProducts = (body) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.products}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: PRODUCTS_SUCESSS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    dispatch({ type: PRODUCTS_FAIL, error: message });
  }
};

//add products
export const addProduct = (body) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addProduct}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PRODUCT_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: PRODUCT_ADD_FAIL, error: message });
  }
};

//update single product
export const updateProduct = (body, id) => async (dispatch) => {
  var object = {};
  body.forEach((value, key) => (object[key] = value));
  var json = object;
  try {
    dispatch({ type: PRODUCT_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editProduct}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);

    return dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: { ...data, json }
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: PRODUCT_UPDATE_FAIL, error: message });
  }
};

//get single product
export const getSingleProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_PRODUCT_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getProduct}`;
    const { data } = await axiosForMultipart.get(`${apiEndpoint}/${id}`);
    return dispatch({
      type: SINGLE_PRODUCT_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: SINGLE_PRODUCT_FAIL, error: message, statusCode: error?.status });
  }
};

//delete single product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteProduct}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: PRODUCT_DELETE_FAIL, error: message });
  }
};

//delete product from api
export const deleteFromAPiProduct = (body) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT__DELETE_FROM_API_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteProductFromAPI}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PRODUCT__DELETE_FROM_API_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: PRODUCT__DELETE_FROM_API_FAIL, error: message });
  }
};

//delete product from api
export const getSpecificationsByCategory = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPECIFICATIONS_BY_CATEGORY_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getSpecificationsByCategory}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: GET_SPECIFICATIONS_BY_CATEGORY_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: GET_SPECIFICATIONS_BY_CATEGORY_FAIL, error: message });
  }
};

//add single sub-product image
export const addSubProductImage = (body) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addSingleSubProductImageEndpoint}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    // toast.error(message);
    return dispatch({ type: PRODUCT_DELETE_FAIL, error: message });
  }
};

//add single sub-product image
export const addProductImage = (body) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addSingleProductImageEndpoint}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    return dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: PRODUCT_DELETE_FAIL, error: message });
  }
};
