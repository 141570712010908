import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { shallowEqual, useSelector } from "react-redux";

export default function ProductTabs({ handleChange2, value, id }) {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  return (
    <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider", position: "sticky", top: 0 }}>
      <Tabs
        value={value}
        onChange={handleChange2}
        textColor="primary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ "& .MuiButtonBase-root": { fontWeight: "bold" } }}
      >
        <Tab value="one" label="Product" />
        {user && user?.userRole?.includes("sub_product_list") && (
          <Tab value="two" label="Sub-Product" className="disabledTab" disabled={id ? false : true} />
        )}
      </Tabs>
    </Box>
  );
}
