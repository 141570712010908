import axios from "axios";
import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart } from "../../../utils/JsonInterceptor";
import { CustomerMap } from "./type";
// import useEncryption from "../../components/Common/useEncryption";

//fetch Customer
export const fetchCustomer = (body) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMERS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.customer}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(message);
    dispatch({ type: CustomerMap.CUSTOMERS_FAIL, error: message });
  }
};

//Add Customer
export const createCustomer = (body) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMERS_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addCustomer}`;
    const { data } = await axiosForMultipart.post(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMERS_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: CustomerMap.CUSTOMERS_ADD_FAIL, error: message });
  }
};

//Edit Customer
export const updateCustomer = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMERS_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editCustomer}`;
    const { data } = await axiosForMultipart.put(`${apiEndpoint}/${id}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMERS_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CustomerMap.CUSTOMERS_UPDATE_FAIL, error: message });
  }
};

//Delete Customer
export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMERS_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteCustomer}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMERS_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CustomerMap.CUSTOMERS_DELETE_FAIL, error: message });
  }
};

//Get Single Customer
export const getSingleCustomer = (id) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMER_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getCustomer}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}`);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMER_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CustomerMap.CUSTOMER_FAIL, error: message });
  }
};

//Get Single Customer
export const getAddressByZip = (id) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.GET_ADDRESS_REQUEST });
    let apiEndpoint = `https://www.prominentgames.com/us_zip_city/get.php?zip_code=`;
    const { data } = await axios.get(`${apiEndpoint}${id}`);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.GET_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    // const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(error);
    return dispatch({ type: CustomerMap.GET_ADDRESS_FAIL, error: error });
  }
};

//Customer Address Update
export const updateCustomerAddress = (body) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.CUSTOMER_ADDRESS_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editCustomerAddress}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.CUSTOMER_ADDRESS_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: CustomerMap.CUSTOMER_ADDRESS_UPDATE_FAIL, error: message });
  }
};

//fetch Customer
export const getCustomersDropdownData = (body) => async (dispatch) => {
  try {
    dispatch({ type: CustomerMap.GET_CUSTOMERS_DROPDOWN_REQUEST });
    let apiEndpoint = `${API_END_POINTS.customersDropdownData}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    // var res = useEncryption(response.data);
    return dispatch({
      type: CustomerMap.GET_CUSTOMERS_DROPDOWN_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message;
    toast.error(message);
    dispatch({ type: CustomerMap.GET_CUSTOMERS_DROPDOWN_FAIL, error: message });
  }
};
