import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  styled
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { shallowEqual, useSelector } from "react-redux";
import { convertToDollar } from "../../utils/custom";
import ComponentLoader from "../../components/ComponentLoader";
import DataNotFound from "../../components/DataNotFound";
import { Fragment, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import GetUserRole from "../../utils/GetUserRole";
import InfoIcon from "@mui/icons-material/Info";
import OrderInfoTable from "./OrderInfoTable";
import { tooltipClasses } from "@mui/material/Tooltip";

export default function StatementInvoiceTable() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(-1);
  const ORDER_VIEW = GetUserRole("order_view");
  const IS_LOCATION = user.userType === "store";
  const { invoiceStatementsData, isLoading } = useSelector((state) => state.statement, shallowEqual);

  const sumTotalAdminComission = invoiceStatementsData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalAdminComm,
    0
  );

  const sumTotalAmount = invoiceStatementsData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalAmount,
    0
  );

  const sumTotalLocationAmount = invoiceStatementsData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalLocationAmount,
    0
  );

  const WidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500
    }
  });
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {isLoading ? (
        <ComponentLoader />
      ) : (
        <TableContainer sx={{ maxHeight: "505px" }}>
          <Table stickyHeader aria-label="sticky table" className="location_table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>ID</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Category Name</Typography>
                </TableCell>
                {!IS_LOCATION && (
                  <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                    <Typography>Total Admin Comission</Typography>
                  </TableCell>
                )}

                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "100px" }}>
                  <Typography>Total Location Amount</Typography>
                </TableCell>
                <TableCell sx={{ background: colors.themeGray[600], color: colors.white[100], minWidth: "140px" }}>
                  <Typography>Total Amount</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceStatementsData && invoiceStatementsData.length > 0 ? (
                invoiceStatementsData.map((row, index) => (
                  <>
                    <TableRow sx={{ position: "relative", "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>
                        <Typography color={colors.secondary[900]} fontWeight="bold">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>{row.rootCategoryName}</Typography>
                      </TableCell>
                      {!IS_LOCATION && (
                        <TableCell>
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {row.totalAdminComm ? convertToDollar(row.totalAdminComm.toFixed(2)) : "$0.00"}
                          </Typography>
                        </TableCell>
                      )}

                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {row.totalLocationAmount ? convertToDollar(row.totalLocationAmount.toFixed(2)) : "$0.00"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {row.totalAmount ? convertToDollar(row.totalAmount.toFixed(2)) : "$0.00"}
                        </Typography>
                      </TableCell>
                      <IconButton
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translate(0, -50%)",
                          color: "rgb(60 59 59)",
                          padding: 0,
                          marginRight: "5px"
                        }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(open === index ? -1 : index)}
                      >
                        {open === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0, border: "0px", p: 0 }}>
                        <Collapse in={open === index} timeout="auto" unmountOnExit>
                          <Box
                            sx={{
                              width: "100%",
                              minHeight: 36,
                              textAlign: "center",
                              alignItems: "center",
                              fontSize: 18
                            }}
                          >
                            <Paper sx={{ margin: "13px !important" }}>
                              <Table aria-label="sticky table" className="location_inner_table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        background: colors.themeGray[600],
                                        color: colors.white[100],
                                        minWidth: "100px"
                                      }}
                                    >
                                      <Typography>Order Id</Typography>
                                    </TableCell>
                                    {!IS_LOCATION && (
                                      <TableCell
                                        sx={{
                                          background: colors.themeGray[600],
                                          color: colors.white[100],
                                          minWidth: "100px"
                                        }}
                                      >
                                        <Typography>Admin Commission</Typography>
                                      </TableCell>
                                    )}
                                    <TableCell
                                      sx={{
                                        background: colors.themeGray[600],
                                        color: colors.white[100],
                                        minWidth: "100px"
                                      }}
                                    >
                                      <Typography>Location Amount</Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        background: colors.themeGray[600],
                                        color: colors.white[100],
                                        minWidth: "100px"
                                      }}
                                    >
                                      <Typography>Total</Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row?.orderItems.map((order, i) => {
                                    return (
                                      <TableRow
                                        sx={{ position: "relative", "&:last-child td, &:last-child th": { border: 0 } }}
                                      >
                                        <TableCell>
                                          {ORDER_VIEW ? (
                                            <Link
                                              style={{
                                                color: colors.secondary[900],
                                                fontWeight: "bold",
                                                textDecoration: "none"
                                              }}
                                              to={`/orders/view/${order?.orderId}`}
                                              target="_blank"
                                            >
                                              {order?.orderId}
                                            </Link>
                                          ) : (
                                            <Typography>{order?.orderId}</Typography>
                                          )}
                                          {/* {order?.subProduct?.some(({ itemStatus }) => itemStatus !== "") && ( */}
                                          <WidthTooltip
                                            title={
                                              <Fragment>
                                                <OrderInfoTable order={order?.subProduct} colors={colors} />
                                              </Fragment>
                                            }
                                            placement="right"
                                          >
                                            <span className="lh-0">
                                              <InfoIcon
                                                sx={{
                                                  fontSize: "15px",
                                                  ml: 0.5,
                                                  verticalAlign: "middle",
                                                  cursor: "pointer"
                                                }}
                                              />
                                            </span>
                                          </WidthTooltip>
                                          {/* // )} */}
                                        </TableCell>
                                        {!IS_LOCATION && (
                                          <TableCell>
                                            <Typography sx={{ textTransform: "capitalize" }}>
                                              ${order?.adminComm?.toFixed(2)}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        <TableCell>
                                          <Typography sx={{ textTransform: "capitalize" }}>
                                            ${order?.storeAmount?.toFixed(2)}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Typography sx={{ textTransform: "capitalize" }}>
                                            ${order?.total?.toFixed(2)}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Paper>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <DataNotFound />
              )}

              <TableRow sx={{ background: colors.themeGray[300], position: "sticky", bottom: 0 }}>
                <TableCell colSpan={2}>
                  <Typography fontWeight="bold">
                    {" "}
                    <b>Total</b>
                  </Typography>
                </TableCell>
                {!IS_LOCATION && (
                  <TableCell>
                    <Typography sx={{ textTransform: "capitalize" }}>
                      <b>
                        ${sumTotalAdminComission ? sumTotalAdminComission && sumTotalAdminComission.toFixed(2) : "0.00"}
                      </b>
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    <b>
                      ${sumTotalLocationAmount ? sumTotalLocationAmount && sumTotalLocationAmount.toFixed(2) : "0.00"}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    <b> ${sumTotalAmount ? sumTotalAmount && sumTotalAmount.toFixed(2) : "0.00"}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
