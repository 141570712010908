import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import MyDialog from "../../components/MyDialog";

import StatementInvoiceTable from "./StatementInvoiceTable";

export default function StatementAddModal({ openModal, setOpenModal, storeName }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;

  return (
    <React.Fragment>
      <MyDialog
        maxWidth="md"
        disableEscapeKeyDown={true}
        open={openModal}
        top="1%"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <DialogTitle className="modal-header"> Location Name: {storeName}</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={gridSpacing}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <StatementInvoiceTable />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </MyDialog>
    </React.Fragment>
  );
}
