// sub product module types

export const SINGLE_SUB_PRODUCT_REQUEST = "SINGLE_SUB_PRODUCT_REQUEST";
export const SINGLE_SUB_PRODUCT_SUCESSS = "SINGLE_SUB_PRODUCT_SUCESSS";
export const SINGLE_SUB_PRODUCT_RESET = "SINGLE_SUB_PRODUCT_RESET";
export const SINGLE_SUB_PRODUCT_FAIL = "SINGLE_SUB_PRODUCT_FAIL";

export const SINGLE_PRODUCT_ADD_REQUEST = "SINGLE_PRODUCT_ADD_REQUEST";
export const SINGLE_PRODUCT_ADD_SUCCESS = "SINGLE_PRODUCT_ADD_SUCCESS";
export const SINGLE_PRODUCT_ADD_FAIL = "SINGLE_PRODUCT_ADD_FAIL";
export const SINGLE_PRODUCT_ADD_RESET = "SINGLE_PRODUCT_ADD_RESET";

export const SINGLE_PRODUCT_UPDATE_REQUEST = "SINGLE_PRODUCT_UPDATE_REQUEST";
export const SINGLE_PRODUCT_UPDATE_SUCCESS = "SINGLE_PRODUCT_UPDATE_SUCCESS";
export const SINGLE_PRODUCT_UPDATE_FAIL = "SINGLE_PRODUCT_UPDATE_FAIL";
export const SINGLE_PRODUCT_UPDATE_RESET = "SINGLE_PRODUCT_UPDATE_RESET";

export const SINGLE_PRODUCT_DELETE_REQUEST = "SINGLE_PRODUCT_DELETE_REQUEST";
export const SINGLE_PRODUCT_DELETE_SUCCESS = "SINGLE_PRODUCT_DELETE_SUCCESS";
export const SINGLE_PRODUCT_DELETE_FAIL = "SINGLE_PRODUCT_DELETE_FAIL";
export const SINGLE_PRODUCT_DELETE_RESET = "SINGLE_PRODUCT_DELETE_RESET";

export const SINGLEB_SUB_PRODUCT_REQUEST = "SINGLEB_SUB_PRODUCT_REQUEST";
export const SINGLEB_SUB_PRODUCT_SUCESSS = "SINGLEB_SUB_PRODUCT_SUCESSS";
export const SINGLEB_SUB_PRODUCT_RESET = "SINGLEB_SUB_PRODUCT_RESET";
export const SINGLEB_SUB_PRODUCT_FAIL = "SINGLEB_SUB_PRODUCT_FAIL";


export const GET_BRAND_DROPDOWN_REQUEST = "GET_BRAND_DROPDOWN_REQUEST";
export const GET_BRAND_DROPDOWN_SUCESSS = "GET_BRAND_DROPDOWN_SUCESSS";
export const GET_BRAND_DROPDOWN_RESET = "GET_BRAND_DROPDOWN_RESET";
export const GET_BRAND_DROPDOWN_FAIL = "GET_BRAND_DROPDOWN_FAIL";
