// Order module types
export const OrderMap = {
  ORDER_REQUEST: "ORDER_REQUEST",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  ORDER_FAIL: "ORDER_FAIL",

  ORDER_ADD_REQUEST: "ORDER_ADD_REQUEST",
  ORDER_ADD_SUCCESS: "ORDER_ADD_SUCCESS",
  ORDER_ADD_FAIL: "ORDER_ADD_FAIL",

  ORDER_UPDATE_REQUEST: "ORDER_UPDATE_REQUEST",
  ORDER_UPDATE_SUCCESS: "ORDER_UPDATE_SUCCESS",
  ORDER_UPDATE_FAIL: "ORDER_UPDATE_FAIL",

  SINGLE_ORDER_REQUEST: "SINGLE_ORDER_REQUEST",
  SINGLE_ORDER_SUCCESS: "SINGLE_ORDER_SUCCESS",
  SINGLE_ORDER_FAIL: "SINGLE_ORDER_FAIL",

  ORDER_DELETE_REQUEST: "ORDER_DELETE_REQUEST",
  ORDER_DELETE_SUCCESS: "ORDER_DELETE_SUCCESS",
  ORDER_DELETE_FAIL: "ORDER_DELETE_FAIL",

  CUSTOMER_DROPDOWN_REQUEST: "CUSTOMER_DROPDOWN_REQUEST",
  CUSTOMER_DROPDOWN_SUCCESS: "CUSTOMER_DROPDOWN_SUCCESS",
  CUSTOMER_DROPDOWN_FAIL: "CUSTOMER_DROPDOWN_FAIL",

  FIND_INVENTORY_REQUEST: "FIND_INVENTORY_REQUEST",
  FIND_INVENTORY_SUCCESS: "FIND_INVENTORY_SUCCESS",
  FIND_INVENTORY_FAIL: "FIND_INVENTORY_FAIL",

  SEARCH_INVENTORY_REQUEST: "SEARCH_INVENTORY_REQUEST",
  SEARCH_INVENTORY_SUCCESS: "SEARCH_INVENTORY_SUCCESS",
  SEARCH_INVENTORY_FAIL: "SEARCH_INVENTORY_FAIL",

  PAYMENT_ADD_REQUEST: "PAYMENT_ADD_REQUEST",
  PAYMENT_ADD_SUCCESS: "PAYMENT_ADD_SUCCESS",
  PAYMENT_ADD_FAIL: "PAYMENT_ADD_FAIL",

  PDF_GET_REQUEST: "PDF_GET_REQUEST",
  PDF_GET_SUCCESS: "PDF_GET_SUCCESS",
  PDF_GET_FAIL: "PDF_GET_FAIL",

  PAYMENT_EDIT_REQUEST: "PAYMENT_EDIT_REQUEST",
  PAYMENT_EDIT_SUCCESS: "PAYMENT_EDIT_SUCCESS",
  PAYMENT_EDIT_FAIL: "PAYMENT_EDIT_FAIL",

  PAYMENT_CLEAR_REQUEST: "PAYMENT_CLEAR_REQUEST",
  PAYMENT_CLEAR_SUCCESS: "PAYMENT_CLEAR_SUCCESS",
  PAYMENT_CLEAR_FAIL: "PAYMENT_CLEAR_FAIL",

  FEDEX_ADD_SHIPMENT_REQUEST: "FEDEX_ADD_SHIPMENT_REQUEST",
  FEDEX_ADD_SHIPMENT_SUCCESS: "FEDEX_ADD_SHIPMENT_SUCCESS",
  FEDEX_ADD_SHIPMENT_FAIL: "FEDEX_ADD_SHIPMENT_FAIL",

  FEDEX_GET_QUOTES_REQUEST: "FEDEX_GET_QUOTES_REQUEST",
  FEDEX_GET_QUOTES_SUCCESS: "FEDEX_GET_QUOTES_SUCCESS",
  FEDEX_GET_QUOTES_FAIL: "FEDEX_GET_QUOTES_FAIL",

  GET_ORDER_LOCATIONS_REQUEST: "GET_ORDER_LOCATIONS_REQUEST",
  GET_ORDER_LOCATIONS_SUCCESS: "GET_ORDER_LOCATIONS_SUCCESS",
  GET_ORDER_LOCATIONS_FAIL: "GET_ORDER_LOCATIONS_FAIL",

  ASSIGN_LOCATION_REQUEST: "ASSIGN_LOCATION_REQUEST",
  ASSIGN_LOCATION_SUCCESS: "ASSIGN_LOCATION_SUCCESS",
  ASSIGN_LOCATION_FAIL: "ASSIGN_LOCATION_FAIL",

  GET_CANCEL_REASON_REQUEST: "GET_CANCEL_REASON_REQUEST",
  GET_CANCEL_REASON_SUCCESS: "GET_CANCEL_REASON_SUCCESS",
  GET_CANCEL_REASON_FAIL: "GET_CANCEL_REASON_FAIL",
  CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAIL: "CANCEL_ORDER_FAIL",

  RETURNREPLACE_REQUEST: "RETURNREPLACE_REQUEST",
  RETURNREPLACE_SUCCESS: "RETURNREPLACE_SUCCESS",
  RETURNREPLACE_FAIL: "RETURNREPLACE_FAIL"
};
