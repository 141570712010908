// Store module types
export const StoreMap = {
  STORES_REQUEST: "STORES_REQUEST",
  STORES_SUCCESS: "STORES_SUCCESS",
  STORES_FAIL: "STORES_FAIL",

  STORE_ADD_REQUEST: "STORE_ADD_REQUEST",
  STORE_ADD_SUCCESS: "STORE_ADD_SUCCESS",
  STORE_ADD_FAIL: "STORE_ADD_FAIL",

  STORE_UPDATE_REQUEST: "STORE_UPDATE_REQUEST",
  STORE_UPDATE_SUCCESS: "STORE_UPDATE_SUCCESS",
  STORE_UPDATE_FAIL: "STORE_UPDATE_FAIL",

  STORE_DELETE_REQUEST: "STORE_DELETE_REQUEST",
  STORE_DELETE_SUCCESS: "STORE_DELETE_SUCCESS",
  STORE_DELETE_FAIL: "STORE_DELETE_FAIL",

  GET_SINGLE_STORE_REQUEST: "GET_SINGLE_STORE_REQUEST",
  GET_SINGLE_STORE_SUCCESS: "GET_SINGLE_STORE_SUCCESS",
  GET_SINGLE_STORE_FAIL: "GET_SINGLE_STORE_FAIL",

  GET_STORE_PRODCUTS_REQUEST: "GET_STORE_PRODCUTS_REQUEST",
  GET_STORE_PRODCUTS_SUCCESS: "GET_STORE_PRODCUTS_SUCCESS",
  GET_STORE_PRODCUTS_FAIL: "GET_STORE_PRODCUTS_FAIL",
  ADD_STORE_PRODCUTS_REQUEST: "ADD_STORE_PRODCUTS_REQUEST",
  ADD_STORE_PRODCUTS_SUCCESS: "ADD_STORE_PRODCUTS_SUCCESS",
  ADD_STORE_PRODCUTS_FAIL: "ADD_STORE_PRODCUTS_FAIL",

  UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAIL: "UPDATE_CATEGORY_FAIL",

  GET_STORES_DROPDOWN_REQUEST: "GET_STORES_DROPDOWN_REQUEST",
  GET_STORES_DROPDOWN_SUCCESS: "GET_STORES_DROPDOWN_SUCCESS",
  GET_STORES_DROPDOWN_FAIL: "GET_STORES_DROPDOWN_FAIL"
};

export const StoreDashboardMap = {
  GET_DASHBOARD_DETAILS_FLAG1: "GET_DASHBOARD_DETAILS_FLAG1",
  GET_DASHBOARD_DETAILS_FLAG1_REQUESTED: "GET_DASHBOARD_DETAILS_FLAG1_REQUESTED",
  GET_DASHBOARD_DETAILS_FLAG1_FAILED: "GET_DASHBOARD_DETAILS_FLAG1_FAILED",
  GET_DASHBOARD_DETAILS_FLAG2: "GET_DASHBOARD_DETAILS_FLAG2",
  GET_DASHBOARD_DETAILS_FLAG3: "GET_DASHBOARD_DETAILS_FLAG3",
  GET_DASHBOARD_DETAILS_FLAG5: "GET_DASHBOARD_DETAILS_FLAG5",
  GET_DASHBOARD_DETAILS_FLAG4: "GET_DASHBOARD_DETAILS_FLAG4",
  GET_DASHBOARD_DETAILS_FLAG6: "GET_DASHBOARD_DETAILS_FLAG6",
  GET_DASHBOARD_DETAILS_FLAG7: "GET_DASHBOARD_DETAILS_FLAG7"
};
