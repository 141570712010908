import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const HoverTooltip = ({ title = "", icon = <HelpOutlineIcon sx={{ fontSize: "17px" }} size="small" />, ...rest }) => {
  return (
    <Tooltip title={title} {...rest}>
      <IconButton sx={{ p: 0, mx: 0.5 }}>{icon}</IconButton>
    </Tooltip>
  );
};

export default HoverTooltip;
