import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

export default function DataNotFound({ msg, ...rest }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      width="100%"
      textAlign="center"
      backgroundColor={colors.themeGray[100]}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={1}
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      p={2}
      lineHeight={0}
      {...rest}
    >
      <Typography variant="h5" color={colors.secondary[900]}>
        {msg || "Data Not Found"}
      </Typography>
    </Box>
  );
}
