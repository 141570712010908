import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Header, InternalPageHeader } from "../../components/Header";
import MyCard from "../../components/MyCard";
import "./CategoryTaxTree.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCategoryTaxTree } from "../category/redux/action";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Search from "../../components/Search";
import CreateIcon from "@mui/icons-material/Create";
import TaxAdd from "./TaxAddModal";
import { useNavigate, useParams } from "react-router-dom";
import GlobalLoader from "../../components/GlobalLoader";
import GetUserRole from "../../utils/GetUserRole";
import noImage from "../../images/no-image.png";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function CategoryTaxTree() {
  const editCommission = GetUserRole("store_tax_comm_edit");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [wichOne, setWichOne] = useState("comission");
  const [rowData, setRowData] = useState("");
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [reqData, setReqData] = useState({
    search: ""
  });
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );

  const { isTaxCategoryLoading } = useSelector((state) => ({
    isTaxCategoryLoading: state && state?.categories ? state?.categories?.isTaxCategoryLoading : ""
  }));

  const { categoriesTaxTreeList } = useSelector(
    (state) => ({
      categoriesTaxTreeList:
        state && state.categories && state.categories.categoriesTaxTreeList
          ? state.categories.categoriesTaxTreeList?.categories
          : []
    }),
    shallowEqual
  );

  const { storeData } = useSelector(
    (state) => ({
      storeData:
        state && state.categories && state.categories.categoriesTaxTreeList
          ? state.categories.categoriesTaxTreeList?.storeData
          : []
    }),
    shallowEqual
  );

  useEffect(() => {
    if (user?.userType === "store") {
      document.title = "Category Tree | Doorvenza";

      dispatch(getCategoryTaxTree({ storeId: user._id }));
    } else {
      if (id) {
        dispatch(getCategoryTaxTree({ storeId: id }));
      }
    }
  }, [dispatch, id, user._id, user?.userType]);

  // Close tax Modal
  const handleClose = () => {
    setOpen(false);
  };

  function SubCategoryView(arr) {
    return (
      <ul>
        {arr?.map((item, subI) => {
          return (
            <li key={subI} className={`${arr?.length > 1 && arr?.length - 1 !== subI ? "sideLine" : ""}`}>
              <Box
                className={`stickyTree innerBlock`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="8px"
              >
                <Box>
                  <Typography fontWeight="bold" sx={{ wordBreak: "break-word" }}>
                    {item?.name}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    // flexDirection="column"
                    gap="20px"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="baseline"
                      flexDirection="column"
                      fontSize="12px"
                      fontWeight="bold"
                      color={colors.themePrimary[900]}
                      sx={{ opacity: "0.8" }}
                    >
                      <Tooltip title="Comission Percentage (%)" className="cursor-help">
                        Commission:{" "}
                      </Tooltip>
                      <Typography variant="span" color={colors.themeGray[600]}>
                        {item?.commission ? `${item?.commission}%` : "N/A"}
                      </Typography>
                      {(user.userType === "admin" || user.userType === "user") && (
                        <IconButton
                          disabled={editCommission ? false : true}
                          onClick={() => {
                            setOpen(!open);
                            setWichOne("comission");
                            setCategoryId(item._id);
                            setRowData(item);
                          }}
                          sx={{
                            bgcolor: colors.themeGray[200],
                            borderRadius: 6,
                            marginLeft: 1
                          }}
                        >
                          <CreateIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="baseline"
                      flexDirection="column"
                      fontSize="12px"
                      fontWeight="bold"
                      color={colors.themePrimary[900]}
                      sx={{ opacity: "0.8" }}
                    >
                      <Tooltip title="Tax (%)" className="cursor-help">
                        Tax:{" "}
                      </Tooltip>
                      <Typography variant="span" color={colors.themeGray[600]}>
                        {item?.taxData?.label && item?.taxData?.taxPercent
                          ? `${item?.taxData?.label} (${item?.taxData?.taxPercent}%)`
                          : "N/A"}
                      </Typography>
                      {(user.userType === "admin" || user.userType === "user") && (
                        <IconButton
                          disabled={editCommission ? false : true}
                          onClick={() => {
                            setOpen(!open);
                            setWichOne("tax");
                            setCategoryId(item._id);
                            setRowData(item);
                          }}
                          sx={{
                            bgcolor: colors.themeGray[200],
                            borderRadius: 6,
                            marginLeft: 1
                          }}
                        >
                          <CreateIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {item?.subcategories?.length > 0 && SubCategoryView(item?.subcategories)}
            </li>
          );
        })}
      </ul>
    );
  }

  const onImageError = (e) => {
    e.target.src = noImage;
  };

  return (
    <>
      <Box mx="20px">
        {user?.userType === "store" ? (
          <Header title="ASSIGNED TAX/COMMISSION VIEW" />
        ) : (
          <InternalPageHeader
            onClick={() => {
              navigate(-1);
            }}
            title="Assign Tax/Commission"
          />
        )}
        <MyCard sx={{ overflow: "unset", mb: 2 }}>
          {isTaxCategoryLoading ? (
            <GlobalLoader />
          ) : (
            <>
              <Grid container spacing={1} sx={{ mb: 3 }}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Typography variant="h3" fontWeight="bold">
                    Location Name:
                    <Typography variant="span" color={colors.themePrimary[900]}>
                      {" "}
                      {storeData?.storeData?.storeName}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{ textAlign: "right" }}>
                  <Search
                    placeholder="Search category and sub category..."
                    reqData={reqData}
                    setReqData={setReqData}
                    sx={{ float: "right", textAlign: "right" }}
                    isMark={true}
                  />
                </Grid>
              </Grid>
              <Box
                id="content"
                sx={{
                  overflowY: "auto",
                  maxHeight: "550px",
                  border: `1px solid ${colors.themeGray[200]}`,
                  borderRadius: 1,
                  px: 1,
                  background: colors.themeGray[100]
                }}
              >
                <ul className="tree">
                  {categoriesTaxTreeList?.length > 0 &&
                    categoriesTaxTreeList?.map((category, i) => {
                      return (
                        <li key={i}>
                          <Box
                            className="stickyTree"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="8px"
                          >
                            <Box>
                              <Box className="treeValue">
                                <img
                                  alt={category?.name}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    border: `1px solid ${colors.themeGray[200]}`,
                                    objectFit: "contain",
                                    pointerEvents: "none",
                                    userSelect: "none"
                                  }}
                                  src={
                                    category?.categoryImg && category?.categoryImg?.fileUrl
                                      ? baseUrl + "/" + category?.categoryImg?.fileUrl
                                      : noImage
                                  }
                                  onError={onImageError}
                                />
                                <Box>
                                  <Typography fontWeight="bold" sx={{ wordBreak: "break-word" }}>
                                    {category?.name}
                                  </Typography>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    // flexDirection="column"
                                    gap="20px"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="baseline"
                                      flexDirection="column"
                                      fontSize="12px"
                                      fontWeight="bold"
                                      color={colors.themePrimary[900]}
                                      sx={{ opacity: "0.8" }}
                                    >
                                      <Tooltip title="Comission Percentage (%)" className="cursor-help">
                                        Commission:{" "}
                                      </Tooltip>
                                      <Typography variant="span" color={colors.themeGray[600]}>
                                        {category?.commission ? `${category?.commission}%` : "N/A"}
                                      </Typography>
                                      {(user.userType === "admin" || user.userType === "user") && (
                                        <IconButton
                                          disabled={editCommission ? false : true}
                                          onClick={() => {
                                            setOpen(!open);
                                            setWichOne("comission");
                                            setCategoryId(category._id);
                                            setRowData(category);
                                          }}
                                          sx={{
                                            bgcolor: colors.themeGray[200],
                                            borderRadius: 6,
                                            marginLeft: 1
                                          }}
                                        >
                                          <CreateIcon sx={{ fontSize: "14px" }} />
                                        </IconButton>
                                      )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="baseline"
                                      flexDirection="column"
                                      fontSize="12px"
                                      fontWeight="bold"
                                      color={colors.themePrimary[900]}
                                      sx={{ opacity: "0.8" }}
                                    >
                                      <Tooltip title="Tax (%)" className="cursor-help">
                                        Tax:{" "}
                                      </Tooltip>
                                      <Typography variant="span" color={colors.themeGray[600]}>
                                        {category?.taxData?.label && category?.taxData?.taxPercent
                                          ? `${category?.taxData?.label} (${category?.taxData?.taxPercent}%)`
                                          : "N/A"}
                                      </Typography>
                                      {(user.userType === "admin" || user.userType === "user") && (
                                        <IconButton
                                          disabled={editCommission ? false : true}
                                          onClick={() => {
                                            setOpen(!open);
                                            setWichOne("tax");
                                            setCategoryId(category._id);
                                            setRowData(category);
                                          }}
                                          sx={{
                                            bgcolor: colors.themeGray[200],
                                            borderRadius: 6,
                                            marginLeft: 1
                                          }}
                                        >
                                          <CreateIcon sx={{ fontSize: "14px" }} />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {category?.subcategories?.length > 0 && SubCategoryView(category?.subcategories)}
                        </li>
                      );
                    })}
                </ul>
              </Box>
            </>
          )}
        </MyCard>
      </Box>
      <TaxAdd
        wichOne={wichOne}
        setWichOne={setWichOne}
        open={open}
        handleClose={handleClose}
        categoryId={categoryId}
        rowData={rowData}
        setRowData={setReqData}
      />
    </>
  );
}
