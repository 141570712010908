import { Box, IconButton, Popover, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";

export default function ReasonPopover({ reason }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} sx={{ p: 0.5 }} onClick={handleClick}>
        <InfoIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        // PaperProps={{
        //   style: {
        //     overflow: "visible"
        //   }
        // }}
      >
        <Box
          sx={{
            p: 2,
            maxWidth: "250px"
            // position: "relative",
            // "&::before": {
            //   backgroundColor: "white",
            //   content: '""',
            //   display: "block",
            //   position: "absolute",
            //   width: "12px",
            //   height: "12px",
            //   top: "0",
            //   left: "49%",
            //   transform: "rotate(45deg) translateX(-40%)"
            // }
          }}
        >
          <Typography>{reason}</Typography>
        </Box>
      </Popover>
    </>
  );
}
