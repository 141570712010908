import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const OrderInfoTable = ({ order = [], colors }) => {
  const style = { background: colors.themeGray[600], color: colors.white[100], minWidth: "25px" };
  return (
    <TableContainer sx={{ maxHeight: "200px" }}>
      <Table stickyHeader aria-label="sticky table" className="tooltip_table">
        <TableHead>
          <TableRow>
            <TableCell sx={style}>Name</TableCell>
            <TableCell sx={style}>SKU</TableCell>
            <TableCell sx={style}>Admin</TableCell>
            <TableCell sx={style}>Amount</TableCell>
            <TableCell sx={style}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.map(({ name = "", SKU = 0, charge = 0, chargeAmount = 0, itemStatus = "", chargeType = "" }, i) => {
            return (
              <TableRow sx={{ background: colors.themeGray[300] }} key={i}>
                <TableCell>{name}</TableCell>
                <TableCell>{SKU}</TableCell>
                <TableCell>
                  {`${chargeType === "fixed" ? "$" : "".trim()}${charge}${
                    chargeType === "percentage" ? "%" : "".trim()
                  }`}
                </TableCell>
                <TableCell>${chargeAmount.toFixed(2)}</TableCell>
                <TableCell style={{ textTransform: "capitalize" }}>
                  {itemStatus === "" ? "Delivered" : itemStatus}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderInfoTable;
