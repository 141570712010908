import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
// import IconButton from "@mui/material/IconButton";
// import { toast } from "react-toastify";
// import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
// import { useDispatch } from "react-redux";
// import { deleteFromAPiProduct } from "../pages/product/redux/action";
import ImageViewer from "react-simple-image-viewer";
const baseUrl = process.env.REACT_APP_BACKEND_URI;
export default function ImageClose({ files, setFiles, rowData, type }) {
  // const [, setOpen] = React.useState(true);
  let images =
    files?.length > 0 &&
    files.map((e) => {
      return `${baseUrl}/${e.fileUrl}`;
    });
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  // const dispatch = useDispatch();
  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={false}
        />
      )}
      {files?.map((e, index) => {
        return (
          <Box key={index} sx={{ position: "relative" }}>
            <Box
              sx={{ width: "100%" }}
              className="cursor-pointer imageAlertThumb"
              onClick={() => {
                setIsViewerOpen(true);
                setCurrentImage(index);
              }}
            >
              <Alert
                sx={{
                  width: "fit-content",
                  p: `${files.length > 1 ? "0 20px 0 12px" : "0 12px"}`,
                  "& .MuiAlert-icon": {
                    mr: `${files.length > 1 ? "12px" : 0}`,
                    py: 1.5
                  }
                }}
                icon={<ImageIcon fontSize="inherit" />}
                severity="info"
              ></Alert>
            </Box>
            {/* {files.length > 1 && (
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: "2",
                  right: "0",
                  top: "14%",
                  bgcolor: "#e5f6fd",
                  color: "rgb(1, 67, 97)"
                }}
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  dispatch(
                    deleteFromAPiProduct({
                      type: type === "product" ? "product" : "vendor",
                      _id: rowData?._id,
                      imageId: e._id
                    })
                  ).then(async (data) => {
                    if (data && data?.payload?.status) {
                      toast.success(data.payload?.message);
                      files.splice(index, 1);
                      setFiles([...files]);
                    } else {
                      toast.error(data.error);
                    }
                  });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )} */}
          </Box>
        );
      })}
    </>
  );
}
