import * as React from "react";
import AddSubProductModal from "./AddSubProductModal";

export default function ExpandProductForm({
  rowData,
  setRowData,
  expanded,
  setExpanded,
  files,
  setFiles,
  specificationsData,
  update,
  setUpdate,
  isSpecificationOpen,
  setIsSpecificationOpen
}) {
  return (
    <AddSubProductModal
      expanded={expanded}
      setExpanded={setExpanded}
      rowData={rowData}
      setRowData={setRowData}
      update={update}
      setUpdate={setUpdate}
      files={files}
      setFiles={setFiles}
      specificationsData={specificationsData}
      setIsSpecificationOpen={setIsSpecificationOpen}
      isSpecificationOpen={isSpecificationOpen}
    />
  );
}
