import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  useTheme,
  InputAdornment,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  Alert
} from "@mui/material";
// import Alert from "@mui/material/Alert";
import { Controller, useForm } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import { tokens } from "../../theme";
import Select from "react-select";
import { emailRegex, numericWithDashRegex } from "../../utils/regex";
import { getAddressByZip } from "../customer/redux/action";
import { toast } from "react-toastify";
import LableText from "../../components/LableText";
import { createStore, fetchStores, getSingleStore, updateStore } from "./redux/action";
import { useNavigate, useParams } from "react-router-dom";
import ImageClose from "../../components/ImageClose";
import { InternalPageHeader } from "../../components/Header";
import { getLatLong, inputScroll, styles } from "../../utils/custom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RequiredFieldLabel from "../../components/RequiredFieldLabel";
import { isMaxLimitCross, validFiles } from "../../utils/common";
const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function StoreAddModal({ reqData, rowData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [zipFlag, setZipFlag] = useState(false);
  const [isAddLoader, setIsAddLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [tobbacoFile, setTobbacoFiles] = useState([]);
  const [sellerFile, setSellerFiles] = useState([]);
  const [einFiles, setEINFiles] = useState([]);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const { id } = useParams();
  const defaultStates = {
    firstName: "",
    businessName: "",
    EINNumber: "",
    lastName: "",
    ownerPhone: "",
    ownerEmail: "",
    phone: "",
    email: "",
    storeName: "",
    address: {
      zip: "",
      city: "",
      state: "",
      address: ""
    },
    returnAbleType: "none",
    returnAble: "",
    contractDocument: [],
    premisesType: "ABC",
    status: "true",
    contractType: "upload",
    isDoorDash: false,
    returnAbleFlag: "true",
    tobaccoDocument: [],
    sellerPermitDocument: [],
    EINNumberDocument: [],
    sellerPermit: []
  };
  // main state to store the payload
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: defaultStates
  });

  // get store data endpoint
  React.useEffect(() => {
    if (id) {
      dispatch(getSingleStore(id)).then(async (data) => {
        if (data && data?.payload?.status) {
          let singleEntry = data && data?.payload?.data;

          reset({
            storeName: singleEntry?.storeData?.storeName,
            businessName: singleEntry?.storeData?.businessName,
            firstName: singleEntry?.firstName,
            lastName: singleEntry?.lastName,
            EINNumber: singleEntry?.storeData?.EINNumber,
            ownerEmail: singleEntry?.storeData?.ownerEmail,
            ownerPhone: singleEntry?.storeData?.ownerPhone,
            email: singleEntry?.email,
            // returnAble: singleEntry?.returnAble,
            phone: singleEntry?.phone,
            contractType: singleEntry?.storeData?.contractType,
            premisesType: singleEntry?.storeData?.premisesType,
            status: singleEntry?.status,
            isDoorDash: singleEntry?.storeData?.isDoorDash,
            returnAble: singleEntry?.storeData?.returnAble,
            returnAbleType: singleEntry?.storeData?.returnAbleType,
            returnAbleFlag: singleEntry?.storeData?.returnAbleType === "none" ? "false" : "true",
            sellerPermit: singleEntry?.storeData?.sellerPermit,

            address: {
              zip: singleEntry?.storeData?.address?.zip,
              city: singleEntry?.storeData?.address?.city,
              state: singleEntry?.storeData?.address?.state,
              address: singleEntry?.storeData?.address?.address,
              county: singleEntry?.storeData?.address?.county
            }
          });

          if (singleEntry?.storeData?.contractDocument && singleEntry?.storeData?.contractDocument[0]?.fileUrl) {
            setFiles([singleEntry?.storeData?.contractDocument[0]]);
          }

          if (
            singleEntry?.storeData?.sellerPermitDocument &&
            singleEntry?.storeData?.sellerPermitDocument[0]?.fileUrl
          ) {
            setSellerFiles([singleEntry?.storeData?.sellerPermitDocument[0]]);
          }

          if (singleEntry?.storeData?.tobaccoDocument && singleEntry?.storeData?.tobaccoDocument[0]?.fileUrl) {
            setTobbacoFiles([singleEntry?.storeData?.tobaccoDocument[0]]);
          }

          if (singleEntry?.storeData?.EINNumberDocument && singleEntry?.storeData?.EINNumberDocument[0]?.fileUrl) {
            setEINFiles([singleEntry?.storeData?.EINNumberDocument[0]]);
          }
        }
      });
    } else {
      reset(defaultStates);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const onSubmit = async (data) => {
    let formDataInfo = new FormData();
    formDataInfo.append("businessName", data.businessName);
    formDataInfo.append("storeName", data.storeName);
    formDataInfo.append("firstName", data.firstName);
    formDataInfo.append("lastName", data.lastName);
    formDataInfo.append("EINNumber", data.EINNumber);
    formDataInfo.append("ownerEmail", data.ownerEmail);
    formDataInfo.append("ownerPhone", data.ownerPhone);
    formDataInfo.append("status", data.status);
    formDataInfo.append("contractType", data.contractType);
    formDataInfo.append("premisesType", data.premisesType);
    formDataInfo.append("phone", parseInt(data.phone));
    formDataInfo.append("email", data.email);
    formDataInfo.append("sellerPermit", data.sellerPermit);
    formDataInfo.append("address[zip]", parseInt(data.address.zip));
    formDataInfo.append("address[city]", data.address.city);
    formDataInfo.append("address[state]", data.address.state);
    formDataInfo.append("address[address]", data.address.address);
    formDataInfo.append("address[county]", data.address.county);
    // eslint-disable-next-line eqeqeq
    formDataInfo.append("returnAbleType", data.returnAbleFlag == "false" || false ? "none" : data.returnAbleType);
    formDataInfo.append("isDoorDash", data.isDoorDash);
    data.returnAble && formDataInfo.append("returnAble", data.returnAble);

    id && formDataInfo.append("storeId", id);
    if (data.contractDocument) {
      data.contractDocument.map((x) => {
        return formDataInfo.append("contractDocument[]", x);
      });
    }

    if (data.tobaccoDocument) {
      data.tobaccoDocument.map((x) => {
        return formDataInfo.append("tobaccoDocument[]", x);
      });
    }

    if (data.sellerPermitDocument) {
      data.sellerPermitDocument.map((x) => {
        return formDataInfo.append("sellerPermitDocument[]", x);
      });
    }

    if (data.EINNumberDocument) {
      data.EINNumberDocument.map((x) => {
        return formDataInfo.append("EINNumberDocument[]", x);
      });
    }

    if (Object.keys(errors).length === 0) {
      await getLatLong(`${data.address.address},${data.address.city}, ${data.address.state} ${data.address.zip}`)
        .then(({ lat, long }) => {
          setIsValidAddress(true);
          formDataInfo.append("address[lat]", lat);
          formDataInfo.append("address[long]", long);
          if (id) {
            setIsAddLoader(true);
            dispatch(updateStore(formDataInfo))
              .then(async (data) => {
                setIsAddLoader(false);
                if (data && data.payload) {
                  if (data && data.payload.status) {
                    toast.success(data.payload.message);
                    dispatch(fetchStores(reqData));
                    reset(defaultStates);
                    navigate("/locations");
                  }
                }
              })
              .catch(() => setIsAddLoader(false));
          } else {
            dispatch(createStore(formDataInfo)).then(async (data) => {
              if (data && data.payload) {
                if (data && data.payload.status) {
                  toast.success(data.payload.message);
                  dispatch(fetchStores(reqData));
                  reset(defaultStates);
                  navigate("/locations");
                }
              }
            });
          }
        })
        .catch((error) => {
          setIsAddLoader(false);
          setIsValidAddress(false);
        });
    }
  };

  const Options = [
    { label: "Fixed", value: "fixed", name: "status" },
    { label: "Percentage", value: "percentage", name: "status" }
  ];

  return (
    <React.Fragment>
      <Box mx="20px">
        <InternalPageHeader
          onClick={() => {
            navigate(-1);
          }}
          title={id ? "Edit Location" : "Create New Location"}
        />
        <RequiredFieldLabel mt={2} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box sx={{ pb: gridSpacing }}>
            <Grid container spacing={gridSpacing}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label={
                      <p className="my-0">
                        Location Name <span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("storeName", { required: true, maxLength: 50 })}
                  />
                  {errors.storeName && errors.storeName.type === "required" && (
                    <ErrorMSG text="Location Name is required" />
                  )}
                  {errors.storeName && errors.storeName.type === "maxLength" && (
                    <ErrorMSG text="Location Name should not be greater than length 50" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label={
                      <p className="my-0">
                        Buissness Name <span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("businessName", { required: true, maxLength: 50 })}
                  />
                  {errors.businessName && errors.businessName.type === "required" && (
                    <ErrorMSG text="Buissness Name is required" />
                  )}
                  {errors.businessName && errors.businessName.type === "maxLength" && (
                    <ErrorMSG text="Buissness Name should not be greater than length 50" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label={
                      <p className="my-0">
                        Owner First Name<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("firstName", { required: true, maxLength: 20 })}
                  />
                  {errors.firstName && errors.firstName.type === "required" && (
                    <ErrorMSG text="Owner First Name is required" />
                  )}
                  {errors.firstName && errors.firstName.type === "maxLength" && (
                    <ErrorMSG text="Owner First Name should not be greater than length 20" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label={
                      <p className="my-0">
                        Owner Last Name<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("lastName", { required: true, maxLength: 20 })}
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <ErrorMSG text="Owner Last Name is required" />
                  )}
                  {errors.lastName && errors.lastName.type === "maxLength" && (
                    <ErrorMSG text="Owner Last Name should not be greater than length 20" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    label={
                      <p className="my-0">
                        Owner Email<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("ownerEmail", {
                      required: true,
                      pattern: emailRegex,
                      maxLength: 50
                    })}
                  />
                  {errors.ownerEmail && errors.ownerEmail.type === "required" && (
                    <ErrorMSG text="Owner Email is required" />
                  )}
                  {errors.ownerEmail && errors.ownerEmail.type === "pattern" && <ErrorMSG text="Invalid email" />}
                  {errors.ownerEmail && errors.ownerEmail.type === "maxLength" && (
                    <ErrorMSG text="Owner Email should not be greater than length 50" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          onWheel={inputScroll}
                          className="phoneInput"
                          label={<LableText>Owner Phone Number</LableText>}
                          value={value}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+1</InputAdornment>
                          }}
                          onChange={(e) => {
                            if (e?.target?.value.length > 10) {
                              return false;
                            } else {
                              onChange(e);
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                    control={control}
                    rules={{ required: true, minLength: 10, maxLength: 10 }}
                    name="ownerPhone"
                  />
                  {errors.ownerPhone && errors.ownerPhone.type === "minLength" && (
                    <ErrorMSG text="Owner Phone Number At least 10 digits required" />
                  )}
                  {errors.ownerPhone && errors.ownerPhone.type === "maxLength" && (
                    <ErrorMSG text="Owner Phone Number should not be greater than 10 digits" />
                  )}
                  {errors.ownerPhone && errors.ownerPhone.type === "required" && (
                    <ErrorMSG text="Owner Phone Number is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        className="inputGroupTextField"
                        label={
                          <p className="my-0">
                            Seller Permit<span className="danger mr-0">*</span>
                          </p>
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register("sellerPermit", { required: true })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Box className="inputGroupButton" id="inputGroupButtonWoImg">
                      <label className="cursor-pointer customUploadButtonWithText" htmlFor="customerSellerFileInput">
                        <span>
                          {(sellerFile && sellerFile.length > 0) ||
                          (watch().sellerPermitDocument?.length > 0 && watch()?.sellerPermitDocument)
                            ? `${
                                watch()?.sellerPermitDocument && watch()?.sellerPermitDocument.length > 0
                                  ? watch()?.sellerPermitDocument[0]?.name
                                  : sellerFile[0].fileName
                              }`
                            : "Upload File : -"}
                        </span>
                        {/* <span className="text-truncate">
                          {sellerFile && sellerFile.length > 0
                            ? sellerFile.map((e) => e.fileName)
                            : watch().sellerPermitDocument?.length > 0
                            ? watch().sellerPermitDocument && watch().sellerPermitDocument[0]?.name
                            : ""}
                        </span> */}
                      </label>
                    </Box>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            id="customerSellerFileInput"
                            className={` z-index-1 customInputFileField customFileInput customFileInputButton customFileInputButton-${
                              navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                            }`}
                            inputProps={{
                              multiple: false,
                              accept: "image/png, image/gif, image/jpg, image/jpeg, application/pdf"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              if (isMaxLimitCross(e.target.files[0])) {
                                e.target.value = null;
                                return toast.error("Maximum image size is 2MB");
                              }
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (validFiles.includes(extFile)) {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif, pdf and png files are allowed!");
                                }
                              }
                              await onChange(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      name="sellerPermitDocument"
                      rules={{ required: sellerFile.length > 0 ? false : true }}
                    />
                    {sellerFile ? (
                      sellerFile.length && sellerFile[0]?.contentType === "application/pdf" ? (
                        <a
                          href={`${baseUrl}${sellerFile[0]?.fileUrl}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ width: "fit-content", display: "block" }}
                        >
                          <Box
                            sx={{
                              "& .MuiPaper-root": {
                                m: 0
                              }
                            }}
                          >
                            <Alert
                              sx={{
                                width: "fit-content",
                                p: "0 12px",
                                "& .MuiAlert-icon": {
                                  mr: 0,
                                  py: 1.5
                                }
                              }}
                              icon={<PictureAsPdfIcon fontSize="inherit" />}
                              severity="warning"
                            ></Alert>
                          </Box>
                        </a>
                      ) : // </Alert>
                      id ? (
                        <Box
                          className="vendorModalImageThumb"
                          sx={{ display: "flex", flexWrap: "wrap", mr: 0.5, gap: "4px" }}
                        >
                          {id && (
                            <ImageClose files={sellerFile} setFiles={setSellerFiles} rowData={rowData} type="vendor" />
                          )}
                        </Box>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <Box display="flex" alignItems="center" columnGap="8px" flexWrap="wrap">
                      <Box display="flex" alignItems="center">
                        {errors.sellerPermitDocument && errors.sellerPermitDocument.type === "required" && (
                          <ErrorMSG text="Seller Permit Document is required" />
                        )}
                        {errors.sellerPermitDocument && errors.sellerPermit && <ErrorMSG text="," />}
                      </Box>
                      {errors.sellerPermit && errors.sellerPermit.type === "required" && (
                        <ErrorMSG text="Seller Permit is required" />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            className="inputGroupTextField"
                            value={value}
                            label={
                              <p className="my-0">
                                EIN Number<span className="danger mr-0">*</span>
                              </p>
                            }
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              if (numericWithDashRegex.test(e.target.value) || e.target.value === "") {
                                if (e.nativeEvent.inputType === "insertText") {
                                  if (e.target.value.length === 3) {
                                    value = e.target.value.substring(0, 2) + "-" + e.target.value.substring(2, 10);
                                    onChange(value);
                                  } else if (e.target.value.length > 3) {
                                    value = e.target.value.substring(0, 2) + "-" + e.target.value.substring(3, 10);
                                    onChange(value);
                                  } else {
                                    onChange(e.target.value);
                                  }
                                } else {
                                  onChange(e.target.value);
                                }
                              }
                            }}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                        name="EINNumber"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Box className="inputGroupButton" id="inputGroupButtonWoImg">
                      <label className="cursor-pointer customUploadButtonWithText" htmlFor="customerEinFileInput">
                        <span>
                          {(einFiles && einFiles.length > 0) ||
                          (watch().EINNumberDocument?.length > 0 && watch()?.EINNumberDocument)
                            ? `${
                                watch()?.EINNumberDocument && watch()?.EINNumberDocument.length > 0
                                  ? watch()?.EINNumberDocument[0]?.name
                                  : einFiles[0].fileName
                              }`
                            : "Upload File : -"}
                        </span>
                        <span className="text-truncate"></span>
                      </label>
                    </Box>

                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            id="customerEinFileInput"
                            className={` z-index-1 customInputFileField customFileInput customFileInputButton customFileInputButton-${
                              navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                            }`}
                            inputProps={{
                              multiple: false,
                              accept: "image/png, image/gif, image/jpg, image/jpeg, application/pdf"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              if (isMaxLimitCross(e.target.files[0])) {
                                e.target.value = null;
                                return toast.error("Maximum image size is 2MB");
                              }
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (validFiles.includes(extFile)) {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif, pdf and png files are allowed!");
                                }
                              }
                              await onChange(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      name="EINNumberDocument"
                      rules={{ required: einFiles.length > 0 ? false : true }}
                    />

                    <Box display="flex" alignItems="center" columnGap="8px" flexWrap="wrap">
                      <Box display="flex" alignItems="center">
                        {errors.EINNumberDocument && errors.EINNumberDocument.type === "required" && (
                          <ErrorMSG text="EIN Number Document is required" />
                        )}
                        {errors.EINNumberDocument && errors.EINNumber && <ErrorMSG text="," />}
                      </Box>
                      {errors.EINNumber && errors.EINNumber.type === "required" && (
                        <ErrorMSG text="EIN Number is required" />
                      )}
                    </Box>
                    {einFiles ? (
                      einFiles.length && einFiles[0]?.contentType === "application/pdf" ? (
                        <a
                          href={`${baseUrl}${einFiles[0]?.fileUrl}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ width: "fit-content", display: "block" }}
                        >
                          <Box
                            sx={{
                              "& .MuiPaper-root": {
                                m: 0
                              }
                            }}
                          >
                            <Alert
                              sx={{
                                width: "fit-content",
                                p: "0 12px",
                                "& .MuiAlert-icon": {
                                  mr: 0,
                                  py: 1.5
                                }
                              }}
                              icon={<PictureAsPdfIcon fontSize="inherit" />}
                              severity="warning"
                            ></Alert>
                          </Box>
                        </a>
                      ) : // </Alert>
                      id ? (
                        <Box
                          className="vendorModalImageThumb"
                          sx={{ display: "flex", flexWrap: "wrap", mr: 0.5, gap: "4px" }}
                        >
                          {id && <ImageClose files={einFiles} setFiles={setEINFiles} rowData={rowData} type="vendor" />}
                        </Box>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    label={
                      <p className="my-0">
                        Location Email<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("email", {
                      pattern: emailRegex,
                      required: true,
                      maxLength: 50
                    })}
                  />
                  {errors.email && errors.email.type === "maxLength" && (
                    <ErrorMSG text="Location Email should not be greater than length 50" />
                  )}
                  {errors.email && errors.email.type === "required" && <ErrorMSG text="Location Email is required" />}
                  {errors.email && errors.email.type === "pattern" && <ErrorMSG text="Invalid email" />}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          fullWidth
                          type="number"
                          size="small"
                          onWheel={inputScroll}
                          className="phoneInput"
                          label={<LableText>Location Phone Number</LableText>}
                          value={value}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+1</InputAdornment>
                          }}
                          onChange={(e) => {
                            if (e?.target?.value.length > 10) {
                              return false;
                            } else {
                              onChange(e);
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                    control={control}
                    rules={{ required: true, minLength: 10, maxLength: 10 }}
                    name="phone"
                  />
                  {errors.phone && errors.phone.type === "minLength" && (
                    <ErrorMSG text="Location Phone Number At least 10 digits required" />
                  )}
                  {errors.phone && errors.phone.type === "maxLength" && (
                    <ErrorMSG text="Location Phone Number should not be greater than 10 digits" />
                  )}
                  {errors.phone && errors.phone.type === "required" && (
                    <ErrorMSG text="Location Phone Number is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    label={
                      <p className="my-0">
                        Address<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("address.address", { required: true })}
                  />
                  {!isValidAddress && <ErrorMSG text="Please valid address!" />}
                  {errors.address && errors.address.address && errors.address.address.type === "required" && (
                    <ErrorMSG text="Address is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    label={
                      <p className="my-0">
                        County<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("address.county", { required: true })}
                  />
                  {errors.address && errors.address.county && errors.address.county.type === "required" && (
                    <ErrorMSG text="County is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    onWheel={inputScroll}
                    label={
                      <p className="my-0">
                        Zip<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("address.zip", { required: true })}
                    onChange={(e) => {
                      dispatch(getAddressByZip(e.target.value)).then((data) => {
                        if (data.payload?.state) {
                          clearErrors("address.state");
                          clearErrors("address.city");
                          clearErrors("address.zip");
                          setZipFlag(true);
                        } else {
                          setZipFlag(false);
                        }
                        setValue("address.state", data.payload.state);
                        setValue("address.city", data.payload.city);
                      });
                    }}
                  />
                  {errors.address && errors.address.zip && errors.address.zip.type === "required" && (
                    <ErrorMSG text="Zip is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    disabled={!zipFlag ? true : false}
                    label={
                      <p className="my-0">
                        State<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("address.state", { required: true })}
                  />
                  {errors.address && errors.address.state && errors.address.state.type === "required" && (
                    <ErrorMSG text="State is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    disabled={!zipFlag ? true : false}
                    label={
                      <p className="my-0">
                        City<span className="danger mr-0">*</span>
                      </p>
                    }
                    InputLabelProps={{ shrink: true }}
                    {...register("address.city", { required: true })}
                  />
                  {errors.address && errors.address.city && errors.address.city.type === "required" && (
                    <ErrorMSG text="City is required" />
                  )}
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="4px">
                  <FormControl sx={{ width: "100%" }}>
                    <label className="fileUploaderLabel z-index-2">
                      Contract Document
                      <span className="danger mr-0">*</span>
                    </label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            className={` z-index-1 customFileInput customFileInputButton customFileInputButton-${
                              navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                            }`}
                            inputProps={{
                              multiple: false,
                              accept: "image/png, image/gif, image/jpg, image/jpeg, application/pdf"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              if (isMaxLimitCross(e.target.files[0])) {
                                e.target.value = null;
                                return toast.error("Maximum image size is 2MB");
                              }
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (validFiles.includes(extFile)) {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif, pdf and png files are allowed!");
                                }
                              }
                              await onChange(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      name="contractDocument"
                      rules={{ required: files.length > 0 ? false : true }}
                    />
                    {errors.contractDocument && errors.contractDocument.type === "required" && (
                      <ErrorMSG text="Contract Document is required" />
                    )}
                  </FormControl>
                  {files ? (
                    files.length && files[0]?.contentType === "application/pdf" ? (
                      // <Alert
                      //   sx={{
                      //     width: "fit-content",
                      //     p: `${files.length > 1 ? "0 20px 0 8px" : "0 8px"}`,
                      //     "& .MuiAlert-icon": {
                      //       mr: `${files.length > 1 ? "12px" : 0}`,
                      //       py: 1
                      //     }
                      //   }}
                      //   icon={""}
                      //   severity="info"
                      // >
                      <a
                        href={`${baseUrl}${files[0]?.fileUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ width: "fit-content", display: "block" }}
                      >
                        <Box
                          sx={{
                            "& .MuiPaper-root": {
                              m: 0
                            }
                          }}
                        >
                          <Alert
                            sx={{
                              width: "fit-content",
                              p: "0 12px",
                              "& .MuiAlert-icon": {
                                mr: 0,
                                py: 1.5
                              }
                            }}
                            icon={<PictureAsPdfIcon fontSize="inherit" />}
                            severity="warning"
                          ></Alert>
                        </Box>
                      </a>
                    ) : // </Alert>
                    id ? (
                      <Box
                        className="vendorModalImageThumb"
                        sx={{ display: "flex", flexWrap: "wrap", mr: 0.5, gap: "4px" }}
                      >
                        {id && <ImageClose files={files} setFiles={setFiles} rowData={rowData} type="vendor" />}
                      </Box>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="4px">
                  <FormControl sx={{ width: "100%" }}>
                    <label className="fileUploaderLabel z-index-2">
                      Tobacco Document
                      <span className="danger mr-0">*</span>
                    </label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            type="file"
                            size="small"
                            className={` z-index-1 customFileInput customFileInputButton customFileInputButton-${
                              navigator?.userAgent?.includes("Mac") && "ontouchend" in document ? "2" : "1"
                            }`}
                            inputProps={{
                              multiple: false,
                              accept: "image/png, image/gif, image/jpg, image/jpeg, application/pdf"
                            }}
                            onChange={async (e) => {
                              let newArray = [];
                              if (isMaxLimitCross(e.target.files[0])) {
                                e.target.value = null;
                                return toast.error("Maximum image size is 2MB");
                              }
                              for await (const [, value] of Object.entries(e.target.files)) {
                                const idxDot = value.name.lastIndexOf(".") + 1;
                                const extFile = value.name.substr(idxDot, value.name.length).toLowerCase();
                                if (validFiles.includes(extFile)) {
                                  newArray = [...newArray, value];
                                } else {
                                  return toast.error("Only jpg/jpeg, gif, pdf and png files are allowed!");
                                }
                              }
                              await onChange(newArray);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      control={control}
                      name="tobaccoDocument"
                      rules={{ required: tobbacoFile.length > 0 ? false : true }}
                    />
                    {errors.tobaccoDocument && errors.tobaccoDocument.type === "required" && (
                      <ErrorMSG text="Tobacco Document is required" />
                    )}
                  </FormControl>
                  {tobbacoFile ? (
                    tobbacoFile.length && tobbacoFile[0]?.contentType === "application/pdf" ? (
                      <a
                        href={`${baseUrl}${tobbacoFile[0]?.fileUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ width: "fit-content", display: "block" }}
                      >
                        <Box
                          sx={{
                            "& .MuiPaper-root": {
                              m: 0
                            }
                          }}
                        >
                          <Alert
                            sx={{
                              width: "fit-content",
                              p: "0 12px",
                              "& .MuiAlert-icon": {
                                mr: 0,
                                py: 1.5
                              }
                            }}
                            icon={<PictureAsPdfIcon fontSize="inherit" />}
                            severity="warning"
                          ></Alert>
                        </Box>
                      </a>
                    ) : // </Alert>
                    id ? (
                      <Box
                        className="vendorModalImageThumb"
                        sx={{ display: "flex", flexWrap: "wrap", mr: 0.5, gap: "4px" }}
                      >
                        {id && (
                          <ImageClose files={tobbacoFile} setFiles={setTobbacoFiles} rowData={rowData} type="vendor" />
                        )}
                      </Box>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Select the driver options for order delivery
                  </FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <FormControlLabel value="false" control={<Radio />} label="Door Venza" />
                        <FormControlLabel value="true" control={<Radio />} label="DoorDash" />
                      </RadioGroup>
                    )}
                    control={control}
                    name="isDoorDash"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Active" />
                        <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                      </RadioGroup>
                    )}
                    control={control}
                    name="status"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="demo-row-radio-buttons-group-label">Premises Type</FormLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <FormControlLabel value="ABC" control={<Radio />} label="ABC" />
                        <FormControlLabel value="NonABC" control={<Radio />} label="NON ABC" />
                      </RadioGroup>
                    )}
                    control={control}
                    name="premisesType"
                  />
                </FormControl>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  columnGap="16px"
                  flexWrap="wrap"
                  sx={{ "@media (max-width: 500px)": { flexDirection: "column", alignItems: "flex-start" } }}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Apply Restocking Charges:</FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            if (e.target.value === "false") {
                              setValue("returnAbleType", "none");
                              setValue("returnAble", "0");
                            }
                            if (e.target.value === "true") {
                              setValue("returnAbleType", "");
                              setValue("returnAble", "");
                            }
                          }}
                        >
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      )}
                      control={control}
                      name="returnAbleFlag"
                    />
                  </FormControl>

                  {watch().returnAbleFlag === "true" && (
                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="row">
                      <FormControl
                        fullWidth
                        sx={{
                          minWidth: "250px",
                          "& .react-select__control": {
                            bgcolor: "#eeeeee"
                          },
                          "@media (max-width: 500px)": {
                            minWidth: "auto"
                          }
                        }}
                      >
                        {/* <label className="react-select-labels z-index-4">Returnable Type/Value</label> */}
                        <Controller
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                styles={styles}
                                theme={(x) => ({ ...x, borderRadius: 4 })}
                                className="react-select-no-outline reactSelectFocus z-index-3 leftJoinedField"
                                classNamePrefix="react-select"
                                isClearable={false}
                                placeholder="Restocking Charges Mode"
                                options={Options}
                                onChange={(e) => {
                                  onChange(e.value);
                                  if (e.value === "none") {
                                    setValue("returnAble", 0);
                                  } else {
                                    setValue("returnAble", "");
                                  }
                                }}
                                value={Options.find((x) => x.value === watch()?.returnAbleType)}
                                isSearchable={false}
                              />
                            );
                          }}
                          control={control}
                          rules={{
                            required: true
                          }}
                          name="returnAbleType"
                        />
                        {errors?.returnAbleType && errors?.returnAbleType?.type === "required" && (
                          <ErrorMSG text="Restocking Charges Mode is required" />
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <label className="react-select-labels z-index-2">
                          Restocking Charges<span className="danger mr-0">*</span>
                        </label>
                        <Controller
                          render={({ field: { onChange, value } }) => {
                            return (
                              <TextField
                                inputProps={{
                                  step: 0.01
                                }}
                                fullWidth
                                className="rightJoinedField"
                                onChange={(e) => onChange(e)}
                                value={value}
                                size="small"
                                type="number"
                                disabled={watch()?.returnAbleType === "none"}
                              />
                            );
                          }}
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) => {
                              if (watch().returnAbleType === "percentage") {
                                return value <= 100;
                              } else {
                                return;
                              }
                            }
                          }}
                          name="returnAble"
                        />
                        {errors.returnAble && errors.returnAble.type === "required" && (
                          <ErrorMSG text="Restocking Charges is required" />
                        )}
                        {errors.returnAble && errors.returnAble.type === "validate" && (
                          <ErrorMSG text="Percentage should not be greater than 100" />
                        )}
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    disabled={isAddLoader}
                    variant="contained"
                    sx={[
                      {
                        "&:hover": {
                          bgcolor: colors.themeSecondaryBlueColor[100],
                          color: colors.white[100]
                        }
                      },
                      { bgcolor: colors.themeDarkGrey[100], m: 1 }
                    ]}
                  >
                    {isAddLoader ? "Saving..." : "Save"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
}
