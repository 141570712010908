import * as React from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 100,
    label: "100"
  },
  {
    value: 200,
    label: "200"
  },
  {
    value: 300,
    label: "300"
  },
  {
    value: 400,
    label: "400"
  },
  {
    value: 500,
    label: "500"
  }
];

export default function VerticalSlider({ value, onChange }) {
  return (
    <Stack spacing={1} direction="column" className="sliderPad">
      <Typography id="non-linear-slider">Stock Purchase Limit:</Typography>
      <Slider
        getAriaLabel={() => "Temperature"}
        orientation="horizontal"
        getAriaValueText={valuetext}
        defaultValue={[1, 200]}
        valueLabelDisplay="auto"
        marks={marks}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        max={500}
        min={1}
      />
    </Stack>
  );
}
