import React from "react";
import { Box, Button } from "@mui/material";
import Search from "../../components/Search";
import GetUserRole from "../../utils/GetUserRole";
import { useNavigate } from "react-router-dom";

export default function SearchBar({ reqData, setReqData }) {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="end" flexWrap="wrap" gap="4px">
      <Search reqData={reqData} setReqData={setReqData} />
      {GetUserRole("store_add") && (
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/locations/add`);
          }}
        >
          Create New Location
        </Button>
      )}
    </Box>
  );
}
