import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Grid } from "@mui/material";
import ModeIcon from "@mui/icons-material/Mode";
import { Typography } from "@mui/material";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect, useState } from "react";
import DeleteDialog from "../subproduct/DeleteDialog";
import { useLocation, useParams } from "react-router-dom";
import { fetchSubProduct } from "../subproduct/redux/action";
import ExpandProductForm from "./ExpandProductForm";
import GlobalLoader from "../../components/GlobalLoader";
import Delete from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import GetUserRole from "../../utils/GetUserRole";
import MyDialog from "../../components/MyDialog";
import AddIcon from "@mui/icons-material/Add";
import Search from "../../components/Search";

export default function SubProductTable({ specificationsData, setIsSpecificationOpen, isSpecificationOpen }) {
  const { id } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openDelete, setDeleteOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [rowData, setRowData] = useState("");
  const [update, setUpdate] = useState(false);
  const [subProductFilters, setSubProductFilters] = useState({
    search: ""
  });

  // let orderViewRole = GetUserRole("order_view");
  let subProductDelete = GetUserRole("sub_product_delete");
  let subProductEdit = GetUserRole("sub_product_edit");
  let subProductAdd = GetUserRole("sub_product_add");
  let subProductList = GetUserRole("sub_product_list");

  const [rowId, setRowId] = useState("");

  // main state to store the payload
  const [reqData] = useState({
    key: "_id",
    desc: true,
    limit: 25,
    page: 1,
    search: ""
  });

  const { subProducts } = useSelector(
    (state) => ({
      subProducts:
        state && state.subProducts && state.subProducts.subProducts ? state.subProducts.subProducts.records : []
    }),
    shallowEqual
  );

  // common store to get common data
  const { common } = useSelector(
    (state) => ({
      common: state && state.subProducts && state.subProducts ? state.subProducts : []
    }),
    shallowEqual
  );
  const { isLoading } = common;

  const dispatch = useDispatch();

  // const onImageError = (e) => {
  //   e.target.src = noImage;
  // };

  useEffect(() => {
    if (id !== "") {
      dispatch(fetchSubProduct({ id: id }));
      setExpanded(location?.state?.addSubProduct || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reqData, update]);

  return (
    <>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" gap="4px">
          <Button
            disabled={!subProductAdd}
            variant="outlined"
            onClick={(e) => {
              setRowData("");
              setExpanded(!expanded);
            }}
          >
            <AddIcon />
            Create Sub Product
          </Button>
          <Search reqData={subProductFilters} setReqData={setSubProductFilters} />
        </Box>
        <MyDialog disableEscapeKeyDown maxWidth="sm" open={expanded} handleClose={() => setExpanded(!expanded)}>
          <ExpandProductForm
            rowData={rowData}
            setRowData={setRowData}
            expanded={expanded}
            setExpanded={setExpanded}
            setFiles={setFiles}
            files={files}
            subProducts={subProducts}
            specificationsData={specificationsData}
            update={update}
            setUpdate={setUpdate}
            setIsSpecificationOpen={setIsSpecificationOpen}
            isSpecificationOpen={isSpecificationOpen}
          />
        </MyDialog>
        {subProductList && (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="simple table" className="product-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: "60px" }}>Index</TableCell>
                  <TableCell sx={{ minWidth: "250px" }}>Sub Product Name</TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>Sale/Old Price</TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>Buy Price</TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>Min Stock Purchase</TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>Max Stock Purchase</TableCell>
                  <TableCell sx={{ minWidth: "100px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <GlobalLoader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {subProducts.length > 0 ||
                  subProducts.filter((value) =>
                    value?.name?.toLowerCase()?.includes(subProductFilters?.search?.toLowerCase())
                  )?.length > 0 ? (
                    subProducts
                      .filter((value) => value?.name?.toLowerCase()?.includes(subProductFilters?.search?.toLowerCase()))
                      .map((row, index) => {
                        return (
                          <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell>{index + 1 ? index + 1 : "N/A"}</TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: 0 }}
                            >
                              {row.name} ({row.SKU})
                            </TableCell>
                            {/* <TableCell>{row.minStockAlert ? row.minStockAlert : 0}</TableCell> */}
                            <TableCell>
                              ${row.inventories.salePrice ? row.inventories.salePrice.toFixed(2) : "0.00"}/ $
                              {row.inventories.oldPrice ? row.inventories.oldPrice.toFixed(2) : "0.00"}
                            </TableCell>
                            <TableCell>
                              ${row.inventories.buyPrice ? row.inventories.buyPrice.toFixed(2) : "0.00"}
                            </TableCell>
                            <TableCell>{row.stockPurchaseLimit.length > 0 ? row.stockPurchaseLimit[0] : "0"}</TableCell>
                            <TableCell>{row.stockPurchaseLimit.length > 0 ? row.stockPurchaseLimit[1] : "0"}</TableCell>
                            <TableCell>
                              <Box display="flex" justifyContent="flex-start" alignItems="center" gap="4px">
                                {subProductEdit && (
                                  <Button
                                    onClick={() => {
                                      setExpanded(!expanded);
                                      setRowData(row);
                                      setFiles([]);
                                    }}
                                    sx={{ minWidth: "auto", color: colors.secondary[900] }}
                                  >
                                    <ModeIcon sx={{ fontSize: "20px" }} />
                                  </Button>
                                )}
                                {subProductDelete && (
                                  <Button
                                    onClick={() => {
                                      setFiles([]);
                                    }}
                                    sx={{ minWidth: "auto", color: colors.themePrimary[900] }}
                                  >
                                    <Delete
                                      sx={{ fontSize: "20px" }}
                                      onClick={() => {
                                        setDeleteOpen(!openDelete);
                                        setRowId(row._id);
                                        // setFiles([]);
                                      }}
                                    />
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="h5">Data not available!</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Grid>
      <DeleteDialog openDelete={openDelete} setDeleteOpen={setDeleteOpen} rowId={rowId} reqData={reqData} />
    </>
  );
}
