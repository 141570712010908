import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function CustomDrawer({
  direction,
  title,
  children,
  open,
  ButtonText,
  variant,
  alignButton,
  titleIcon,
  ButtonIcon
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (direction, open) => (event) => {
    if (event) {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
    }

    setState({ ...state, [direction]: open });
  };

  const list = (direction) => (
    <Box
      id="notificationDrawer"
      sx={{ width: direction === "top" || direction === "bottom" ? "auto" : 400 }}
      role="presentation"
      className="adminDrawer"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
        p="16px"
        position="sticky"
        top="0"
        bgcolor="#fff"
        zIndex="1"
        borderBottom="1px solid #eaecef"
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap="12px">
          <Box sx={{ color: colors.secondary[900], lineHeight: 0 }}>{titleIcon}</Box>
          <Typography variant="h4" sx={{ fontWeight: "600", color: colors.secondary[900] }}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={toggleDrawer(direction, !open)}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Box>
  );

  return (
    <>
      <Box textAlign={alignButton ? alignButton : "left"}>
        <Button variant={variant} onClick={toggleDrawer(direction, open)}>
          {ButtonText}&nbsp;
          {ButtonIcon ? ButtonIcon : <ArrowRightAltIcon />}
        </Button>
      </Box>
      <Drawer anchor={direction} open={state[direction]} onClose={toggleDrawer(direction, !open)}>
        {list(direction)}
      </Drawer>
    </>
  );
}
