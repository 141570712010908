import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { InternalPageHeader } from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { fixFloating, formatPhoneNumber } from "../../utils/common";
import noImag from "../../media/Images/no-photo-available.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getProfile } from "../global/redux/action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ResendPasswordPopover from "../../components/ResendPasswordPopover";
import { updateStatus } from "../category/redux/action";
import { toast } from "react-toastify";
import GetUserRole from "../../utils/GetUserRole";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import AssignmentReturnedOutlinedIcon from "@mui/icons-material/AssignmentReturnedOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ImageViewer from "react-simple-image-viewer";
import ErrorPage from "../../components/ErrorPage";
import DashboardLoader from "../../components/DashboardLoader";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import HoverTooltip from "../../components/HoverTooltip";

const baseUrl = process.env.REACT_APP_BACKEND_URI;

export default function StoreProfile() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [sliderImage, setSliderImage] = useState(null);

  const { address, status, isLoading } = useSelector((state) => state?.layout, shallowEqual);
  const credential = GetUserRole("store_edit");
  const categoryTreeAccess = GetUserRole("category_product_tree");
  const storeEditAccess = GetUserRole("store_edit");
  const storeTaxViewAccess = GetUserRole("store_tax_comm_view");
  const onImageError = (e) => {
    e.target.src = noImag;
  };
  const openModal = (e) => {
    if (e?.target?.src === noImag) return;
    setSliderImage([e?.target?.src]);
  };
  useEffect(() => {
    if (id) {
      dispatch(getProfile(id));
    }
  }, [dispatch, id]);

  return (
    <Box sx={{ p: 2 }}>
      {status === 422 && <ErrorPage />}
      {isLoading ? (
        <DashboardLoader />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            gap="8px"
            sx={{ "@media (max-width: 800px)": { flexDirection: "column", alignItems: "flex-start" } }}
          >
            <InternalPageHeader to="/locations" title="Location Profile" />
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap="8px"
              sx={{ "@media (max-width: 800px)": { width: "100%", justifyContent: "flex-end" } }}
            >
              {categoryTreeAccess && (
                <Button variant="outlined" onClick={() => navigate(`/locations/tree/${address && address._id}`)}>
                  Assign Product
                </Button>
              )}
              {storeEditAccess && (
                <Button variant="outlined" onClick={() => navigate(`/locations/edit/${address && address._id}`)}>
                  Edit Location
                </Button>
              )}
            </Box>
          </Box>
          <Box my={2}>
            {isLoading ? (
              <DashboardLoader />
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Card className="customCard" sx={{ p: 0 }}>
                    <Box sx={{ p: 2, textAlign: "center", background: colors.themeGray[200] }}>
                      <Typography
                        variant="h2"
                        color={colors.secondary[900]}
                        fontWeight="bold"
                        className="word-break-all"
                      >
                        {address?.storeData?.storeName}
                      </Typography>
                      <Typography variant="h4" fontWeight="bold" className="word-break-all">
                        {address?.storeData?.businessName}
                      </Typography>
                      <Typography variant="h6" fontWeight="bold" color={colors.themeGray[600]} fontSize="12px">
                        (Business Name)
                      </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ px: 2, pb: 2 }}>
                      <List sx={{ pb: 0 }}>
                        <ListItem
                          sx={{
                            px: 0,
                            "& div": { m: 0 },
                            columnGap: "2px",
                            alignItems: "center",
                            flexWrap: "wrap"
                          }}
                        >
                          <ListItemText
                            primary="Email:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <Box textAlign="right" className="word-break-all">
                            {address?.email}{" "}
                            <ResendPasswordPopover resetData={{ email: address?.email, type: "store" }} />
                          </Box>
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                          <ListItemText
                            primary="Phone:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <ListItemText primary={formatPhoneNumber(`+1${address?.phone}`)} align="right" />
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                          <ListItemText
                            primary="Address:"
                            sx={{
                              "& span": { fontWeight: "bold", color: colors.themeGray[600] },
                              alignSelf: "baseline"
                            }}
                          />
                          <Box>
                            <ListItemText
                              primary={`${address?.storeData?.address?.address}`}
                              align="right"
                              sx={{ textTransform: "capitalize" }}
                            />
                            <ListItemText
                              primary={`${address?.storeData?.address?.county}`}
                              align="right"
                              sx={{ textTransform: "capitalize" }}
                            />
                            <ListItemText
                              primary={`${address?.storeData?.address?.city}, ${address?.storeData?.address?.state} ${address?.storeData?.address?.zip}`}
                              align="right"
                              sx={{ textTransform: "capitalize" }}
                            />
                          </Box>
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <ListItem
                          sx={{
                            px: 0,
                            "& div": { m: 0 },
                            columnGap: "2px",
                            alignItems: "center",
                            flexWrap: "wrap"
                          }}
                        >
                          <ListItemText
                            primary="Status:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <Box textAlign="right">
                            <FormControlLabel
                              sx={{ mr: 0 }}
                              control={
                                <Switch
                                  disabled={!credential}
                                  checked={address?.status ? address.status : false}
                                  color="success"
                                  onChange={(e) => {
                                    dispatch(
                                      updateStatus({
                                        key: "user",
                                        id: address?._id,
                                        status: !address?.status
                                      })
                                    ).then(async (data) => {
                                      if (data && data?.payload?.status) {
                                        toast.success(data.payload.message);
                                        dispatch(getProfile(id));
                                      } else {
                                        toast.error(data.error);
                                      }
                                    });
                                  }}
                                />
                              }
                            />
                          </Box>
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Typography variant="h5" fontWeight="bold" color={colors.themeGray[600]}>
                              Contract Document:
                            </Typography>
                            <Box sx={{ cursor: "pointer" }}>
                              <Fragment>
                                {address?.storeData?.contractDocument[0]?.contentType === "application/pdf" ? (
                                  <a
                                    href={`${baseUrl}${address?.storeData?.contractDocument[0]?.fileUrl}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="pdfDoc"
                                  >
                                    <Box>
                                      <Alert icon={<PictureAsPdfIcon fontSize="inherit" />} severity="warning"></Alert>
                                    </Box>
                                  </a>
                                ) : (
                                  <img
                                    src={`${baseUrl}/${address?.storeData?.contractDocument[0]?.fileUrl}`}
                                    alt="Contract Document"
                                    className="thumbImage"
                                    onError={onImageError}
                                    onClick={(e) => openModal(e)}
                                  />
                                )}
                              </Fragment>
                            </Box>
                          </Grid>
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Typography variant="h5" fontWeight="bold" color={colors.themeGray[600]}>
                              Tobbaco Document:
                            </Typography>
                            <Box sx={{ cursor: "pointer" }}>
                              <Fragment>
                                {address?.storeData?.tobaccoDocument &&
                                  address?.storeData?.tobaccoDocument?.length !== 0 && (
                                    <>
                                      {address?.storeData?.tobaccoDocument[0]?.contentType === "application/pdf" ? (
                                        <a
                                          href={`${baseUrl}${address?.storeData?.tobaccoDocument[0]?.fileUrl}`}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="pdfDoc"
                                        >
                                          <Box>
                                            <Alert
                                              icon={<PictureAsPdfIcon fontSize="inherit" />}
                                              severity="warning"
                                            ></Alert>
                                          </Box>
                                        </a>
                                      ) : (
                                        <img
                                          src={`${baseUrl}/${address?.storeData?.tobaccoDocument[0]?.fileUrl}`}
                                          alt="Tobbaco Document"
                                          className="thumbImage"
                                          onError={onImageError}
                                          onClick={(e) => openModal(e)}
                                        />
                                      )}
                                    </>
                                  )}
                              </Fragment>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box sx={{ p: 1, background: "#E7F3F3", borderRadius: "4px" }}>
                          <Typography fontWeight="bold" color={colors.themePrimary[900]}>
                            Owner's Info:
                          </Typography>
                          <Divider sx={{ mt: 0.5 }} />
                          <List sx={{ pb: 0 }}>
                            <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                              <ListItemText
                                primary="Name:"
                                sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                              />
                              <ListItemText
                                primary={`${address?.firstName} ${address?.lastName}`}
                                align="right"
                                sx={{ textTransform: "capitalize" }}
                                className="word-break-all"
                              />
                            </ListItem>
                            <Divider sx={{ borderStyle: "dashed" }} />
                            <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                              <ListItemText
                                primary="Email:"
                                sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                              />
                              <ListItemText
                                primary={address?.storeData?.ownerEmail}
                                align="right"
                                className="word-break-all"
                              />
                            </ListItem>
                            <Divider sx={{ borderStyle: "dashed" }} />
                            <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                              <ListItemText
                                primary="Phone:"
                                sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                              />
                              <ListItemText
                                primary={formatPhoneNumber(`+1 ${address?.storeData?.ownerPhone}`)}
                                align="right"
                                sx={{ textTransform: "capitalize" }}
                              />
                            </ListItem>
                          </List>
                        </Box>
                        <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                          <ListItemText
                            primary="Restocking Charges:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              textAlign: "right",
                              fontWeight: "bold",
                              color: colors.themePrimary[900]
                            }}
                          >
                            {address?.storeData?.returnAbleType === "percentage"
                              ? `${address?.storeData?.returnAble}%`
                              : address?.storeData?.returnAbleType === "none"
                              ? "$0.00"
                              : `$${
                                  address?.storeData &&
                                  address?.storeData.returnAble &&
                                  address?.storeData?.returnAble.toFixed(2)
                                }`}
                            &nbsp;
                            {address?.storeData?.returnAbleType === "none"
                              ? ""
                              : `(${address?.storeData?.returnAbleType})`}
                          </Typography>
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                          <ListItemText
                            primary="Premises Type:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <ListItemText
                            primary={address?.storeData?.premisesType}
                            align="right"
                            sx={{ textTransform: "capitalize" }}
                          />
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <ListItem sx={{ px: 0, "& div": { m: 0 }, columnGap: "2px", flexWrap: "wrap" }}>
                          <ListItemText
                            primary="Driver From:"
                            sx={{ "& span": { fontWeight: "bold", color: colors.themeGray[600] } }}
                          />
                          <ListItemText
                            primary={
                              address?.storeData && address?.storeData?.isDoorDash === false ? "DoorVenza" : "DoorDash"
                            }
                            align="right"
                            sx={{
                              textTransform: "capitalize",
                              "& span": {
                                color: colors.white[100],
                                background: `${
                                  address?.storeData && address?.storeData?.isDoorDash === false
                                    ? colors.success[900]
                                    : colors.themePrimary[900]
                                }`,
                                borderRadius: "50px",
                                width: "fit-content",
                                p: "2px 12px"
                              }
                            }}
                          />
                        </ListItem>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Typography variant="h5" fontWeight="bold" color={colors.themeGray[600]}>
                              EIN:
                            </Typography>
                            <Typography>{address?.storeData?.EINNumber}</Typography>
                          </Grid>
                          {address?.storeData?.EINNumberDocument && (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                                <Fragment>
                                  {address?.storeData?.EINNumberDocument[0]?.contentType === "application/pdf" ? (
                                    <a
                                      href={`${baseUrl}${address?.storeData?.EINNumberDocument[0]?.fileUrl}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="pdfDoc"
                                    >
                                      <Box>
                                        <Alert
                                          icon={<PictureAsPdfIcon fontSize="inherit" />}
                                          severity="warning"
                                        ></Alert>
                                      </Box>
                                    </a>
                                  ) : (
                                    <img
                                      src={`${baseUrl}/${address?.storeData?.EINNumberDocument[0]?.fileUrl}`}
                                      alt="EIN Number Document"
                                      className="thumbImage"
                                      onError={onImageError}
                                      onClick={(e) => openModal(e)}
                                    />
                                  )}
                                </Fragment>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <Grid container spacing={gridSpacing} pt={1.5} mb={1.5}>
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Typography variant="h5" fontWeight="bold" color={colors.themeGray[600]}>
                              Seller Permit:
                            </Typography>
                            <Typography>{address?.storeData?.sellerPermit}</Typography>
                          </Grid>
                          {address?.storeData?.sellerPermitDocument && (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <Box textAlign="right" sx={{ float: "right", cursor: "pointer" }}>
                                <Fragment>
                                  {address?.storeData?.sellerPermitDocument[0]?.contentType === "application/pdf" ? (
                                    <a
                                      href={`${baseUrl}${address?.storeData?.sellerPermitDocument[0]?.fileUrl}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="pdfDoc"
                                    >
                                      <Box>
                                        <Alert
                                          icon={<PictureAsPdfIcon fontSize="inherit" />}
                                          severity="warning"
                                        ></Alert>
                                      </Box>
                                    </a>
                                  ) : (
                                    <img
                                      src={`${baseUrl}/${address?.storeData?.sellerPermitDocument[0]?.fileUrl}`}
                                      alt="Seller Permit Document"
                                      className="thumbImage"
                                      onError={onImageError}
                                      onClick={(e) => openModal(e)}
                                    />
                                  )}
                                </Fragment>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </List>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              {address?.locationBusiness?.totalOrders}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Delivered Orders
                              <HoverTooltip title="Delivered Orders + Return Orders + Replace Orders" />
                            </Typography>
                          </Box>
                          <DescriptionOutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              {address?.locationBusiness?.returnedOrders}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Returned Orders
                            </Typography>
                          </Box>
                          <AssignmentReturnedOutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              {address?.locationBusiness?.replacedOrders}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Replaced Orders
                            </Typography>
                          </Box>
                          <PublishedWithChangesOutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              {address?.locationBusiness?.cancelOrder ?? 0}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Cancelled Orders
                              <HoverTooltip title="Canceled Orders + Return Rejected Orders + Replacement Rejected Orders" />
                            </Typography>
                          </Box>
                          <RemoveShoppingCartIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              ${fixFloating(address?.locationBusiness?.locationAmount, 2)}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Total Business
                              <HoverTooltip title="Amount Payable Total= (Net Total + Tax - Commission) - (Restocking charge and Refund Amount)" />
                            </Typography>
                          </Box>
                          <RequestQuoteOutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              ${fixFloating(address?.locationBusiness?.doorvenzaAmount, 2)}
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Doorvenza Business
                              <HoverTooltip title="Doorvenza Commission+Customer Restocking Charges+Location Restocking Charges" />
                            </Typography>
                          </Box>
                          <CurrencyExchangeOutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                      <Card className="customCard" sx={{ p: 0 }}>
                        <Box className="customerCountsCard">
                          <Box className="content">
                            <Typography className="fs-28" variant="h2" fontWeight="bold">
                              {address?.locationBusiness?.locationProducts}{" "}
                              <span style={{ color: colors.themeGray[600], fontWeight: "bold", fontSize: "20px" }}>
                                / {address?.locationBusiness?.totalProduct}
                              </span>
                            </Typography>
                            <Typography color={colors.secondary[900]} fontWeight="bold">
                              Products Selling / Total
                            </Typography>
                          </Box>
                          <Inventory2OutlinedIcon className="customerCountsCardIcon" />
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Card className="customCard">
                        <Box
                          sx={{
                            pb: 1,
                            mb: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px",
                            flexWrap: "wrap"
                          }}
                        >
                          <Typography color={colors.secondary[900]} fontWeight="bold">
                            Root Category Tax / Commission:
                            <HoverTooltip title="Category list which have tax and commission assigned" />
                          </Typography>
                          {storeTaxViewAccess && (
                            <Button
                              variant="outlined"
                              onClick={() => navigate(`/locations/locationTax/${address && address._id}`)}
                            >
                              Assign Tax
                            </Button>
                          )}
                        </Box>
                        <TableContainer sx={{ maxHeight: "500px" }}>
                          <Table stickyHeader aria-label="sticky table" className="customTable">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ minWidth: "80px" }}>Sr. No.</TableCell>
                                <TableCell sx={{ minWidth: "120px" }}>Category Name</TableCell>
                                <TableCell sx={{ minWidth: "120px" }}>Commission</TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>Tax</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {address?.locationBusiness?.categoryCommission?.length > 0 ? (
                                <>
                                  {address?.locationBusiness?.categoryCommission.map((comis, i) => {
                                    return (
                                      <TableRow key={i}>
                                        <TableCell sx={{ textTransform: "capitalize" }}>{i + 1}</TableCell>
                                        <TableCell sx={{ textTransform: "capitalize" }}>
                                          <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            gap="12px"
                                          >
                                            <Box>
                                              <Typography fontSize="14px">{comis?.categoryName || "N/A"}</Typography>
                                            </Box>
                                          </Box>
                                        </TableCell>
                                        <TableCell sx={{ textTransform: "capitalize" }}>
                                          {comis?.commission === 0 ? comis?.commission : `${comis?.commission}%`}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: "capitalize" }}>
                                          {comis?.taxName ? `${comis?.taxName} - ${comis?.taxPercent}%` : "N/A"}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </>
                              ) : (
                                <TableRow>
                                  <TableCell colSpan="8" align="center">
                                    <Typography variant="h5">Data not available!</Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </>
      )}

      {sliderImage && (
        <ImageViewer
          src={sliderImage}
          currentIndex={"0"}
          onClose={() => setSliderImage(null)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={false}
        />
      )}
    </Box>
  );
}
