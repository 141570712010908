import Select from "react-select";
import { styles } from "../../utils/custom";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";

function TreeViewDropdown({ items, isCategoryFromProduct }) {
  const renderNestedItems = (nestedItems, depth) => {
    let options = [];
    nestedItems?.forEach((item) => {
      const value = {
        id: item._id,
        name: item.name
      };
      const label = (
        <span style={{ paddingLeft: `${item.depth * 15}px` }} className="beforeTree">
          <SubdirectoryArrowRightOutlinedIcon fontSize="small" />
          {item.name}
        </span>
      );
      const isDisabled = isCategoryFromProduct ? (item.depth === 0 ? true : false) : false;

      const subcategories =
        item.subcategories && item.subcategories.length > 0 ? renderNestedItems(item.subcategories) : null;
      options.push({
        value,
        label,
        isDisabled
      });
      if (subcategories) {
        options = options.concat(subcategories);
      }
    });
    return options;
  };
  return renderNestedItems(items, items);
}
function TreeViewSelect({ items, value, onChange, placeholder, defaultValue, isClear, isCategoryFromProduct }) {
  const options = TreeViewDropdown({ items, isCategoryFromProduct });
  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.value.name.toLowerCase().includes(input.toLowerCase())) return true;
      return false;
    }
    return true;
  };

  return (
    <>
      <Select
        styles={styles}
        defaultValue={options.map((x) => x.value.id === defaultValue && { value: x.value.id, label: x.value.name })}
        // defaultValue={options.filter((x) => x.value.id === defaultValue)}
        options={options}
        value={options.map((x) => x.value.id === defaultValue && { value: x.value.id, label: x.value.name })}
        // value={options.filter((x) => x.value.id === defaultValue)}
        onChange={onChange}
        classNamePrefix="react-select"
        className="reactSelectFocus z-index-5"
        noOptionsMessage={() => "Search Category"}
        isSearchable={true}
        isMulti={false}
        closeMenuOnSelect={true}
        placeholder={placeholder}
        isClearable={isClear}
        filterOption={filterOptions}
      />
    </>
  );
}

export default TreeViewSelect;
