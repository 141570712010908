import {
  GET_DRIVERS,
  DRIVER_ADD_FAIL,
  DRIVER_ADD_REQUEST,
  DRIVER_ADD_SUCCESS,
  DRIVER_DELETE_FAIL,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_SUCCESS,
  DRIVER_FAIL,
  DRIVER_REQUEST,
  DRIVER_UPDATE_FAIL,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS,
  STATEMENTS_REQUEST,
  STATEMENTS_SUCCESS,
  STATEMENTS_FAIL,
  STATEMENTS_VIEW_REQUEST,
  STATEMENTS_VIEW_SUCCESS,
  STATEMENTS_VIEW_FAIL,
  INVOICE_STATEMENTS_VIEW_REQUEST,
  INVOICE_STATEMENTS_VIEW_SUCCESS,
  INVOICE_STATEMENTS_VIEW_FAIL,
  PDF_GET_SUCCESS,
  PDF_GET_REQUEST
} from "./type";

// category intial state
const initialDrivers = {
  drivers: [],
  isLoading: true,
  isMainStatementsLoading: true,
  isViewStatementsLoading: false,
  isEditLoading: true,
  driversForEdit: null,
  error: null,
  success: false,
  total: null,
  statements: [],
  statementsViewData: [],
  invoiceStatementsData: [],
  statementPdf: "",
  statemenTotal: null
};

export const reducer = (state = initialDrivers, action) => {
  switch (action.type) {
    case DRIVER_REQUEST:
      return { ...state, isLoading: true };
    case GET_DRIVERS: {
      return {
        ...state,
        drivers: action.payload.data.records,
        isLoading: false,
        success: true,
        total: action.payload.data.totalRecords
      };
    }
    case DRIVER_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case DRIVER_ADD_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_ADD_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_ADD_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case DRIVER_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_UPDATE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_UPDATE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case DRIVER_DELETE_REQUEST:
      return { ...state, isLoading: true };
    case DRIVER_DELETE_SUCCESS:
      return { ...state, isLoading: false, success: true };
    case DRIVER_DELETE_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }

    case STATEMENTS_REQUEST:
      return { ...state, isMainStatementsLoading: true };
    case STATEMENTS_SUCCESS:
      return {
        ...state,
        isMainStatementsLoading: false,
        success: true,
        statements: action.payload.data.records,
        total: action.payload.data.totalRecord,
        statementsViewData: []
      };
    case STATEMENTS_FAIL: {
      return { ...state, isMainStatementsLoading: false, error: action.error };
    }

    case STATEMENTS_VIEW_REQUEST:
      return { ...state, isViewStatementsLoading: true };
    case STATEMENTS_VIEW_SUCCESS:
      return {
        ...state,
        isViewStatementsLoading: false,
        success: true,
        statementsViewData: action.payload.data,
        statemenTotal: action.payload.data.totalRecords
      };
    case STATEMENTS_VIEW_FAIL: {
      return { ...state, isViewStatementsLoading: false, error: action.error };
    }

    case INVOICE_STATEMENTS_VIEW_REQUEST:
      return { ...state, isLoading: true };
    case INVOICE_STATEMENTS_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        invoiceStatementsData: action.payload.data
        // total: action.payload.data.totalRecords
      };
    case INVOICE_STATEMENTS_VIEW_FAIL: {
      return { ...state, isLoading: false, error: action.error };
    }
    case PDF_GET_SUCCESS: {
      return { ...state, statementPdf: action.payload.data, isLoading: false, error: false };
    }
    case PDF_GET_REQUEST: {
      return { ...state, isLoading: true, error: action.error };
    }

    default:
      return state;
  }
};
