import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Header } from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Select from "react-select";
import { styles } from "../../utils/custom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

export default function ProductReport() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const { control } = useForm({});

  // status options
  const statusOptions = [
    { label: "Active", value: "true", name: "status" },
    { label: "Inactive", value: "false", name: "status" }
  ];

  return (
    <Box mx="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        gap="8px"
        sx={{ "@media (max-width: 400px)": { flexDirection: "column", alignItems: "flex-start" } }}
      >
        <Header title="PRODUCT REPORT" />
      </Box>
      <Typography variant="h4" fontWeight="bold" textAlign="center" color={colors.themePrimary[900]}>
        ALL THE FIELD'S & DATA OF THIS PAGE ARE STATIC
      </Typography>
      <Box>
        <Card className="customCard customCardMargin">
          <Typography variant="h4" fontWeight="bold" color={colors.themePrimary[900]}>
            <span style={{ color: colors.themeGray[600] }}>Product Name:</span> Red Vape{" "}
            <span style={{ color: colors.secondary[900], fontSize: "16px" }}>(1111501)</span>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="h5" fontWeight="600" mb={2}>
            Report Filter:
          </Typography>
          <Grid container spacing={gridSpacing}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-6">Product Name</label>
                <Select
                  styles={styles}
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-5"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder="Select Product Name"
                  options={statusOptions}
                  value={statusOptions.value}
                  name="product"
                />
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-6">Sub Product Name</label>
                <Select
                  styles={styles}
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-5"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder="Select Sub Product Name"
                  options={statusOptions}
                  value={statusOptions.value}
                  name="subProduct"
                />
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-4">Location Name</label>
                <Select
                  styles={styles}
                  theme={(x) => ({ ...x, borderRadius: 4 })}
                  className="react-select-no-outline reactSelectFocus z-index-3"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder="Select Location Name"
                  options={statusOptions}
                  value={statusOptions.value}
                  name="location"
                />
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <label className="react-select-labels z-index-2">Date Range</label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      className="datePickerStyling-1 z-index-1"
                      selected={value}
                      showMonthDropdown
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange={true}
                      dropdownMode="select"
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      placeholderText="MM-DD-YYYY"
                    />
                  )}
                  control={control}
                  name="reportDateRange"
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                <Button variant="outlined" sx={{ m: 0.5 }}>
                  Reset
                </Button>
                <Button variant="contained" sx={{ m: 0.5 }}>
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Card className="customCard customCardMargin">
          <Box
            sx={{
              pb: 1,
              mb: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap"
            }}
          >
            <Box>
              <Typography fontWeight="bold" color={colors.secondary[900]}>
                Product Report:
              </Typography>
            </Box>
            <Button variant="outlined" sx={{ color: colors.themeGray[600], borderColor: colors.themeGray[600] }}>
              <SaveAltIcon color={colors.themeGray[600]} sx={{ fontSize: "20px" }} />
              &nbsp;&nbsp;Export PDF
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: "500px" }}>
            <Table stickyHeader aria-label="sticky table" className="customTable">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: "80px" }}>Sr. No.</TableCell>
                  <TableCell sx={{ minWidth: "200px" }}>Sub Product Name</TableCell>
                  <TableCell sx={{ minWidth: "200px" }}>Location Name</TableCell>
                  <TableCell sx={{ minWidth: "100px" }}>Qty. Sold</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textTransform: "capitalize" }}>1</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>Red Vape (1111501)</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>UniMart</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>200</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ textTransform: "capitalize" }}>2</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>G Pro Vape (1111502)</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>Patel Store</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>146</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Box>
  );
}
