import { Chip, MenuItem, Select, Typography } from "@mui/material";

export function PaymentStatusLabel({ status }) {
  if (status === "failed") {
    return <Chip label="Failed" variant="outlined" sx={{ borderColor: "#DB3646", color: "#DB3646" }} />;
  }
  if (status === "pending") {
    return <Chip label="Pending" variant="outlined" sx={{ borderColor: "#007BFF", color: "#007BFF" }} />;
  }
  if (status === "received") {
    return <Chip label="Received" variant="outlined" sx={{ borderColor: "#29A745", color: "#29A745" }} />;
  }
  if (status === "refunded") {
    return <Chip label="Refunded" variant="outlined" sx={{ borderColor: "#6C757D", color: "#6C757D" }} />;
  }
  if (status === "refundInitiated") {
    return <Chip label="Refund Initiated" variant="outlined" sx={{ borderColor: "#343A40", color: "#343A40" }} />;
  }
  if (status === "paid") {
    return <Chip label="Paid" variant="outlined" sx={{ borderColor: "#29A745", color: "#29A745" }} />;
  }
  if (status === "refundFailed") {
    return <Chip label="Refund Failed" variant="outlined" sx={{ borderColor: "#DB3646", color: "#DB3646" }} />;
  }
}

export function StatusLabel({ value, onChange, disabled }) {
  return (
    <>
      {value === "delivered" || value === "cancelled" ? (
        <Typography className="text-capitalize">{value}</Typography>
      ) : (
        <Select
          value={value !== null && value}
          defaultValue={value !== null && value}
          onChange={onChange}
          disabled={disabled}
          className="orderTableSelect"
        >
          <MenuItem value={"pending"} disabled={true}>
            Pending
          </MenuItem>
          <MenuItem
            value={"confirmed"}
            disabled={value === "confirmed" || value === "shipped" || value === "delivered" || value === "cancelled"}
          >
            Confirmed
          </MenuItem>
          <MenuItem value={"shipped"} disabled={value === "shipped" || value === "delivered" || value === "cancelled"}>
            Shipped
          </MenuItem>
          <MenuItem value={"delivered"} disabled={value === "delivered" || value === "cancelled"}>
            Delivered
          </MenuItem>
          <MenuItem value={"cancelled"} disabled={value === "cancelled"}>
            Cancelled
          </MenuItem>
        </Select>
      )}
    </>
  );
}
