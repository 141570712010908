// Vendor module types
export const BrandsMap = {
  BRAND_REQUEST: "BRAND_REQUEST",
  BRAND_SUCCESS: "BRAND_SUCCESS",
  BRAND_FAIL: "BRAND_FAIL",

  BRAND_ADD_REQUEST: "BRAND_ADD_REQUEST",
  BRAND_ADD_SUCCESS: "BRAND_ADD_SUCCESS",
  BRAND_ADD_FAIL: "BRAND_ADD_FAIL",

  BRAND_UPDATE_REQUEST: "BRAND_UPDATE_REQUEST",
  BRAND_UPDATE_SUCCESS: "BRAND_UPDATE_SUCCESS",
  BRAND_UPDATE_FAIL: "BRAND_UPDATE_FAIL",

  BRAND_DELETE_REQUEST: "BRAND_DELETE_REQUEST",
  BRAND_DELETE_SUCCESS: "BRAND_DELETE_SUCCESS",
  BRAND_DELETE_FAIL: "BRAND_DELETE_FAIL",

  BRANDS_REQUEST: "BRANDS_REQUEST",
  BRANDS_SUCCESS: "BRANDS_SUCCESS",
  BRANDS_FAIL: "BRANDS_FAIL",

 
};
