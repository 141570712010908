import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from "@mui/x-data-grid";
import React, { useState } from "react";
import MyFilter from "./MyFilter";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function MyVendorGridToolbar(props) {
  //select perticular chip state
  const [selectFilter, setSelectFilter] = useState([]);

  // initial state payload to load
  const [payLoadData, setPayloadData] = useState({
    key: "_id",
    desc: true,
    limit: 25,
    page: 1,
    search: "",
    fromDate: new Date(),
    toDate: new Date(),
    dateRange: 0,
    status: ""
  });
  //date state to store range data
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    dateRange: 0
  });
  // main function to clear filtered data
  const clearFilterdata = (item) => {
    let data = selectFilter;
    let requestData = payLoadData;
    let dateRangeData = dateRange;
    if (data.indexOf(item) !== -1) {
      let indexNo = data.indexOf(item);
      data.splice(indexNo, 1);
      // eslint-disable-next-line eqeqeq
      if ("Status" == item) requestData.status = "";
      // eslint-disable-next-line eqeqeq
      if ("Date" == item) {
        requestData.fromDate = new Date();
        requestData.toDate = new Date();
        requestData.dateRange = 0;
        dateRangeData.startDate = new Date();
        dateRangeData.endDate = new Date();
        dateRangeData.dateRange = 0;
      }
    }
    if (item === "clearall") {
      requestData.status = "";
      requestData.fromDate = new Date();
      requestData.toDate = new Date();
      requestData.dateRange = 0;
      dateRangeData.startDate = new Date();
      dateRangeData.endDate = new Date();
      dateRangeData.dateRange = 0;
      data = [];
    }
    props.setReqData(Object.assign({}, requestData));
    props.restorePaginationState();
    setSelectFilter(data);
  };

  return (
    <Box mb={0.5}>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <MyFilter
          reqData={props.reqData}
          setReqData={props.setReqData}
          payLoadData={payLoadData}
          setPayloadData={setPayloadData}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectFilter={selectFilter}
          setSelectFilter={setSelectFilter}
          clearFilterdata={clearFilterdata}
          props={props}
        />

        {selectFilter.length > 1 && (
          <Box
            sx={{
              p: "2px 4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: 1,
              borderRadius: "4px",
              borderColor: "#bdbdbd",
              background: "#f2f0f0"
            }}
          >
            <Typography variant="span" sx={{ fontSize: "13px" }}>
              Clear all
            </Typography>
            <IconButton
              sx={{ color: "#f44336", p: "2px", ml: 0.5, background: "#e5e4e4", borderRadius: "50%" }}
              onClick={() => {
                clearFilterdata("clearall");
              }}
            >
              <CloseIcon sx={{ fontSize: "11px" }} />
            </IconButton>
          </Box>
        )}
        {selectFilter.map((item) => (
          <Box
            sx={{
              p: "2px 4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: 1,
              borderRadius: "4px",
              borderColor: "#bdbdbd"
            }}
          >
            <Typography variant="span" sx={{ fontSize: "13px" }}>
              {item}
            </Typography>
            <IconButton
              sx={{ color: "#f44336", p: "2px", ml: 0.5, background: "#e5e4e4", borderRadius: "50%" }}
              onClick={() => {
                clearFilterdata(item);
              }}
            >
              <CloseIcon sx={{ fontSize: "11px" }} />
            </IconButton>
          </Box>
          // <button
          //   key={item}
          //   type="button"
          //   className="btn pull-right ml-auto filteredOptions"
          //   onClick={() => {
          //     clearFilterdata(item);
          //   }}
          // >

          //   <i className="text-danger pull-right fa fa-close"></i>
          // </button>
        ))}
      </GridToolbarContainer>
    </Box>
  );
}
