import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { viewStatements } from "../redux/action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IconButton, LinearProgress, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { PaymentStatusLabel } from "../../../utils/LabalColor";
import { CommanFormatDate } from "../../../utils/custom";
import { VisibilityOff } from "@mui/icons-material";
import TableWrapper from "../../../components/TableWrapper";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import TopStatusFilter from "../filter/TopStatusFilter";

export default function StatementsMainTable({
  statements,
  isLoading,
  setCurrentStatementId,
  currentStatementId,
  reqData,
  setReqData,
  total,
  location
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      flex: 1,
      sortable: false,
      renderCell: ({ row, id }) => (
        <Typography color={colors.secondary[900]} fontWeight="bold">
          {id}
        </Typography>
      )
    },
    {
      field: "generatedDate",
      headerName: "Generated Date",
      minWidth: 250,
      flex: 2,
      sortable: false,
      renderCell: ({ row, i }) => (
        <Typography color={colors.secondary[900]} fontWeight="bold">
          {moment(row.generatedDate).format(CommanFormatDate)}
        </Typography>
      )
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 250,
      flex: 2,
      sortable: false,
      renderCell: ({ row, i }) => <PaymentStatusLabel status={row.status} />
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 0,
      flex: 1,
      sortable: false,
      renderCell: ({ row, i }) => (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={() => {
            setCurrentStatementId(row._id);
            dispatch(viewStatements({ statementId: row._id })).then(async (data) => {
              if (data && data?.payload?.status) {
                toast.success(data.payload.message);
              }
            });
          }}
        >
          {currentStatementId === row._id ? (
            <RemoveRedEyeIcon sx={{ fontSize: "24px", color: "#707070" }} />
          ) : (
            <VisibilityOff />
          )}
        </IconButton>
      )
    }
  ];
  const apiRef = useGridApiRef();
  const restorePaginationState = () => {
    const state = apiRef.current.exportState();
    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
          pageSize: 10
        }
      }
    };
    apiRef.current.restoreState(restoredState);
  };
  return (
    <TableWrapper sx={{ mb: 4 }} h="350px">
      <DataGrid
        apiRef={apiRef}
        getRowHeight={() => "auto"}
        disableColumnFilter
        rows={statements?.map((product, i) => ({
          id: reqData.limit * reqData.page - reqData.limit + (i + 1),
          ...product
        }))}
        disableRowSelectionOnClick={true}
        columns={columns}
        slotProps={{
          toolbar: {
            printOptions: { hideFooter: true, hideToolbar: true },
            reqData,
            setReqData,
            location,
            restorePaginationState
          }
        }}
        components={{ Toolbar: GridToolbar }}
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: TopStatusFilter
        }}
        loading={isLoading}
        selectable="false"
        rowCount={total || 0}
        paginationMode="server"
        sortingMode="server"
        onRowClick={(rowData) => {}}
        pagination
        onPaginationModelChange={(e) => {
          setReqData(
            Object.assign({}, reqData, {
              page: e.page + 1,
              limit: e.pageSize
            })
          );
        }}
        rowsPerPage={reqData.limit}
        onSortModelChange={(newSortModel) => {
          if (newSortModel.length > 0) {
            setReqData(
              Object.assign({}, reqData, {
                key: newSortModel[0]?.field,
                desc: newSortModel[0]?.sort === "asc" ? false : true
              })
            );
          } else {
            setReqData(
              Object.assign({}, reqData, {
                key: "_id",
                desc: true
              })
            );
          }
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: reqData.limit } }
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        sx={{
          "@media print": {
            ".MuiDataGrid-main": {
              width: "fit-content",
              fontSize: "14px",
              overflow: "visible",
              border: "none !important"
            },
            border: "none !important",
            ".MuiDataGrid-main .MuiDataGrid-columnHeader": {
              color: "#000 !important"
            },
            ".MuiDataGrid-main .MuiDataGrid-cell": {
              whiteSpace: "normal !important",
              maxHeight: "unset !important"
            },
            ".MuiDataGrid-main .MuiDataGrid-row": {
              maxHeight: "unset !important"
            },
            ".MuiDataGrid-main .MuiDataGrid-virtualScroller": {
              height: "auto !important",
              overflow: "unset !important"
            }
          }
        }}
      />
    </TableWrapper>
  );
}
