export function getParentCategories(categories, categoryId) {
  for (const category of categories) {
    if (category._id === categoryId) {
      return [{ name: category.name, taxId: category.taxId }]; // Base case: the category is the root of the tree
    }
    if (category.subcategories) {
      const parentCategories = getParentCategories(category.subcategories, categoryId);
      if (parentCategories !== null) {
        return [...parentCategories, { name: category.name, taxId: category.taxId }];
      }
    }
  }
  return null; // The category ID was not found in the tree
}

//Get depth 2
export function GetDepth(categories, categoryId) {
  for (const category of categories) {
    if (category._id === categoryId) {
      return false;
    }
    if (category.subcategories) {
      for (const subCategory of category.subcategories) {
        if (subCategory._id === categoryId) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getParentSpecification(categories, categoryId) {
  for (const category of categories) {
    if (category._id === categoryId) {
      return [{ specificationId: category.specificationId }]; // Base case: the category is the root of the tree
    }
    if (category.subcategories) {
      const parentCategories = getParentSpecification(category.subcategories, categoryId);
      if (parentCategories !== null) {
        return [...parentCategories, { specificationId: category.specificationId }];
      }
    }
  }
  return null;
}
