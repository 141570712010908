import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor from "../../../utils/JsonInterceptor";
import { UserMap } from "./type";

//fetch user
export const fetchUsers = (body) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_REQUEST });
    let apiEndpoint = `${API_END_POINTS.users}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: UserMap.USERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: UserMap.USERS_FAIL, error: message });
  }
};

//Add user
export const createUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_ADD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.addUser}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: UserMap.USERS_ADD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: UserMap.USERS_ADD_FAIL, error: message });
  }
};

//Edit user
export const updateUser = (body, id) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.editUser}`;
    const { data } = await JsonInterceptor.put(`${apiEndpoint}/${id}`, body);
    return dispatch({
      type: UserMap.USERS_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: UserMap.USERS_UPDATE_FAIL, error: message });
  }
};

//Delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_DELETE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.deleteUser}`;
    const { data } = await JsonInterceptor.delete(`${apiEndpoint}/${id}`);
    return dispatch({
      type: UserMap.USERS_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: UserMap.USERS_DELETE_FAIL, error: message });
  }
};

//Get userrole
export const getUserRole = (id) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_ROLE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.userRole}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${id}`);
    return dispatch({
      type: UserMap.USERS_ROLE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    return dispatch({ type: UserMap.USERS_ROLE_FAIL, error: message });
  }
};

//Assign userRole
export const assignUserRole = (body) => async (dispatch) => {
  try {
    dispatch({ type: UserMap.USERS_ROLE_ASSIGN_REQUEST });
    let apiEndpoint = `${API_END_POINTS.assignRole}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: UserMap.USERS_ROLE_ASSIGN_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : error.message;
    toast.error(message);
    dispatch({ type: UserMap.USERS_ROLE_ASSIGN_FAIL, error: message });
  }
};


